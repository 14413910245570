<template>
  <div class="usps__wrapper">
    <div
      v-if="title"
      class="usps__title"
    >
      {{ title }}
    </div>
    <div class="usps">
      <div
        v-for="(usp, index) in usps"
        :key="index"
        class="usps__entry"
      >
        <i
          class="uil usps__entry__icon"
          :class="`uil-${usp.icon}`"
        />
        <div
          class="usps__entry__content"
          v-html="usp.content"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    usps: {
      type: Array,
      required: true,
    },
  },
  computed: {
  },
};
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/_colors.scss";

  .usps__wrapper {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 10rem auto;
    font-size: 1rem;
  }

  .usps__title {
    margin-bottom: 0.25em;
    text-transform: uppercase;
    opacity: 0.7;
  }

  .usps {
    display: flex;
    flex-direction: column;
    gap: 2em;

    background-color: #f8f8f8;
    border: 1px solid $light-border;

    padding: 3em 2em;

    &__entry {
      display: flex;
      align-items: center;
      gap: 1.5em;

      &__icon {
        font-size: 4em;
        color: $red;
      }

      &__content {
        font-size: 2em;
      }
    }
  }
</style>
