import {
  en as enDatePicker, nl as nlDatePicker, es as esDatePicker, fr as frDatePicker, de as deDatePicker, it as itDatePicker,
} from 'vuejs-datepicker/dist/locale';
import * as nl from './app/nl.json';
import * as en from './app/en.json';
import * as es from './app/es.json';
import * as de from './app/de.json';
import * as fr from './app/fr.json';
import * as it from './app/it.json';

import * as nlCountries from './node-i18n-iso-countries-master/langs/nl.json';
import * as enCountries from './node-i18n-iso-countries-master/langs/en.json';
import * as esCountries from './node-i18n-iso-countries-master/langs/es.json';
import * as deCountries from './node-i18n-iso-countries-master/langs/de.json';
import * as frCountries from './node-i18n-iso-countries-master/langs/fr.json';
import * as itCountries from './node-i18n-iso-countries-master/langs/it.json';

import * as nlLangs from './i18n-iso-languages-master/langs/nl.json';
import * as enLangs from './i18n-iso-languages-master/langs/en.json';
import * as esLangs from './i18n-iso-languages-master/langs/es.json';
import * as deLangs from './i18n-iso-languages-master/langs/de.json';
import * as frLangs from './i18n-iso-languages-master/langs/fr.json';
import * as itLangs from './i18n-iso-languages-master/langs/it.json';

export default {
  en: {
    ...en.default,
    language: enLangs.default.languages,
    countries: enCountries.default.countries,
    Datepicker: enDatePicker,
  },
  nl: {
    ...nl.default,
    language: nlLangs.default.languages,
    countries: nlCountries.default.countries,
    Datepicker: nlDatePicker,
  },
  es: {
    ...es.default,
    language: esLangs.default.languages,
    countries: esCountries.default.countries,
    Datepicker: esDatePicker,
  },
  fr: {
    ...fr.default,
    language: frLangs.default.languages,
    countries: frCountries.default.countries,
    Datepicker: frDatePicker,
  },
  it: {
    ...it.default,
    language: itLangs.default.languages,
    countries: itCountries.default.countries,
    Datepicker: itDatePicker,
  },
  de: {
    ...de.default,
    language: deLangs.default.languages,
    countries: deCountries.default.countries,
    Datepicker: deDatePicker,
  },
};
