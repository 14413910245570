<template>
  <div
    :class="{
      'location': true,
    }"
  >
    <select
      :value="value"
      class="location-select"
      @change="onChange"
    >
      <option
        v-for="location of locations"
        :key="location.externalid"
        :value="location.externalid"
      >
        {{ displayName(location) }}
      </option>
    </select>
  </div>
</template>
<script>

import { mapState } from 'vuex';

export default {
  name: 'LocationSelect',
  components: {
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customer,
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
  },
  mounted() {
  },
  methods: {
    onChange(ev) {
      this.$emit('input', ev.target.value);
    },
    displayName(location) {
      // TODO a better work around: not all locations seem to have helpful names (hence we add the city)
      if (location.Name.toUpperCase().includes(location.city.toUpperCase()) || location.Name.length > 15) {
        return location.Name;
      }
      return `${location.Name} (${location.city})`;
    },
  },
};
</script>

<style lang="scss" scoped>
.location {
  display: inline-grid;
  grid-template-areas: "value select";
  grid-template-columns: auto 1em;
  align-items: center;
  position: relative;

  cursor: pointer;

  >select{
    grid-column: 1 / -1;
    grid-row: 1 / 1;
    font-size: 1.5rem;
    padding: .5em 1em .5em 1em; /* Extra padding on the right */
    height: auto;
    border-radius: 0;
    background-image: none;
    cursor: inherit;
  }
}

</style>
