import { fetch } from '@/api/warranty';
import { timeExpired } from '@/utils/dateUtils';

export default {
  namespaced: true,
  state: {
    // items: [],
    errorMessage: null,
    inProgress: false,
    myWarranties: '',
    isLoaded: false,
    lastUpdate: null,
    customer: null,
  },

  getters: {
    listMyWarranties: state => (state.myWarranties ? state.myWarranties : []),
    loadStatus(state) {
      return state.isLoaded;
    },
  },

  actions: {
    async get({
      commit, rootState, rootGetters, state,
    }) {
      if (rootState.myWarranties.lastUpdate === null || timeExpired(rootState.myWarranties.lastUpdate) || rootState.customer.selectedCustomerId !== state.customer) {
        commit('setRequestPending');
        try {
          if (rootGetters['auth/isLoggedIn']) {
            const result = await fetch(
              rootState.customer.selectedCustomerId,
            );
            commit('setRequestSuccess', { result: result.result, customerId: rootState.customer.selectedCustomerId });
          } else {
            throw new Error('Not logged in!');
          }
        } catch (err) {
          commit('setRequestFailed', { error: err.message });
        }
      }
    },
    async update({
      commit, rootState, rootGetters,
    }) {
      try {
        if (rootGetters['auth/isLoggedIn']) {
          const result = await fetch(
            rootState.customer.selectedCustomerId,
          );
          commit('setRequestSuccess', { result: result.result, customerId: rootState.customer.selectedCustomerId });
        } else {
          throw new Error('Not logged in!');
        }
      } catch (err) {
        commit('setRequestFailed', { error: err.message });
      }
    },
    async clear({ commit }) {
      commit('setRequestFailed', { error: '' });
    },
  },

  mutations: {
    //
    setRequestSuccess(state, { result, customerId }) {
      state.inProgress = false;
      state.myWarranties = result || state.myWarranties;
      state.isLoaded = true;
      state.lastUpdate = new Date();
      state.customer = customerId;
    },
    setRequestFailed(state, { error }) {
      state.inProgress = false;
      state.isLoaded = true;
      state.errorMessage = error;
    },
    setRequestPending(state) {
      state.inProgress = true;
      state.isLoaded = false;
      state.errorMessage = null;
      state.myWarranties = [];
      state.lastUpdate = null;
    },
  },
};
