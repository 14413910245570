<template>
  <search-input
    v-model="search.input"
    :placeholder="$t('account.account.myCustomers.searchRelation')"
    :items="search.items"
    :item="model"
    :autofocus="autofocus"
    :loading="!!search.loading"
    class="relation-search"
    :state="state"
    @search="searchRelations($event)"
    @selected="selectRelation"
  >
    <template v-slot:item="{ item, selected }">
      <template v-if="item.id !== -1">
        <Relation
          :value="item"
          :can-edit="selected"
          @input="setModel"
        />
      </template>
      <template v-else>
        <div class="crm-relation">
          <span
            class="crm-relation__create"
            :class="{'crm-relation__create--subtle': search.items.length>1}"
            v-html="$t(`account.account.myCustomers.createNewCustomer${search.input?'FromSearch':''}`,{search:search.input})"
          />
        </div>
      </template>
    </template>
  </search-input>
</template>

<script>
import SearchInput from '@/components/searchInput.vue';
import { mapState } from 'vuex';
import dialogs from '@/utils/dialogs';
import { GetCustomerRelations } from '@/api/api';
import { isEqual } from '@/utils/object';
import CustomerInfoDialog from './relationInfoDialog.vue';
import Relation from './relation.vue';

export default {
  name: 'RelationInput',
  components: {
    SearchInput,
    Relation,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    state: {
      type: [Object, Boolean],
      default: () => null,
    },
  },
  data() {
    return {
      search: {
        input: '', // Current search value
        items: [],
        query: '', // Search value corresponding to item
        loading: false,
      },
      model: null,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
  },
  validations() {
    return {
      model: {
        // No real validations yet
      },
    };
  },
  watch: {
    value() {
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
    this.searchRelations(this.search.input);
  },
  methods: {
    valueToModel() {
      this.model = this.value ? { ...this.value } : null;
    },
    async searchRelations(search) {
      this.search = { ...this.search, loading: { search } };
      try {
        const searchResult = (await GetCustomerRelations(
          this.customer.selectedCustomerId,
          {
            search,
            limit: 5,
          },
        ));

        if (!this.search.loading || (this.search.loading && this.search.loading.search === search)) {
          this.search = {
            ...this.search,
            items: [...searchResult, { id: -1 }],
            query: search,
            loading: false,
          };
        }
      } catch (err) {
        this.search = { ...this.search, loading: false };
        throw err;
      }
    },
    async selectRelation(relation) {
      if (relation && (relation.id < 0)) {
        const prefill = {};
        const inputStr = this.search.input;
        if (inputStr.indexOf('@') > 0) {
          const emailParts = /([^@]+)@(.*)/.exec(inputStr);
          const name = emailParts?.[1];
          prefill.email = inputStr;
          if (name) {
            const parts = name.split('.').filter((x) => x).map((x) => x[0].toUpperCase() + x.slice(1));
            prefill.firstName = parts[0] || '';
            prefill.lastName = parts.slice(1).join(' ') || '';
          }
        } else {
          const parts = inputStr.split(' ').filter((x) => x);
          prefill.firstName = parts[0] || '';
          prefill.lastName = parts.slice(1).join(' ') || '';
        }

        const result = await dialogs.show({
          component: CustomerInfoDialog,
          props: {
            value: prefill,
          },
        });
        this.searchRelations('');// clear
        if (result) {
          this.setModel(result);
        }
      } else {
        this.setModel(relation);
      }
    },
    blockAutocomplete: () => -1, // The correct value would be "off", but that does not work for Chrome
    async editRelation() {
      // try {
      const { model } = this;

      const result = await dialogs.show({
        component: CustomerInfoDialog,
        props: {
          value: model,
        },
      });
      if (result) {
        this.setModel(result);
      }
    },
    setModel(model) {
      if (!isEqual(model, this.model)) {
        this.model = model;
        this.$emit('input', model);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
