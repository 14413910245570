export function makeEquipmentSearchResult(search, result, addedItems) {
  return {
    items: result.map((item) => ({
      ...item,
      items: item.items.filter((i) => !(addedItems || []).find(
        (assigned) => (assigned.serial ? i.serial === assigned.serial : i.id === assigned.id),
      )),
    })).filter((x) => x.items.length),
    query: search,
    loading: false,
  };
}
export function assignedItemFromSelected(item, origEvent) {
  // Figure out if a specific serialNumber/itemIndex was selected
  let itemId = null;
  if (origEvent) {
    const path = origEvent.path ? [...origEvent.path] : [origEvent.srcElement || origEvent.target].filter((x) => x);
    let cur;
    // eslint-disable-next-line no-cond-assign
    while (cur = path.shift()) {
      itemId = cur.getAttribute && cur.getAttribute('data-item-id');
      if (itemId) break;
    }
  }
  const refItem = { ...((itemId ? item.items.find((i) => i.id === itemId) : null) || item.items[0]) };
  const refStock = refItem.stock;
  delete refItem.stock;
  return {
    ...item,
    id: refItem.id,
    items: null,
    serial: refItem.serial,
    reference: refItem,
    quantity: undefined,
    stock: refStock,
  };
}

export function itemToSplitId(item) {
  return {
    externalorderid: item.order.externalid,
    orderid: item.order.orderid,
    orderlineid: item.order.orderlineid,
    index: item.index ?? item.reference?.index,
    serial: item.serial,
  };
}

export function assignableIdToStr(id) {
  // This is analogous to the
  // COALESCE(
  //         COALESCE(NULLIF(o.reference->>'serial',''), NULLIF(o.itemref->>'serial','')),
  //         CASE WHEN LENGTH(o.order->>'externalid')>1 THEN
  //             CONCAT(o.order->>'externalid',':',o.order->>'orderlineid',':',o.itemref->'index')
  //         ELSE
  //             NULL
  //         END,
  //         CONCAT('O:', o.order->>'id', '_OL:', o.order->>'orderlineid', ':',o.itemref->'index')
  //     ) as id,
  if (id.serial) {
    return id.serial;
  } if (id.externalorderid) {
    return `${id.externalorderid}:${id.orderlineid}:${id.index}`;
  }
  return `O:${id.orderid}_OL:${id.orderlineid}:${id.index}`;
}

export function relationOfReference(olRef) {
  if (olRef && (olRef.email || olRef.relationId || olRef.firstName || olRef.lastName || olRef.phone)) {
    return {
      id: olRef.relationId,
      email: olRef.email,
      firstName: olRef.firstName,
      lastName: olRef.lastName,
      phone: olRef.phone,
      language: olRef.language,
    };
  }
  return null;
}

export function isValidRelation(relation) {
  return (relation?.email?.trim()?.length)
    || (relation?.firstName?.trim()?.length || relation?.lastName?.trim()?.length);
}

export function itemIdsOfsOrderline({
  order,
  orderline,
}) {
  const ids = [];
  const olQty = orderline.qty || orderline.quantity;
  const serials = orderline.serials || orderline.serial || [];
  for (let i = 0; i < olQty; ++i) {
    ids.push({
      externalorderid: order.externalorderid,
      orderid: !order.externalorderid ? order.id : null,
      orderline: orderline.navlinenr || orderline.orderline || orderline.id,
      index: 1 + i,
      serial: serials[i],
    });
  }

  return ids;
}

export function refToItemId({ order, orderline, ref }) {
  const serials = orderline.serials || orderline.serial || [];
  return {
    externalorderid: order.externalorderid,
    orderid: !order.externalorderid ? order.id : null,
    orderline: orderline.navlinenr || orderline.orderline || orderline.id,
    index: ref.index,
    serial: serials[ref.index - 1],
  };
}
