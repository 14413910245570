<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div
            v-click-outside="close"
            class="modal-body"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'BcfModal',
  directives: {
    clickOutside: vClickOutside.directive,
  },

  created() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        this.close();
      }
    };
  },

  methods: {
    close() {
      this.$emit('close', true);
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
            * The following styles are auto-applied to elements with
            * transition="modal" when their visibility is toggled
            * by Vue.js.
            *
            * You can easily play with the modal transition by editing
            * these styles.
            */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
