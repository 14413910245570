<template>
  <div>
    <SectionTitle>{{ $t('items.specifications') }}</SectionTitle>
    <table class="table table--striped m-b-4">
      <tbody>
        <tr>
          <td class="nowrap">
            <b>{{ $t('items.articleNumber') }}</b>
          </td>
          <td>{{ item.externalid }}</td>
        </tr>
        <tr
          v-for="attr in itemAttributes"
          :key="attr.key"
        >
          <td class="nowrap topaligned">
            <b>{{ attr.key }}</b>
          </td>
          <td v-if="((typeof attr.value === 'string') && (attr.value.indexOf('.pdf')> 0))">
            <a :href="attr.value">Download</a>
          </td>
          <td v-else-if="(Array.isArray(attr.value))">
            <ul class="m-b-0">
              <li
                v-for="val in attr.value"
                :key="val"
                v-html="val"
              />
            </ul>
          </td>
          <td
            v-else
            v-html="attr.value"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.topaligned {
  vertical-align: top;
}
</style>

<script>
import { mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';

export default {
  components: {
    SectionTitle,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
    }),
    itemAttributes() {
      const itemAttributes = [];
      // const { attributeTranslations } = this;
      const specificationAttributes = [
        'item_brand',
        'model',
        'color',
        'paint_des',
        'frame_size',
        'helmet_size',
        'range',
        'frame',
        'fork',
        'material',
        'groupset',
        'crank',
        'crankset',
        'crank_arm',
        'crank_chainring',
        'cassette',
        'electric',
        'drive_unit',
        'battery',
        'electriccharger',
        'item_brake_type',
        'front_brake',
        'rear_brake',
        'front_derailleur',
        'rear_derailleur',
        'handlebar',
        'handlebar_width',
        'cockpit_range',
        'stem',
        'seatpost',
        'seatpost_dia',
        'sad_range',
        'saddle',
        'wheelset',
        'front_wheel',
        'rear_wheel',
        'wheel_dia',
        'outer_tyre',
        'tire_type',
        'max_tire_clearance_700c',
        'configurable',
        'paint_bac',
        'paint_c2',
        'paint_c3',
        'paint_c4',
        'paint_c5',
        'paint_c6',
        'paint_finish',
        'sex',
        'weight_limit',
        'compatible_frameplatforms',
        'ean',
        'pedals',
        'niveau',
        'condition',
        'warranty',

      ];

      Object.keys(this.item.attributes).forEach((x) => {
        if ((typeof this.item.attributes[x] === 'string') || (Array.isArray(this.item.attributes[x]))) {
          if (
            specificationAttributes.includes(x)
          ) {
            itemAttributes.push({
              attrName: x,
              key: this.attributeTranslations[x] ? this.attributeTranslations[x] : x,
              value: this.item.attributes[x],
            });
          }
        }
      });

      return itemAttributes.sort((a, b) => {
        let indA = specificationAttributes.indexOf(a.attrName);
        let indB = specificationAttributes.indexOf(b.attrName);

        if (indA === -1) {
          if (indA === -1) { indA = specificationAttributes.length - 1; }
        }
        if (indB === -1) { indB = specificationAttributes.length - 1; }

        if (indA < indB) {
          return -1;
        } if (indA > indB) {
          return 1;
        }
        return 0;
      });
    },
  },
};
</script>
