<template>
  <label
    :class="{
      'labelcheckbox':true,
      'labelcheckbox--disabled':disabled
    }"
  >
    <input
      v-model="valueInt"
      type="checkbox"
      class="checkbox-hidden"
      :value="checkedValue"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
    >
    <span
      class="checkbox m-r-1"
    />
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Array, Boolean],
      default: null,
    },
    checkedValue: {
      type: [String, Object, Boolean],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
<style scoped lang="scss">

.labelcheckbox{
  &--disabled{
    opacity: .5;
    //color: #999;
    cursor: default;
  }
}

.checkbox-hidden {
  display: none;
}
.checkbox-hidden:checked ~ .checkbox::after {
  content: '\e9c3';
  font-family: unicons;
  font-size: 2.25rem;
  color: #d1000d;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 2.25rem;
  width: 2.25rem;
  text-align: center;
}

</style>
