<template>
  <transition name="slide">
    <div class="overlay">
      <aside v-click-outside="vcoConfig">
        <slot />
      </aside>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      vcoConfig: {
        handler: this.handler,
        middleware: this.middleware,
        events: ['dblclick', 'click'],
        isActive: true,
      },
    };
  },
  created() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode === 27) {
        this.close();
      }
    };
  },
  methods: {
    close() {
      this.$emit('close', true);
    },
    onClickOutside() {
    },
    handler() {
      this.$emit('close', true);
    },
    middleware(event) {
      return event.target.className === 'overlay';
    },
  },
};
</script>
