<template>
  <div class="sessionpage">
    <!-- Left side / Image -->
    <div class="sessionpage__media" />

    <!-- Right side / Form -->
    <div class="sessionpage__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Login',
  props: {
    nextRoute: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      errorMessage: state => state.auth.error,
      inProgress: state => state.auth.inProgress,
    }),
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
  },
  methods: {
    async loginSubmit() {
      await this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password,
      });
      if (this.isLoggedIn) {
        if (this.nextRoute === undefined) {
          this.$router.push('/');
        } else {
          // Push previously requested route
          this.$router.push({ path: this.nextRoute, query: this.query });
        }
      }
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
