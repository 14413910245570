<template>
  <span
    class="list__item"
    :class="classes"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'ListItem',
  inheritAttrs: true,
  props: {
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    interactive: {
      // Setting this flag will apply the :hover effects for the full component instead of just the icon and anchor within it
      type: Boolean,
      default: false, // Defaults to false merely in order not to disturb previous uses of this component
    },
  },
  computed: {
    classes() {
      const isDisabled = ('disabled' in this.$attrs) || (this.disabled);
      return {
        'list__item--small': ('small' in this.$attrs),
        'list__item--arrow-after': ('arrow' in this.$attrs),
        'list__item--icon-before': ('iconBefore' in this.$attrs) || ('icon-before' in this.$attrs),
        'list__item--icon-after': ('iconAfter' in this.$attrs) || ('icon-after' in this.$attrs),
        'list__item--icon-big': ('iconBig' in this.$attrs) || ('icon-big' in this.$attrs),
        'list__item--toggle-before': ('toggleBefore' in this.$attrs) || ('toggle-before' in this.$attrs),
        'list__item--disabled': isDisabled,
        'list__item--interactive': (('interactive' in this.$attrs) || (this.interactive)) && !isDisabled,
      };
    },
  },
};
</script>
