<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <router-link :to="{name: 'account'}">
                <i class="uil uil-arrow-left" />
              </router-link>
              <h1>{{ $t('account.about.promo.title') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <loader v-if="pending" />

    <div
      v-else
      class="container m-t-2"
    >
      <div class="row">
        <div class="col-lg-6 m-b-4">
          <SharePointDriveList
            site="RidleyMedia"
            :logo="require('@/assets/img/brands/logo_ridley_small.png')"
          />
        </div>
        <div class="col-lg-6 m-b-4">
          <SharePointDriveList
            site="EddyMerckxMedia2"
            :logo="require('@/assets/img/brands/logo_eddy_merckx_small.png')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 m-b-4">
          <SharePointDriveList
            site="ForzaMedia"
            :logo="require('@/assets/img/brands/logo_forza_small.png')"
          />
        </div>
        <div class="col-lg-6 m-b-4">
          <SharePointDriveList
            site="CyclingFactoryMedia"
            :logo="require('@/assets/img/bcf-logo.svg')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SharePointDriveList from '@/components/sharepointDriveList.vue';

export default {
  name: 'SharePointDrive',
  components: {
    SharePointDriveList,
  },
  metaInfo() {
    return {
      title: this.$t('account.about.promo.title'),
    };
  },
};

</script>
