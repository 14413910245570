<template>
  <div
    v-if="pages.length > 1"
    class="pagination"
  >
    <ul>
      <li>
        <button
          type="button"
          :disabled="!(currentPage !== 1)"
          @click="currentPage--"
        >
          <i class="uil uil-arrow-left" />
        </button>
      </li>
      <li>
        <button
          v-for="pageNumber in pages.slice(currentPage-5 < 0 ? 0 : currentPage - 5, currentPage+(10 - (currentPage - (currentPage-5 < 0 ? 0 : currentPage - 5))))"
          :key="pageNumber"
          type="button"
          :class="pageNumber === currentPage ? 'current' : ''"
          @click="currentPage = pageNumber"
        >
          {{ pageNumber }}
        </button>
      </li>
      <li>
        <button
          type="button"
          :disabled="!(currentPage < pages.length)"
          @click="currentPage++"
        >
          <i class="uil uil-arrow-right" />
        </button>
      </li>
    </ul>

    <div class="pagination__options">
      <select v-model="itemsPerPageInt">
        <option value="5">
          5
        </option>
        <option value="10">
          10
        </option>
        <option value="20">
          20
        </option>
        <option value="50">
          50
        </option>
        <option value="100">
          100
        </option>
      </select>
      {{ $t('items.perPage', {total: pages.length}) }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    page: {
      type: Number,
      default: null,
    },
    pages: {
      type: Array,
      default() {
        return [];
      },
    },
    itemsPerPage: {
      type: Number,
      default: null,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(newVal) {
        this.$emit('pageChanged', newVal);
      },
    },
    itemsPerPageInt: {
      get() {
        return this.itemsPerPage;
      },
      set(newVal) {
        this.$emit('itemsPerPageChanged', newVal);
      },
    },
  },
};
</script>
