<template>
  <span
    class="badge"
    :class="'badge--'+type"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    type: {
      type: String,
      default: null,
    },
  },
};
</script>
