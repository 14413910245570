<template>
  <footer class="main__footer">
    <div class="main__footer__top">
      <span />
      <span />
      <span />
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main__footer__inner">
            <div>
              <img
                src="@/assets/img/bcf-logo.svg"
                title="Logo"
                alt="BCF Logo"
              >
              <img
                src="@/assets/img/brands/logo_ridley_small.png"
                title="Ridley"
                alt="Ridley logo"
              >
              <img
                src="@/assets/img/brands/logo_eddy_merckx_small.png"
                title="Eddy Merckx"
                alt="Eddy Merckx logo"
              >
              <img
                src="@/assets/img/brands/logo_forza_small.png"
                title="Forza"
                alt="Forza logo"
              >
              <img
                src="@/assets/img/brands/logo_corsa_small.png"
                title="Corsa"
                alt="Corsa logo"
              >
            </div>
            <div style="flex-direction: column;align-self: right;">
              <div style="font-size: 0.7em; align-self: stretch; text-align: right; margin-top: -0.7em;">
                v{{ version }}
              </div>
              <small>
                Copyright © 2020 by Belgian cycling factory. All Rights Reserved. Check our
                <router-link :to="{name: 'myContract'}">sales conditions</router-link>.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import packageJson from '../../package.json';

export default {
  data() {
    return {
      version: packageJson.version,
    };
  },
};
</script>
