<template>
  <BcfDialog
    ref="dialog"
    v-dialog-config="{
      overlay,
      beforeClose: beforeClose // Prevent unsaved changes from getting lost
    }"
    class="assign_customer_dialog"
    :overlay="overlay"
    wide
    :title="orderline?.description || $t('account.account.myOrders.orderItemReference.title')"
  >
    <div
      v-if="orderline"
      class="content__wrapper"
    >
      <div class="section__title">
        <h1>
          {{ $t('account.account.myOrders.orderItemReference.title') }}
        </h1>
      </div>
      <p class="section__description">
        {{ $t('account.account.myOrders.orderItemReference.explanation') }}
      </p>
      <orderline-reference
        :reference="reference"
        :item-index="0"
        @input="updateReference($event)"
      />
      <Message
        v-if="errorMessage"
        type="error"
      >
        {{ errorMessage }}
      </Message>
      <usps
        :title="$t('account.account.myBackOrders.assign.usps.title')"
        :usps="usps"
      />
    </div>
    <template v-slot:footer>
      <button
        class="button"
        type="button"
        @click="close"
      >
        {{ $t('general.cancel') }}
      </button>
      <button
        class="button button--primary"
        type="submit"
        @click.prevent="close(model, 'save')"
      >
        <loading-overlay :loading="isLoading">
          {{ $t('general.save') }}
        </loading-overlay>
      </button>
    </template>
  </BcfDialog>
</template>
<script>

import Message from '@/components/Message.vue';
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import dialogs from '@/utils/dialogs';
import OrderlineReference from '@/views/account/account/mybackorders/orderlineReference.vue';
import { isEqual } from '@/utils/object';
import loadingOverlay from '@/elements/LoadingOverlay.vue';
import usps from '@/components/usps.vue';

export default {
  components: {
    Message,
    BcfDialog,
    OrderlineReference,
    loadingOverlay,
    usps,
  },
  props: {
    overlay: { // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    orderline: {
      type: Object,
      default: () => {},
    },
    reference: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      model: { ...this.reference },
    };
  },
  computed: {
    usps() {
      return [
        {
          icon: 'award',
          content: this.$t('account.account.myBackOrders.assign.usps.registration'),
        },
        {
          icon: 'clock',
          content: this.$t('account.account.myBackOrders.assign.usps.warranty'),
        },
        {
          icon: 'envelope-info',
          content: this.$t('account.account.myBackOrders.assign.usps.updates'),
        },
      ];
    },
  },
  mounted() {
  },
  methods: {
    updateReference(updatedReference) {
      this.model = updatedReference;
    },
    async beforeClose(result, source) {
      if (source === 'dismiss') {
        if (!isEqual(this.reference, this.model)) {
          const confirmed = await dialogs.show({
            title: this.$t('general.unsavedChanges.title'),
            content: this.$t('general.unsavedChanges.content'),
            type: 'cancelYes',
          });
          if (confirmed) {
            return true;
          }
          return false;
        }
      }
      return undefined;
    },
    close(result, source = 'cancel') {
      return this.overlay.close({ reference: result, type: source }, source);
    },
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "@/assets/scss/_colors.scss";

@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "./node_modules/bootstrap/scss/mixins";

.dialog-overlay__modal__footer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.content__wrapper {
  padding-bottom: 1em;
}

.section{
  margin-bottom: 2em;
}

.section__title{
  display: flex;
  flex-direction: row;

  >h1,h2,h3,h4,h5{
    flex: 1 0 auto;

    margin-bottom: 1.5rem;
  }

  >.section__control{
    flex: 0 0 auto;
  }
}

.section__description {
  color: #8d99af;
}

.quantity_input{
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
