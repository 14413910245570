import { LogIn, GetCustomerByid } from '@/api/api';
import { userUpdate } from '@/api/user';
import { enablePush, disablePush } from '@/api/notifications';

/** Context */
export default {
  namespaced: true,
  state: {
    user: null,
    error: null,
    inProgress: false,
  },
  getters: {
    // Possibly have to check for expirationtime as well?
    isLoggedIn: state => !state.inProgress && state.user !== null,
    getToken: (state, getters, rootState) => rootState.auth.user.token,
  },
  actions: {
    async login({ commit, dispatch, rootState }, { username, password }) {
      commit('setRequestUserPending');
      try {
        const result = await LogIn(username, password);
        commit('setRequestUserSuccess', { user: result });
        if (result.customerids.length > 0) {
          if (result.customerids.length === 1) {
            // Fetch the single customer
            const customer = await GetCustomerByid(result.customerids[0]);
            await dispatch(
              'customer/setSelectedCustomer',
              { selectedCustomer: customer },
              { root: true },
            );
          }
        }
        await dispatch('language/getAttributeTranslations', null, {
          root: true,
        });
        if (result.lang !== rootState.language.current) {
          await userUpdate(result.id, { ...result, lang: rootState.language.current });
          dispatch('updateUser', { user: { ...result, lang: rootState.language.current } });
        }

        commit('setRequestUserIsLoaded');
      } catch (err) {
        commit('setRequestUserFailed', { error: err.errorMessage });
      }
    },
    async logout({ commit, dispatch }) {
      commit('clear');
      dispatch('customer/clear', {}, { root: true });
      dispatch('items/clear', {}, { root: true });
      const reg = await navigator.serviceWorker.ready;
      if (reg) {
        reg.pushManager.getSubscription().then(async (subscription) => {
          if (subscription) {
            await subscription.unsubscribe();
          }
        });
      }
    },
    async updateUser({ commit }, { user }) {
      commit('updateUser', { user });
    },
    async togglePushNotifications({ commit }, isEnabled) {
      if (isEnabled) {
        enablePush();
      } else {
        disablePush();
      }
      commit('togglePushNotifications', isEnabled);
    },
  },
  mutations: {
    setRequestUserSuccess(state, { user }) {
      state.user = user;
      state.error = null;
    },
    setRequestUserIsLoaded(state) {
      state.inProgress = false;
    },
    setRequestUserFailed(state, { error }) {
      state.inProgress = false;
      state.error = error;
    },
    setRequestUserPending(state) {
      state.inProgress = true;
      state.error = null;
    },
    clear(state) {
      state.inProgress = false;
      state.user = null;
      state.error = null;
    },
    updateUser(state, { user }) {
      state.user = user;
    },
    togglePushNotifications(state, isEnabled) {
      state.user.pushnotifications = isEnabled;
    },
  },
};
