import { GetCustomerByUserId, GetCustomerByid } from '@/api/api'; /** Context */

export default {
  namespaced: true,
  state: {
    inProgress: false,
    selectedCustomerId: null,
    selectedCustomer: null,
    errorMessage: null,
  },
  getters: {
    getDefaultShippingAddress: (state) => state.selectedCustomer.addresses.filter((a) => a.default === 1)[0],
    selectedCustomerId: (state) => state.selectedCustomerId,
  },
  actions: {
    async setCustomerUuid(
      { commit },
      { customerUuid },
    ) {
      commit('setCustomerUuid', { customerUuid });
    },
    async setSelectedExternalCustomerId(
      { dispatch, rootState },
      { selectedExternalCustomerId },
    ) {
      const possibleCustomers = await GetCustomerByUserId(rootState.auth.user.id);
      const customer = possibleCustomers.find((o) => o.externalid === selectedExternalCustomerId);
      if (customer) {
        dispatch('setSelectedCustomer', {
          selectedCustomer: customer,
        });
      }
    },

    async setSelectedCustomer(
      { commit, dispatch },
      { selectedCustomer },
    ) {
      commit('setSelectedCustomerId', {
        selectedCustomerId: selectedCustomer.id,
      });
      commit('setRequestPending');
      try {
        dispatch('cart/createCartForCustomerIfNotExists', selectedCustomer.id, {
          root: true,
        });

        commit('setRequestSuccess', { selectedCustomer });
        dispatch('items/clear', null, { root: true });
      } catch (err) {
        commit('setRequestFailed', { error: err.errorMessage });
      }
    },

    async refreshCustomer(
      { commit, state },
    ) {
      // console.log('Refresh customer!');
      commit('setRequestPending');
      try {
        const customer = await GetCustomerByid(state.selectedCustomerId);
        commit('setRequestSuccess', { selectedCustomer: customer });
      } catch (err) {
        commit('setRequestFailed', { error: err.errorMessage });
      }
    },

    clear({ commit }) {
      commit('clear');
    },
  },

  // mutations
  mutations: {
    setCustomerUuid(state, { customerUuid }) {
      state.selectedCustomer.customeruuidkey = customerUuid;
    },
    setSelectedCustomerId(state, { selectedCustomerId }) {
      state.selectedCustomerId = selectedCustomerId;
      Vue.delete(state, 'possibleCustomers');
      Vue.delete(state, 'possibleCustomersLastUpdate');
    },
    setRequestSuccess(state, { selectedCustomer }) {
      state.inProgress = false;
      state.selectedCustomer = selectedCustomer;
      state.lastUpdate = new Date();
    },
    setRequestFailed(state, { error }) {
      state.inProgress = false;
      state.error = error;
    },
    setRequestPending(state) {
      state.inProgress = true;
      state.lastUpdate = null;
    },
    clear(state) {
      state.inProgress = false;
      state.selectedCustomerId = null;
      state.selectedCustomer = null;
      state.errorMessage = null;
      state.lastUpdate = null;
      state.customeruuidkey = null;
    },
  },
};
