<template>
  <div
    :id="containerId"
    ref="container"
    class="search-input"
  >
    <input
      v-if="!item"
      :id="inputId"
      ref="input"
      :value="searchVal"
      type="search"
      :autofocus="autofocus"
      autocomplete="off"
      class="form-control"
      :class="validationClasses"
      :placeholder="placeholder === null ? `${$t('search')}...` : placeholder"
      :disabled="disabled"
      @keydown="onKeyDown"
      @input="searchChanged($event.target.value)"
      @focus="onFocus"
      @blur="onBlur"
      @click="onClick"
    >
    <div
      v-else
      class="search-input__selected-item"
      :class="validationClasses"
    >
      <div class="search-input__item">
        <slot
          name="item"
          :item="item"
          :selected="true"
        >
          {{ item.description }}
        </slot>
      </div>
      <div class="search-input__clear-item">
        <button
          class="search-input__clear"
          @click.prevent.stop="clearItem"
        >
          <i class="uil uil-times" />
        </button>
      </div>
    </div>

    <b-popover
      ref="popover"
      :target="inputId"
      :placement="'bottom'"
      :fallback-placement="['top']"
      boundary="scrollParent"
      :boundary-padding="0"
      :container="container !== undefined ? container : containerId"
      :style="{width: width}"
      @shown="onShown"
      @blur="onBlur"
    >
      <!--boundary="window"-->
      <div
        class="search-input__result-list"
      >
        <template v-if="showItems.length>0">
          <div
            v-for="i of showItems"
            :key="i.index"
            class="search-input__item"
            :class="{
              'search-input--selected': i.index===selectedIndex
            }"
            @click="selectItem(i.item, $event)"
          >
            <slot
              name="item"
              :item="i.item"
              :selected="false"
            >
              {{ i.item.description }}
            </slot>
          </div>
        </template>
        <div
          v-else
          class="search-input__empty"
        >
          <slot
            name="empty"
          >
            <template v-if="loading">
              {{ $t('general.loading') }}
            </template>
            <template v-else-if="searchVal">
              {{ $t('general.searchNoResults', {search: searchVal}) }}
            </template>
            <template v-else>
              {{ $t('general.noResults') }}
            </template>
          </slot>
        </div>
      </div>

      <div :style="{display: 'flex', flexDirection: 'row'}">
        <loader
          v-if="loading"
          class="search-input__loader"
          :style="{flex: `1 1 ${width}`}"
        />
      </div>
    </b-popover>
  </div>
</template>
<script>
import loader from '@/elements/loader.vue';

let inputId = 0;

export default {
  name: 'SearchInput',
  components: {
    loader,
  },
  props: {
    fetch: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    state: {
      // Formvalidation
      type: Boolean,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    container: {
      type: [String, Object, Function, HTMLDivElement, HTMLElement],
      default: undefined,
    },
  },
  data() {
    const id = inputId++;
    return {
      inputId: `search-input-${id}`,
      containerId: `search-input-container-${id}`,
      searchVal: '',
      lastSearch: '',
      error: null,
      width: 'auto',
      popover: null,
      selectedIndex: undefined,
    };
  },
  computed: {
    showItems() {
      return this.items.map((item, index) => ({ item, index }));
    },
    validationClasses() {
      const { state } = this;
      return state === true ? {
        'is-valid': true,
      } : state === false ? {
        'is-invalid': true,
      } : {};
    },
  },
  watch: {
    value: {
      handler(value, oldValue) {
        if (oldValue !== value) {
          this.searchVal = value;
        }
      },
    },
  },
  mounted() {
    this.searchVal = this.value || '';
    if (this.autofocus && this.$refs.input && this.$refs.input.focus) {
      /** @type {HTMLInputElement} */
      const { input } = this.$refs;
      if (document.activeElement !== input) {
        this.$refs.input.focus();
      }
      if (document.activeElement === input) {
        this.$nextTick(() => {
          this.$refs.popover.$emit('open');
        });
      }
      this.width = `${this.$refs.container.getBoundingClientRect().width}px`;
    }
  },
  methods: {
    searchChanged(search) {
      this.searchVal = search;
      if (search !== this.lastSearch) {
        this.lastSearch = search;
        this.$emit('search', search);
        this.$emit('input', search);
        this.$emit('changed', search);
      }
    },
    onFocus() {
      if (!this.disabled) this.$refs.popover.$emit('open');
    },
    onClick() {
      if (this.$refs.popover.show) this.$refs.popover.$emit('close');
      else if (!this.disabled) this.$refs.popover.$emit('open');
    },
    onBlur(event) {
      if (typeof event.relatedTarget !== 'undefined') {
        // Browser supports relatedTarget, which contains new element getting focus
        let cur = event.relatedTarget;
        const ownElements = [this.$el, this.$refs.popover.$el, this.popover].filter((x) => x);
        while (cur) {
          if (ownElements.includes(cur)) {
            // Self-click, Component still in focus, don't hide yet
            return;
          }
          cur = cur.parent;
        }
        this.$refs.popover.$emit('close');
      } else {
        // using somewhat ugly timeout here to let any click handlers fire first. (or we risk hiding the item being
        // clicked on in the popout)
        setTimeout(() => {
          if (this.$refs.popover.show) this.$refs.popover.$emit('close');
        }, 100);
      }
    },
    onShown(ev) {
      // Ugly workaround to make the popout match input width
      this.width = `${this.$refs.container.getBoundingClientRect().width}px`;
      ev.relatedTarget.style.width = this.width;
      // Keep track of the popover element to detect self-clicks
      this.popover = ev.relatedTarget;
      this.$nextTick(() => this.$refs.popover.$forceUpdate());
    },
    selectItem(item, event) {
      // Emit selection and close
      this.$emit('selected', item, event);
      this.$refs.popover.$emit('close');
    },
    clearItem(event) {
      this.$emit('selected', null, event);
    },
    onKeyDown(/** @type {KeyboardEvent} */event) {
      if (event?.key === 'Enter' && this.showItems.length) {
        const index = this.selectedIndex || 0;
        const selected = this.showItems[index];
        if (selected) {
          this.selectItem(selected.item, event);
        }
      } else if (event?.key === 'ArrowDown') {
        let next = (this.selectedIndex ?? -1) + 1;
        if (next >= this.showItems?.length) {
          next = null;
        }
        this.selectedIndex = next;
      } else if (event?.key === 'ArrowUp') {
        let next = (this.selectedIndex ?? this.showItems.length) - 1;
        if (next < 0) {
          next = null;
        }
        this.selectedIndex = next;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
