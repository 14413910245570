<template>
  <span
    class="list__item list__item--location"
    :class="classes"
  >
    <div class="list__item__icon">
      <i class="uil uil-map-marker-alt" />
    </div>
    <div class="list__item__content">
      <slot />
    </div>
  </span>
</template>

<script>
export default {
  name: 'ListItemLocation',
  inheritAttrs: true,
  computed: {
    classes() {
      return {
        'list__item--small': this.small,
        'list__item--selected': ('selected' in this.$attrs),
        'list__item--arrow-after': ('arrow' in this.$attrs),
      };
    },
  },
};
</script>
