<template>
  <span
    class="progress"
    :class="'progress--'+progressType"
  >
    <span
      class="progress__bar"
      :class="'progress__bar--'+progressState"
      :style="{'width':value+'%'}"
    />
  </span>
</template>

<script>
export default {
  name: 'Progress',
  props: {
    value: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    progressState() {
      /*
                This is an example on how the component should do all the work.
                Not sure how the data is received, so feel free to delete or modify...
                */
      let state = 'low';
      state = (this.value <= 25) ? 'low' : state;
      state = (this.value <= 75 && this.value > 25) ? 'medium' : state;
      state = (this.value > 75) ? 'full' : state;
      return state;
    },
    progressType() {
      return (this.type) ? this.type : 'normal';
    },
  },
};
</script>
