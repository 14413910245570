<template>
  <div
    :class="{
      'dialog': true,
      'dialog--wide': wide
    }"
  >
    <div
      :class="{
        'dialog__header': true
      }"
    >
      <slot name="header">
        <div
          :class="{
            'dialog__headercontent': true
          }"
        >
          <slot name="title">
            <strong>{{ title }}</strong>
          </slot>
        </div>

        <div
          :class="{
            'dialog__headercontrols': true
          }"
        >
          <slot name="header-controls">
            <div
              :class="{
                'dialog__close': true
              }"
              class="dialog__close"
              @click="overlay.close(undefined, 'close')"
            >
              &times;
            </div>
          </slot>
        </div>
      </slot>
    </div>

    <div
      :class="{'dialog__content': true}"
    >
      <slot>
        <span
          v-if="htmlContent"
          v-html="htmlContent"
        />
        <span
          v-else
        ><!--style="white-space: pre"-->
          {{ content }}
        </span>
      </slot>
    </div>

    <div
      v-if="hasFooterSlot"
      :class="{'dialog__footer': true}"
    >
      <slot name="footer">
        <div :class="{'dialog__footer-controls': true}">
          <button
            v-for="(item, index) of footerButtons"
            :key="index"
            :class="`button ${item.class}`"
            @click.prevent.stop="overlay.close(item.value, 'button')"
          >
            {{ $t(item.label) }}
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
/**
 * A component wrapper for the dialog-overlay. Meant to be simple to use for yes/no modals while allowing complexer use
 * cases via slots. Adjusted to fit our styling better than the native bootstrap dialog
 *
 * Would've ideally
 */

import vClickOutside from 'v-click-outside';

const typeToFooterButtons = {
  ok: [
    {
      value: true,
      class: 'button--secondary',
      label: 'general.ok',
    },
  ],
  cancelYes: [
    {
      value: undefined,
      class: 'button--secondary',
      label: 'general.cancel',
    },
    {
      value: true,
      class: 'button--primary',
      label: 'general.yes',
    },
  ],
  cancelDelete: [
    {
      value: undefined,
      class: 'button--secondary',
      label: 'general.cancel',
    },
    {
      value: true,
      class: 'button--danger',
      label: 'general.delete',
    },
  ],
};

export default {
  name: 'BcfDialog',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    overlay: {
      // A control object to an overlay
      type: Object,
      default: null,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Dialog',
    },
    htmlContent: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: undefined,
    },
    scroll: {
      type: String,
      /**
       * Options allowed:
       *  - content:  Will set content region of the modal to overflow: auto
       *  - window:   Will set the overlay itself to overflow:auto (scrolling the dialog as a whole)
       *  - any other value will leave overflow values to their HTML defaults
       */
      default: 'content',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    footerButtons() {
      return typeToFooterButtons[this.type] || [];
    },
    hasFooterSlot() {
      return (!!this.$slots.footer) || this.footerButtons.length;
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
