<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <span class="main__title__action--left">
                <router-link :to="{name: 'prebook', params: { brand: this.$route.params.brand } }">
                  <i class="uil uil-arrow-left" />
                </router-link>
              </span>
              <h1>{{ $t("prebook.checkout.title") }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <loading-overlay :loading="loading">
      <div
        v-if="error"
        class="container"
      >
        <Message
          class="m-t-2"
          type="error"
          :icon="false"
          :show-close="false"
        >
          {{ error }}
        </Message>
      </div>
      <div v-else>
        <div
          v-if="prebookProducts.length > 0"
          class="container"
        >
          <div class="row">
            <div class="col">
              <div class="steps-progress">
                <div class="steps-progress__item steps-progress__item--active">
                  1
                </div>
                <div class="steps-progress__item">
                  2
                </div>
                <div class="steps-progress__item">
                  <i class="uil uil-check" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container m-t-4">
          <router-view />
          <div class="row">
            <div class="col-lg-7 m-b-4">
              <div
                v-for="(group, i) in generated.groupedOrders"
                :key="'group'+(i+1)"
              >
                <SectionTitle>Order #{{ (i+1) }}</SectionTitle>
                <div
                  class="highlight"
                  style="margin-bottom: 10px; background: white; font-size: 1.75rem;"
                >
                  <div v-if="group.date">
                    <strong>{{ $t('cart.deliveryDate') }}: </strong> {{ formatDate(group.date) }}
                  </div>
                  <div v-if="group.shippingcost">
                    <strong>Shippingcost:</strong> + <price
                      :currency="generated.currency"
                      :price="group.shippingcost"
                    />
                  </div>
                </div>

                <card
                  v-for="line in group.lines"
                  :key="line.item.id"
                  style="margin-bottom: 30px"
                >
                  <itemcard
                    v-if="line.item.externalid"
                    class="product__item"
                    :show-availability="false"
                    :quantity="line.orderline.quantity"
                    :summaryitem="line.item"
                  />
                  <Message
                    v-for="error in line.errors"
                    :key="i + error"
                    class="m-t-2"
                    type="error"
                    :icon="false"
                    :show-close="false"
                  >
                    {{ $t('cart.messages.' + error) }}
                  </Message>

                  <Message
                    v-for="warning in line.warnings"
                    :key="i + warning"
                    class="m-t-2"
                    type="warning"
                    :icon="false"
                    :show-close="false"
                  >
                    {{ $t('cart.messages.' + warning) }}
                  </Message>
                </card>
              </div>
            </div>

            <div class="col-lg-5 m-b-4">
              <SectionTitle>{{ $t('cart.prices') }}</SectionTitle>

              <cart-prices-table :generated="generated" />
              <div
                v-if="!showRetailPrices && balanceExceedsCreditlimit && isPreviewMode"
              >
                <myBalanceStatus class="m-t-4" />
                <div class="form-group form-group--checkbox">
                  <checkbox
                    v-model="balanceApproved"
                    :checked-value="{ type: 'preorder', value: true }"
                  >
                    <span>{{ $t('account.account.myBalance.approved') }}</span>
                  </checkbox>
                </div>
              </div>
              <!-- Use below disabled line to activate RTS -->
              <!-- :disabled="!isValid || (!balanceApproved && balanceExceedsCreditlimit)" -->
               <!-- :disabled="!isValid" -->
              <button
                v-if="prebookProducts.length > 0"
                class="button button--danger w-100"
                :disabled="!isValid || (!balanceApproved && (balanceExceedsCreditlimit && checkProhibitedActions === 1))"
                @click="confirmPrebook"
              >
                {{ $t('cart.nextStep') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </loading-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import card from '@/elements/Card.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import itemcard from '@/views/items/itemCard.vue';
import { availabilityText } from '@/utils/cartUtils';
import Message from '@/components/Message.vue';
import cartPricesTable from '@/components/cartPricesTable.vue';
import myBalanceStatus from '@/components/myBalanceStatus.vue';
import { formatDate } from '@/utils/dateUtils';
import checkbox from '@/elements/checkbox.vue';
import price from '@/elements/Price.vue';
import LoadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  components: {
    card,
    SectionTitle,
    itemcard,
    Message,
    cartPricesTable,
    myBalanceStatus,
    checkbox,
    price,
    LoadingOverlay,
  },
  metaInfo() {
    return {
      title: this.$t('header.cart'),
    };
  },
  data() {
    return {
      balanceApproved: false,
      creditlimit: 0,
    };
  },
  computed: {
    checkProhibitedActions() {
      return this.selectedCustomer.prohibitedactions;
    },
    balanceExceedsCreditlimit() {
      return ((this.selectedCustomer.attributes.CreditLimitLCY > 0) && (this.totalBalance > this.creditlimit));
    },
    isCreditlimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    ...mapGetters('prebook', {
      prebookProducts: 'prebookProducts',
      generated: 'prebookGenerated',
      loading: 'prebookLoading',
      error: 'prebookError',
    }),
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
      isPreviewMode: (state) => state.global.isPreviewMode,
      selectedCustomer: (state) => state.customer.selectedCustomer,
      prebookMeta: (state) => state.prebook.metadata,
    }),
    isValid() {
      return !this.generated.hasErrors;
    },
    totalBalance() {
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        return ((this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) * exchangerate)
          + this.generated.toPay;
      }
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY
          + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay;
    },
  },
  async  mounted() {
    if (!this.prebookMeta || this.prebookMeta.preorderCustomerId !== this.selectedCustomer.id) {
      this.$router.replace({ name: 'prebook', params: { brand: this.$route.params.brand } });
    } else {
      this.$store.dispatch('prebook/generate');
      this.currency = this.selectedCustomer.attributes.currency;
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY * exchangerate;
      } else {
        this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
      }
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date);
    },
    datePredicted(item) {
      if (!item.item.$$availability) {
        return null;
      } if (
        !item.item.$$availability.find(
          (o) => o.quantity === item.orderline.quantity && o.date,
        )
      ) {
        return null;
      }
      const { date } = item.item.$$availability.find(
        (o) => o.quantity === item.quantity,
      );
      return new Date(date);
    },
    weeksToPredicted(item) {
      return availabilityText(item.item.$$availability, item.orderline.quantity, this.showRetailPrices);
    },
    confirmPrebook() {
      this.$router.push({ name: 'prebook_confirmation', params: { brand: this.$route.params.brand } });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_colors.scss";
.cartLink {
  color: inherit;
  text-decoration: none;
}
</style>
