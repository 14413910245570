import { render, staticRenderFns } from "./freshChat.vue?vue&type=template&id=013c1b96&scoped=true"
import script from "./freshChat.vue?vue&type=script&lang=js"
export * from "./freshChat.vue?vue&type=script&lang=js"
import style0 from "./freshChat.vue?vue&type=style&index=0&id=013c1b96&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013c1b96",
  null
  
)

export default component.exports