var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'dialog-overlay': true,
    'dialog-overlay--closed': !_vm.isOpen,
    'dialog-overlay--open': _vm.isOpen,
    [`dialog-overlay--scroll-${_vm.scrollMode}`]: true
  }},[_c('div',{staticClass:"dialog-overlay__spacer"}),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],class:{
      'dialog-overlay__content': true,
    }},[_vm._t("default",null,null,_vm.overlayControl)],2),_c('div',{staticClass:"dialog-overlay__spacer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }