<template>
  <div class="crm-relation">
    <span class="crm-relation__name">
      {{ value.firstName }} {{ value.lastName }}
    </span>
    <span
      v-if="value.phone"
      class="crm-relation__phone"
    >
      <i class="uil uil-phone" />
      {{ value.phone }}
    </span>
    <span class="crm-relation__email">
      {{ value.email }}
    </span>
    <span
      v-if="canEdit"
      class="crm-relation__edit"
    >
      <a
        href="#"
        @click.prevent.stop="editRelation"
      >
        {{ $t('general.edit') }}
      </a>
    </span>
  </div>
</template>

<script>
import dialogs from '@/utils/dialogs';
import { isEqual } from '@/utils/object';
import CustomerInfoDialog from './relationInfoDialog.vue';

export default {
  name: 'Relation',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: null,
      errorMessage: null,
    };
  },
  computed: {
  },
  watch: {
    value() {
      this.valueToModel();
    },
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    valueToModel() {
      this.model = this.value; // Not taking copies, because this component does not directly edit
    },
    async editRelation() {
      // try {
      const { model } = this;

      const result = await dialogs.show({
        component: CustomerInfoDialog,
        props: {
          value: model,
        },
      });
      if (result) {
        this.setModel(result);
      }
    },
    setModel(model) {
      if (!isEqual(model, this.model)) {
        this.model = model;
        this.$emit('input', model);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
