/* eslint-disable no-console */
import { register } from 'register-service-worker';

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
      'App is being served from cache by a service worker.\n'
      + 'For more details, visit https://goo.gl/AFskqB',
    );
  },
  async registered(reg) {
    console.log('Service worker has been registered.');
    console.log(reg);
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated() {
    console.log('New content is available; Refresh...');
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
