export default {
  namespaced: true,
  state: {
    isPreviewMode: false,
    previousRoute: null,
  },

  actions: {
    async setPreviewMode({ commit }, val) {
      commit('setPreviewMode', val);
    },
  },

  mutations: {
    setPreviewMode(state, val) {
      state.isPreviewMode = val;
    },
    previousRoute(state, val) {
      state.previousRoute = val;
    },
  },
};
