import PinchZoom from 'vue-pinch-zoom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Hammer from 'hammerjs';
import './style.css';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },

    disableScroll: {
      type: Boolean,
      // Used to default to true which adds no-scroll to the html-element.
      // Seemed to only add more scrolling bugs, vue-lb-open on body does the actual work
      default: false,
    },

    showLightBox: {
      type: Boolean,
      default: true,
    },

    startAt: {
      type: Number,
      default: 0,
    },

    nThumbs: {
      type: Number,
      default: 7,
    },

    showThumbs: {
      type: Boolean,
      default: true,
    },

    // Mode
    autoPlay: {
      type: Boolean,
      default: false,
    },

    autoPlayTime: {
      type: Number,
      default: 3000,
    },

    siteLoading: {
      default: null,
    },

    showCaption: {
      type: Boolean,
      default: false,
    },

    lengthToLoadMore: {
      type: Number,
      default: 0,
    },

    closeText: {
      type: String,
      default: 'Close (Esc)',
    },

    previousText: {
      type: String,
      default: 'Previous',
    },

    nextText: {
      type: String,
      default: 'Next',
    },

    previousThumbText: {
      type: String,
      default: 'Previous',
    },

    nextThumbText: {
      type: String,
      default: 'Next',
    },

    backgroundColor: {
      type: String,
      default: 'transparant',
    },
  },

  data() {
    return {
      select: this.startAt,
      lightBoxOn: this.showLightBox,
      timer: null,
    };
  },
  components: {
    PinchZoom,
  },
  computed: {
    thumbIndex() {
      const halfDown = Math.floor(this.nThumbs / 2);

      if (this.select >= halfDown && this.select < this.images.length - halfDown) {
        return {
          // eslint-disable-next-line no-mixed-operators
          begin: this.select - halfDown + (1 - this.nThumbs % 2),
          end: this.select + halfDown,
        };
      }

      if (this.select < halfDown) {
        return {
          begin: 0,
          end: this.nThumbs - 1,
        };
      }

      return {
        begin: this.images.length - this.nThumbs,
        end: this.images.length - 1,
      };
    },

    imagesThumb() {
      if (this.siteLoading) {
        return this.images.map(({ thumb }) => ({
          src: thumb,
          loading: this.siteLoading,
          error: this.siteLoading,
        }));
      }

      return this.images.map(({ thumb }) => thumb);
    },
  },

  watch: {
    lightBoxOn(value) {
      if (document != null) {
        this.onToggleLightBox(value);
      }
    },

    select() {
      this.$emit('onImageChanged', this.select);
      if (this.$refs.pinch.ivyPinch.scale > 1) {
        this.$refs.pinch.toggleZoom();
      }
      if (this.select >= this.images.length - this.lengthToLoadMore - 1) { this.$emit('onLoad'); }

      if (this.select === this.images.length - 1) { this.$emit('onLastIndex'); }

      if (this.select === 0) { this.$emit('onFirstIndex'); }

      if (this.select === this.startAt) { this.$emit('onStartIndex'); }
    },
  },

  mounted() {
    if (this.autoPlay) {
      this.timer = setInterval(() => {
        this.nextImage();
      }, this.autoPlayTime);
    }

    this.onToggleLightBox(this.lightBoxOn);

    if (this.$refs.container) {
      const hammer = new Hammer(this.$refs.container);

      hammer.on('swiperight', () => {
        this.previousImage();
      });

      hammer.on('swipeleft', () => {
        this.nextImage();
      });
    }
  },

  methods: {
    onToggleLightBox(value) {
      if (this.disableScroll) {
        if (value) {
          document.querySelector('html').classList.add('no-scroll');
        } else {
          document.querySelector('html').classList.remove('no-scroll');
        }
      }

      if (value) {
        document.querySelector('body').classList.add('vue-lb-open');
      } else {
        document.querySelector('body').classList.remove('vue-lb-open');
      }

      this.$emit('onOpened', value);

      if (value) {
        document.addEventListener('keydown', this.addKeyEvent);
      } else {
        document.removeEventListener('keydown', this.addKeyEvent);
      }
    },

    showImage(index) {
      this.$set(this, 'lightBoxOn', true);
      this.$set(this, 'select', index);
    },

    addKeyEvent(event) {
      if (event.keyCode === 37) this.previousImage(); // left arrow
      if (event.keyCode === 39) this.nextImage(); // right arrow
      if (event.keyCode === 27) this.closeLightBox(); // esc
    },

    closeLightBox() {
      this.$set(this, 'lightBoxOn', false);
    },

    nextImage() {
      this.$set(this, 'select', (this.select + 1) % this.images.length);
    },

    previousImage() {
      this.$set(this, 'select', (this.select + this.images.length - 1) % this.images.length);
    },
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.addKeyEvent);

    if (this.autoPlay) {
      clearInterval(this.timer);
    }
  },
};
