<template>
  <div class="sessionpage__content__inner">
    <img
      src="@/assets/img/bcf-logo.svg"
      alt="Belgium Cycling Factory"
      class="sessionpage__logo"
    >

    <div v-if="isLoggedIn && selectedCustomerId && allowWelcomeBack">
      <h1 v-html="$t('login.welcomeBack')" />
      <p v-html="$t('login.welcomeBackInfo', { username: user.username })" />
      <br>
      <router-link
        :to="{name: 'dashboard'}"
        class="button button--danger m-r-2"
      >
        {{ $t("login.toDashboard") }}
      </router-link>
      <a
        href="#"
        @click="logout"
      >{{ $t("login.logout") }}</a>
    </div>
    <div v-if="isLoggedIn">
      <div v-show="!selectedCustomerId">
        <h1 v-html="$t('login.selectCustomer')" />
        <p v-html="$t('login.selectCustomerInfo')" />
        <br>
        <select-customer @changed="customerChanged" />
        <div
          class="links"
          style="margin-top:20px; text-align: center"
        >
          <a
            href="#"
            @click="logout"
          >{{ $t("login.logout") }}</a>
        </div>
      </div>
    </div>

    <form
      v-if="!isLoggedIn"
      @submit.prevent="loginSubmit"
    >
      <loading-overlay :loading="inProgress">
        <h1>{{ $t("login.title") }}</h1>

        <div
          v-if="errorMessage"
          class="m-b-4"
        >
          <message type="error">
            {{ $t(`api.${errorMessage}`) }}
          </message>
        </div>

        <div class="form-group">
          <label>{{ $t("login.username") }}</label>
          <input
            v-model="username"
            type="text"
            placeholder="Username"
          >
        </div>

        <div class="form-group">
          <label>{{ $t("login.password") }}</label>
          <input
            v-model="password"
            type="password"
            placeholder="Password"
          >
        </div>

        <div class="form-group">
          <label>{{ $t('account.settings.edit.languageChooser') }}</label>
          <language-changer />
        </div>

        <button
          type="submit"
          class="button button--primary w-100"
        >
          {{ $t("login.title") }}
        </button>
        <div
          v-if="isPreviewMode"
          class="links"
          style="margin-top:20px; text-align: center"
        >
          <router-link :to="{ name: 'resetPassword' }">
            {{
              $t("login.forgotPassword")
            }}
          </router-link>
        </div>
      </loading-overlay>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import languageChanger from '@/components/languageChanger.vue';
import message from '@/components/Message.vue';
import selectCustomer from '@/components/selectCustomer.vue';
import loadingOverlay from '@/elements/LoadingOverlay.vue';

export default {
  name: 'Login',
  metaInfo() {
    return {
      title: this.$t('login.title'),
    };
  },
  components: {
    languageChanger,
    message,
    selectCustomer,
    loadingOverlay,
  },
  props: {
    nextRoute: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      username: '',
      password: '',
      allowWelcomeBack: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      errorMessage: (state) => state.auth.error,
      inProgress: (state) => state.auth.inProgress,
      isPreviewMode: (state) => state.global.isPreviewMode,
    }),
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      selectedCustomerId: 'customer/selectedCustomerId',
    }),
  },
  async mounted() {
    if (this.isLoggedIn && this.$route.query.cus) {
      await this.$store.dispatch('customer/setSelectedExternalCustomerId', { selectedExternalCustomerId: this.$route.query.cus });
    }
  },
  methods: {
    customerChanged() {
      if (this.isLoggedIn && this.selectedCustomerId) {
        if (!this.nextRoute) {
          this.$router.push('/');
        } else {
          this.$router.push(
            { path: this.nextRoute, query: this.query },
          ); // Push previously requested route
        }
      }
    },
    async loginSubmit() {
      this.allowWelcomeBack = false;
      await this.$store.dispatch('auth/login', {
        username: this.username,
        password: this.password,
      });
      const requestedCustomer = this.$route.query?.cus || this.$route.params?.query?.cus;
      if (requestedCustomer) {
        await this.$store.dispatch('customer/setSelectedExternalCustomerId', { selectedExternalCustomerId: requestedCustomer });
      }
      this.customerChanged();
    },
    logout() {
      this.$store.dispatch('auth/logout');
      // this.$router.push({ name: 'login' });
    },
  },
};
</script>
