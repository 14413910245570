<template>
  <div class="cart-view cart-confirm">
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <span class="main__title__action--left">
                <router-link :to="{name: 'cart'}">
                  <i class="uil uil-arrow-left" />
                </router-link>
              </span>
              <h1>{{ $t('cart.deliveryAndBilling') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div
      class="container"
    >
      <div class="row">
        <div class="col">
          <div class="steps-progress">
            <div class="steps-progress__item steps-progress__item--active">
              1
            </div>
            <div class="steps-progress__item steps-progress__item--active">
              2
            </div>
            <div class="steps-progress__item">
              <i class="uil uil-check" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader v-if="loading" />

    <div
      v-else
      class="container m-t-4"
    >
      <div class="row">
        <div class="col-md-9">
          <SectionTitle>{{ $t('cart.deliveryAddress') }}</SectionTitle>

          <ul class="m-b-4">
            <li
              v-for="location in locations"
              :key="location.externalid"
              class="list__item list__item__checkbox"
            >
              <radio-button
                v-model="cartLocation"
                name="locations"
                :checked-value="location.externalid"
              >
                <span>
                  <strong>{{ location.Name }}</strong>
                  <small>
                    {{ `${location.street}, ${location.postalcode} ${location.city} (${$t(`countries.${location.country}`)})` }}
                  </small>
                </span>
              </radio-button>
            </li>
          </ul>

          <SectionTitle>{{ $t("cart.reference") }}</SectionTitle>
          <div class="form-group">
            <input
              v-model="reference"
              type="text"
              class="form-control"
              :maxlength="max"
            >
            <small
              v-if="reference && reference.length === max"
              class="form-text--warning"
            >
              {{ $t("cart.maxchars") }}
            </small>
          </div>
          <SectionTitle>{{ $t("cart.orderremarks") }}</SectionTitle>
          <div class="form-group">
            <p>{{ $t('cart.remarksexplanation') }}</p>
            <textarea
              v-model="remarks"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 m-b-4">
          <SectionTitle>{{ $t('cart.prices') }}</SectionTitle>
          <cart-prices-table :generated="generated" />
          <SectionTitle style="margin-bottom: 0px;">
            {{ $t('cart.options') }}
          </SectionTitle>
          <div class="m-b-4">
            <listItem toggle-before>
              <toggle v-model="oneBikeOneOrder">
                {{ $t('cart.OneBikeOneOrder') }}
              </toggle>
            </listItem>
          </div>
          <button
            class="button button--danger w-100"
            :class="{'button--loading': pending}"
            :disabled="pending"
            @click="placeOrder"
          >
            {{ $t('cart.order') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { mapGetters, mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';
import radioButton from '@/elements/radioButton.vue';
import cartPricesTable from '@/components/cartPricesTable.vue';
import loader from '@/elements/loader.vue';
import toggle from '@/elements/toggle.vue';
import ListItem from '@/elements/ListItem.vue';

export default {
  components: {
    cartPricesTable,
    radioButton,
    SectionTitle,
    loader,
    toggle,
    ListItem,
  },
  metaInfo() {
    return {
      title: `${this.$t('cart.deliveryAndBilling')} - ${this.$t('header.cart')}`,
    };
  },
  data() {
    return {
      pending: false,
      max: 50,
    };
  },
  computed: {
    ...mapState({
      locations: (state) => state.customer.selectedCustomer.addresses,
    }),
    oneBikeOneOrder: {
      get() {
        return this.$store.getters['cart/oneBikeOneOrder'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('cart/setOneBikeOneOrder', newValue);
      },
    },
    cartLocation: {
      get() {
        return this.$store.getters['cart/cartLocation'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('cart/setLocation', newValue);
      },
    },
    reference: {
      get() {
        return this.$store.getters['cart/cartReference'];
      },
      set(newValue) {
        this.$store.dispatch('cart/setReference', newValue);
      },
    },
    remarks: {
      get() {
        return this.$store.getters['cart/cartRemark'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('cart/setRemark', newValue);
      },
    },

    ...mapGetters('cart', {
      cartPrice: 'cartPrice',
      loading: 'cartLoading',
      generated: 'cartGenerated',
    }),
  },
  created() {
    if (this.$store.getters['cart/cartLocation'] === null) {
      this.$store.dispatch(
        'cart/setLocation',
        this.$store.state.customer.selectedCustomer.addresses.find((s) => s.default === 1).externalid,
      );
    }
  },
  methods: {
    placeOrder: async function placeOrder() {
      this.pending = true;
      await this.$store.dispatch('cart/placeOrder');
      this.$router.push({ name: 'cartSuccess' });
      this.pending = false;
    },
  },
};
</script>
