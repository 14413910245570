import { getAttributeTranslations as getAttributeTranslationsApi } from '@/api/api';
import { NoResponseApiError } from '../../api/apiErrors';

const apiLangMap = {
  nl: 'nl_BE',
  es: 'es_ES',
  fr: 'fr_FR',
  de: 'de_DE',
  it: 'it_IT',
  en: 'default',
};

const dhlLangMap = {
  nl: 'https://www.dhlparcel.nl/nl/consument/volg-je-pakket?tc=',
  es: 'https://www.dhlparcel.nl/en/follow-your-shipment?tc=',
  fr: 'https://www.dhlparcel.nl/en/follow-your-shipment?tc=',
  de: 'https://www.dhlparcel.nl/en/follow-your-shipment?tc=',
  it: 'https://www.dhlparcel.nl/en/follow-your-shipment?tc=',
  en: 'https://www.dhlparcel.nl/en/follow-your-shipment?tc=',
};

const jclLangMap = {
  nl: 'https://tt3.jcl-logistics.com/tracktrace/#/en/shipment/',
  es: 'https://tt3.jcl-logistics.com/tracktrace/#/en/shipment/',
  fr: 'https://tt3.jcl-logistics.com/tracktrace/#/en/shipment/',
  de: 'https://tt3.jcl-logistics.com/tracktrace/#/de/shipment/',
  it: 'https://tt3.jcl-logistics.com/tracktrace/#/en/shipment/',
  en: 'https://tt3.jcl-logistics.com/tracktrace/#/en/shipment/',
};

export default {
  namespaced: true,
  state: {
    current: 'en',
    currentApi: apiLangMap.en,
    currentDhl: dhlLangMap.en,
    currentJcl: jclLangMap.en,
    attributeTranslations: [],
  },

  // actions
  actions: {
    async setLanguage({ commit, dispatch, rootGetters }, { language }) {
      commit('setLanguage', { language });
      if (rootGetters['auth/isLoggedIn']) {
        dispatch('getAttributeTranslations', language);
      }
    },
    async getAttributeTranslations({ commit, state }, language) {
      try {
        const lang = apiLangMap[language]
          ? apiLangMap[language]
          : apiLangMap.en;
        const apiLang = language
          ? lang
          : state.currentApi;

        const translations = await getAttributeTranslationsApi(apiLang);
        commit('setAttributeTranslations', {
          translations: translations.reduce((transObj, trans) => {
            transObj[trans.attribute.toLowerCase()] = trans.translation;
            return transObj;
          }, {}),
        });
      } catch (error) {
        if (error instanceof NoResponseApiError) {
          // don't show error already handled ....
        }
      }
    },
  },

  // mutations
  mutations: {
    setLanguage(state, { language }) {
      state.current = language;
      state.currentApi = apiLangMap[language]
        ? apiLangMap[language]
        : apiLangMap.en;
      state.currentDhl = dhlLangMap[language]
        ? dhlLangMap[language]
        : dhlLangMap.en;
      state.currentJcl = jclLangMap[language]
        ? jclLangMap[language]
        : jclLangMap.en;
    },
    setAttributeTranslations(state, { translations }) {
      state.attributeTranslations = translations;
    },
  },
};
