var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'dialog': true,
    'dialog--wide': _vm.wide
  }},[_c('div',{class:{
      'dialog__header': true
    }},[_vm._t("header",function(){return [_c('div',{class:{
          'dialog__headercontent': true
        }},[_vm._t("title",function(){return [_c('strong',[_vm._v(_vm._s(_vm.title))])]})],2),_c('div',{class:{
          'dialog__headercontrols': true
        }},[_vm._t("header-controls",function(){return [_c('div',{staticClass:"dialog__close",class:{
              'dialog__close': true
            },on:{"click":function($event){return _vm.overlay.close(undefined, 'close')}}},[_vm._v(" × ")])]})],2)]})],2),_c('div',{class:{'dialog__content': true}},[_vm._t("default",function(){return [(_vm.htmlContent)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.htmlContent)}}):_c('span',[_vm._v(" "+_vm._s(_vm.content)+" ")])]})],2),(_vm.hasFooterSlot)?_c('div',{class:{'dialog__footer': true}},[_vm._t("footer",function(){return [_c('div',{class:{'dialog__footer-controls': true}},_vm._l((_vm.footerButtons),function(item,index){return _c('button',{key:index,class:`button ${item.class}`,on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.overlay.close(item.value, 'button')}}},[_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])}),0)]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }