<template>
  <div class="sessionpage__content__inner">
    <img
      src="@/assets/img/bcf-logo.svg"
      alt="Belgium Cycling Factory"
      class="sessionpage__logo"
    >

    <div v-if="!token">
      <h1>{{ $t('login.requestPassword') }}</h1>
      <message
        v-if="passwordError"
        type="error"
      >
        {{ $t(passwordError) }}
      </message>
      <message
        v-if="passwordSuccess"
        type="success"
      >
        {{ $t('login.requestPasswordSuccess') }}
      </message>
      <div
        v-if="pending"
        class="container-loading"
      >
        <loader />
      </div>

      <form
        v-if="!pending & !passwordSuccess"
        @submit.prevent="request"
      >
        <div class="form-group">
          <label>{{ $t('login.email') }}</label>
          <input
            v-model="email"
            type="text"
            placeholder="Email"
          >
        </div>

        <button
          type="submit"
          class="button button--primary w-100"
        >
          {{ $t('login.requestPassword') }}
        </button>
      </form>
    </div>
    <div v-else>
      <h1>{{ $t('account.settings.changePassword.title') }}</h1>
      <message
        v-if="passwordError"
        type="error"
        v-html="$t(passwordError)"
      />
      <message
        v-if="passwordSuccess"
        type="success"
        v-html="$t('login.changePasswordSuccessfull')"
      />
      <div
        v-if="pending"
        class="container-loading"
      >
        <loader />
      </div>

      <form
        v-if="!pending & !passwordSuccess"
        @submit.prevent="change"
      >
        <div class="form-group">
          <label>{{ $t('account.settings.changePassword.newPassword') }}</label>
          <input
            v-model="newPassword"
            type="password"
            autocomplete="new-password"
            required
            minlength="5"
            pattern=".*\d.*"
            :title="$t('account.settings.changePassword.validation')"
          >
        </div>
        <div class="form-group">
          <label>{{ $t('account.settings.changePassword.repeatNewPassword') }}</label>
          <input
            v-model="newPassword2"
            type="password"
            required
            minlength="5"
            :pattern="newPassword"
            :title="$t('account.settings.changePassword.newPasswordNotTheSame')"
          >
        </div>

        <button
          type="submit"
          class="button button--primary w-100"
        >
          {{ $t('account.settings.changePassword.changePassword') }}
        </button>
      </form>
    </div>
    <div
      class="links"
      style="margin-top:20px; text-align: center"
    >
      <router-link :to="{name:'login'}">
        {{ $t('login.title') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { requestPassword, changePasswordWithToken } from '@/api/user';
import loader from '@/elements/loader.vue';
import message from '@/components/Message.vue';

export default {
  name: 'ResetPassword',
  metaInfo() {
    return {
      title: this.$t('login.requestPassword'),
    };
  },
  components: {
    loader,
    message,
  },
  data() {
    return {
      email: '',
      newPassword: '',
      newPassword2: '',
      passwordError: null,
      passwordSuccess: false,
      pending: false,
    };
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
  },
  methods: {
    async request() {
      this.passwordSuccess = null;
      this.passwordError = null;
      this.pending = true;
      try {
        await requestPassword(this.email, this.$store.state.language.current);
        this.passwordSuccess = true;
      } catch (err) {
        this.passwordError = err.apiMessage;
      } finally {
        this.pending = false;
      }
    },
    async change() {
      this.passwordSuccess = null;
      this.passwordError = null;
      this.pending = true;
      try {
        if (this.newPassword === this.newPassword2) {
          if (this.newPassword.length > 4 && /\d/.test(this.newPassword)) {
            await changePasswordWithToken(this.token, this.newPassword);
            this.passwordSuccess = true;
          } else {
            this.passwordError = 'account.settings.changePassword.validation';
          }
        } else {
          this.passwordError = 'account.settings.changePassword.newPasswordNotTheSame';
        }
      } catch (err) {
        this.passwordError = err.apiMessage;
      } finally {
        this.pending = false;
      }
    },
  },
};
</script>
