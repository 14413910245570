export const urlBase64ToUint8Array = (base64String) => {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line no-useless-escape
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export const ab2str = hashBuffer => btoa(String.fromCharCode.apply(null, new Uint8Array(hashBuffer)));

export const pushSupported = async () => {
  if (('PushManager' in window) && ('serviceWorker' in navigator)) {
    try {
      const reg = await navigator.serviceWorker.ready;
      if (reg) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const subscribe = async () => {
  if (await pushSupported()) {
    // Push Notifications
    if (Notification.permission === 'granted') {
      try {
        const reg = await navigator.serviceWorker.ready;
        if (reg) {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              'BAiv1IOvLP0MuxGx9u1c-kHiHEs6moqBeo8G84TcOBRkfLjOQW5V8_3UQ6vTlmWrorReK6t8EC14jxoC3VYa1Rw',
            ),
          };
          try {
            const subscription = await reg.pushManager.subscribe(subscribeOptions);
            window.store.dispatch('notifications/subscribe', {
              useragent: navigator.appVersion,
              endpoint: subscription.endpoint,
              p256dh: ab2str(subscription.getKey('p256dh')),
              auth: ab2str(subscription.getKey('auth')),
            });
            return subscription;
          } catch (e) {
            console.error(e);
          }
        }
      } catch (e) {
        console.error('"', e);
      }
    }
  }
  return null;
};

export default subscribe;
