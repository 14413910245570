<template>
  <div>
    <div
      class="m-t-4 m-md-t-0"
    >
      <header class="main__title">
        <div class="container">
          <div
            v-if="!pending"
            class="row"
          >
            <div class="col main__title__inner">
              <span
                v-if="parentPaths && parentPaths.length"
                class="main__title__action--left"
                @click.prevent="changeCurrentPath(parentPaths[parentPaths.length - 1].path)"
              >
                <i class="uil uil-arrow-left" />
              </span>
              <router-link
                v-if="!parentPaths && itemId"
                class="main__title__action--left"
                :to="{name: 'sharepointRootItems', params: {driveId: driveId, itemId: null}}"
              >
                <i class="uil uil-arrow-left" />
              </router-link>
              <router-link
                v-if="!parentPaths && !itemId"
                class="main__title__action--left"
                :to="{name: 'sharepointDrives'}"
              >
                <i class="uil uil-arrow-left" />
              </router-link>

              <h1>
                {{ result.title }}
              </h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div
      v-if="error"
      class="container"
    >
      <message type="error">
        {{ $t(error) }}
      </message>
    </div>
    <div v-else>
      <div>
        <div
          v-if="!pending"
          class="container breadcrumbs"
        >
          <small>
            <router-link :to="{name: 'sharepointDrives'}">
              {{ $t('account.about.promo.title') }}
            </router-link>
            <i class="uil uil-angle-right" />
            <router-link :to="{name: 'sharepointDrives'}">
              {{ result.parent.site }}
            </router-link>
            <div
              v-if="itemId"
              style="display: inline"
            >
              <i class="uil uil-angle-right" />
              <router-link :to="{name: 'sharepointRootItems', params: {driveId: driveId, itemId: null}}">
                {{ decodeURI(result.parent.driveName) }}
              </router-link>
            </div>
            <div
              v-for="path in parentPaths"
              :key="path.id"
              style="display: inline"
            >
              <i class="uil uil-angle-right" />
              <a
                href="#"
                @click.prevent="changeCurrentPath(path.path)"
              >
                {{ path.name }}
              </a>
            </div>
            <i class="uil uil-angle-right" />
            <strong>
              {{ result.title }}
            </strong>

          </small>
        </div>
      </div>
      <div class="container m-t-2">
        <loader v-if="pending" />
        <div
          v-else
          class="row"
        >
          <emptystate
            v-if="result.children.length === 0"
            icon="file-search-alt"
            class="col"
          >
            {{ $t('account.about.promo.empty') }}
          </emptystate>
          <div
            v-else
            class="col"
          >
            <list-item
              v-for="item in folders"
              :key="item.id"
              icon-before
              arrow
            >
              <a
                class="pointer"
                @click.prevent="changeCurrentPath((itemId ? '/' + itemId : '') + '/' + item.name)"
              >
                <i class="uil uil-folder" />
                <div>
                  {{ item.name }}
                </div>
              </a>
              <small class="folder-count">{{ ' (' + item.$$folder.count + ')' }}</small>
            </list-item>

            <div
              class="gallery"
              :class="'gallery--'+ items.length > 1 ? items.length : 2"
            >
              <a
                v-for="item in items"
                :key="item.id"
                :title="item.name"
                :href="item.$$file.download"
                style="background-position: center; background-size: cover;"
                :style="[ item.$$file.thumb ? { backgroundImage : 'url('+item.$$file.thumb+')'} : { backgroundImage : 'url('+placeholder+')'}]"
                download
              ><div>{{ item.name }}<br>
                <small>{{ item.$$file.mimeType }}</small></div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
small.folder-count {
  margin-left: 5px;
}
</style>
<script>
import ListItem from '@/elements/ListItem.vue';
import { getDriveItemItems, getDriveRootItems } from '@/api/sharepoint';
import loader from '@/elements/loader.vue';
import emptystate from '@/elements/EmptyState.vue';
import message from '@/components/Message.vue';

import placeholder from '@/assets/img/product_placeholder.jpg';

export default {
  name: 'SharepointItems',
  components: {
    ListItem,
    loader,
    emptystate,
    // eslint-disable-next-line vue/no-unused-components
    placeholder,
    message,
  },
  props: {
    driveId: {
      type: String,
      default: null,
    },
    itemId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      result: null,
      pending: false,
      parentPath: '',
      currentPath: this.itemId,
      folders: [],
      items: [],
      placeholder,
      error: null,
    };
  },
  metaInfo() {
    return {
      title: this.$t('account.about.promo.title'),
    };
  },
  watch: {
    $route() {
      this.itemId = this.$route.params.itemId;
      this.loadItems();
    },
  },
  async mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems() {
      this.pending = true;
      this.parentPaths = null;
      try {
        if (this.itemId) {
          this.result = await getDriveItemItems(this.driveId, this.itemId);
        } else {
          this.result = await getDriveRootItems(this.driveId);
        }
      } catch (e) {
        this.error = e.apiMessage;
      }
      if (this.result.parent.path) {
        this.parentPaths = this.result.parent.path.split('/');
        this.parentPaths = this.parentPaths.map((o, i) => {
          const name = decodeURI(o);
          let path = '';
          this.parentPaths.forEach((element, ia) => {
            if (ia <= i) {
              path += `/${element}`;
            }
          });
          return {
            name,
            path,
          };
        });
      }
      this.folders = this.result.children.filter(o => o.type === 'FOLDER');
      this.items = this.result.children.filter(o => o.type === 'ITEM');

      this.pending = false;
    },
    changeCurrentPath(currentPath) {
      this.$router.push({ name: 'sharepointItems', params: { driveId: this.driveId, itemId: currentPath } });
    },
  },
};

</script>

<style lang="scss" scoped>
.container.breadcrumbs {
  background: lightgray;
  padding-top: 1em;
  padding-bottom: 1em;
  width: 100%;
  a {
    cursor: pointer;
    text-decoration: none;
    color: black;
  }
}

.gallery a {
  text-decoration: none;
  background: white;
  div {
    background-color: #00000082;
    color: white;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 1em;
    font-size: 0.8em;
    small {
      font-size: 0.7em;
    }
  }
}
</style>
