import { apiInstance } from './api';

export const getSiteDrives = async (site) => {
  const drives = await apiInstance.get(`/v1/sharepoint/site/${site}`);
  return drives.data;
};

export const getDriveRootItems = async (drive) => {
  const items = await apiInstance.get(`/v1/sharepoint/drive/${drive}`);
  return items.data;
};

export const getDriveItemItems = async (drive, item) => {
  const items = await apiInstance.get(`/v1/sharepoint/drive/${drive}/${encodeURIComponent(item)}`);
  return items.data;
};
