import { apiInstance } from './api';

export const config = async (ItemId, lang) => {
  const result = await apiInstance.get(`/v1/customizer/models/${ItemId}?language=${lang}`);
  return result.data;
};

export const getModels = async (lang, cus) => {
  const result = await apiInstance.get(`/v1/customizer/models?language=${lang}&cid=${cus}`);
  return result.data.result;
};

export const saveConfiguration = async (model, configuration) => {
  try {
    const result = await apiInstance.post('/v1/customizer/favorite/save', { model, configuration });
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const LoadConfigurationByCode = async (code) => {
  try {
    const result = await apiInstance.get(`/v1/customizer/model/${code}`);
    return result.data[0];
  } catch (err) {
    if (err.status === 404) {
      throw Object.assign(new Error(`Configuration-code ${code} not found`), { status: 404 });
    } else {
      throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
    }
  }
};
