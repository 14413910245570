<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <span class="main__title__action--left">
                <router-link :to="{name: 'prebook_checkout', params: { brand: this.$route.params.brand } }">
                  <i class="uil uil-arrow-left" />
                </router-link>
              </span>
              <h1>{{ $t('cart.deliveryAndBilling') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div
      class="container"
    >
      <div class="row">
        <div class="col">
          <div class="steps-progress">
            <div class="steps-progress__item steps-progress__item--active">
              1
            </div>
            <div class="steps-progress__item steps-progress__item--active">
              2
            </div>
            <div class="steps-progress__item">
              <i class="uil uil-check" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader v-if="loading" />

    <div
      v-else
      class="container m-t-4"
    >
      <div class="row">
        <div class="col-md-7">
          <sectionTitle>{{ $t('cart.deliveryAddress') }}</sectionTitle>

          <ul class="m-b-4">
            <li
              v-for="location in locations"
              :key="location.externalid"
              class="list__item list__item__checkbox"
            >
              <radio-button
                v-model="cartLocation"
                name="locations"
                :checked-value="location.externalid"
              >
                <span>
                  <strong>{{ location.Name }}</strong>
                  <small>
                    {{ `${location.street}, ${location.postalcode} ${location.city} (${$t(`countries.${location.country}`)})` }}
                  </small>
                </span>
              </radio-button>
            </li>
          </ul>
        </div>
        <div class="col-md-5 m-b-4">
          <SectionTitle>{{ $t('cart.prices') }}</SectionTitle>
          <cart-prices-table :generated="generated" />
          <SectionTitle style="margin-bottom: 0px;">
            {{ $t('cart.options') }}
          </SectionTitle>
          <div class="m-b-4">
            <listItem toggle-before>
              <toggle v-model="oneBikeOneOrder">
                {{ $t('cart.OneBikeOneOrder') }}
              </toggle>
            </listItem>
          </div>
          <button
            class="button button--danger w-100"
            :class="{'button--loading': pending}"
            :disabled="pending"
            @click="placeOrder"
          >
            {{ $t('cart.order') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { mapGetters, mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';
import radioButton from '@/elements/radioButton.vue';
import cartPricesTable from '@/components/cartPricesTable.vue';
import loader from '@/elements/loader.vue';
import toggle from '@/elements/toggle.vue';
import ListItem from '@/elements/ListItem.vue';

export default {
  components: {
    cartPricesTable,
    radioButton,
    SectionTitle,
    loader,
    toggle,
    ListItem,
  },
  metaInfo() {
    return {
      title: `${this.$t('cart.deliveryAndBilling')} - ${this.$t('prebook.checkout.title')}`,
    };
  },
  data() {
    return {
      pending: false,
      max: 50,
    };
  },
  mounted() {
    if (!this.prebookMeta || this.prebookMeta.preorderCustomerId !== this.selectedCustomer.id) {
      this.$router.replace({ name: 'prebook', params: { brand: this.$route.params.brand } });
    }
  },
  computed: {
    ...mapState({
      locations: (state) => state.customer.selectedCustomer.addresses,
      selectedCustomer: (state) => state.customer.selectedCustomer,
      prebookMeta: (state) => state.prebook.metadata,
    }),
    oneBikeOneOrder: {
      get() {
        return this.$store.getters['prebook/oneBikeOneOrder'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('prebook/setOneBikeOneOrder', newValue);
      },
    },
    cartLocation: {
      get() {
        return this.$store.getters['prebook/prebookLocation'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('prebook/setLocation', newValue);
      },
    },
    reference: {
      get() {
        return this.$store.getters['prebook/prebookReference'];
      },
      set(newValue) {
        this.$store.dispatch('prebook/setReference', newValue);
      },
    },
    remarks: {
      get() {
        return this.$store.getters['prebook/prebookRemark'];
      },
      // setter
      set(newValue) {
        this.$store.dispatch('prebook/setRemark', newValue);
      },
    },

    ...mapGetters('prebook', {
      loading: 'prebookLoading',
      generated: 'prebookGenerated',
    }),
  },
  created() {
    if (this.$store.getters['prebook/prebookLocation'] === null) {
      this.$store.dispatch(
        'prebook/setLocation',
        this.$store.state.customer.selectedCustomer.addresses.find((s) => s.default === 1).externalid,
      );
    }
  },
  methods: {
    placeOrder: async function placeOrder() {
      this.pending = true;
      await this.$store.dispatch('prebook/placeOrder');
      this.$router.push({ name: 'prebook_success', params: { brand: this.$route.params.brand } });
      this.pending = false;
    },
  },
};
</script>
