<template>
  <div
    v-if="data.qty > 0 || alwaysShow"
    class="preorder__summary"
  >
    <div class="preorder__summary__count">
      {{ data.qty }}
    </div>
    <div class="preorder__summary__price">
      <price
        :price="data.price"
        :currency="data.currency"
      />
    </div>
  </div>
</template>

<script>
import Price from '@/elements/Price.vue';

export default {
  name: 'PreorderSummary',
  components: {
    Price,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          qty: 0,
          price: 0,
          currency: 'EUR',
        };
      },
    },
    alwaysShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>

</style>
