<template>
  <span>{{ formated }}</span>
</template>

<script>
export default {
  name: 'Price',
  props: {
    price: {
      type: [String, Number],
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    showEmptyAsDash: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formated() {
      if (this.price && this.currency) {
        return Number(this.price).toLocaleString(undefined, { style: 'currency', currency: this.currency });
      }
      if (this.showEmptyAsDash || !this.currency) return '-';
      return Number(0).toLocaleString(undefined, { style: 'currency', currency: this.currency });
    },
  },
};
</script>
