<template>
  <div>
    <table class="table table--striped m-b-2">
      <tr>
        <td>{{ $t('cart.totalPrice') }}</td>
        <td
          class="nowrap text-right"
          width="1"
        >
          <price
            :currency="generated.currency"
            :price="generated.totalMSRP"
          />
        </td>
      </tr>

      <tr v-if="!showRetailPrices">
        <td>{{ $t('cart.dealerDiscount') }}</td>
        <td
          class="nowrap text-right"
          width="1"
        >
          -
          <price
            :currency="generated.currency"
            :price="generated.totalMSRP - generated.totalPrice"
          />
        </td>
      </tr>
      <tr>
        <td>{{ $t('cart.shippingCost') }}</td>
        <td
          class="nowrap text-right"
          width="1"
        >
          +
          <price
            :currency="generated.currency"
            :price="generated.totalShippingCost"
          />
        </td>
      </tr>
    </table>

    <div
      v-if="!showRetailPrices"
      class="highlight m-b-4 d-flex align-items-center space-between"
    >
      <div>{{ $t('cart.toPay') }}</div>
      <div class="font--big">
        <price
          :currency="generated.currency"
          :price="generated.toPay"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { mapState } from 'vuex';
import price from '@/elements/Price.vue';

export default {
  components: {
    price,
  },
  props: {
    generated: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
    }),
  },
};
</script>
