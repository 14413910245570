<template>
  <div>
    <!-- TODO : colorpicker
    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            Kies een kleur
          </div>
        </template>

        <ul>
          <li
            v-for="color in [{ title: 'Olive Green', hex: '#187795'}, { title: 'Candy Red', hex: '#9067C6'}, { title: 'Sea blue', hex: '#9CAFB7'}, { title: 'Tuscan', hex: '#F7DBA7'}]"
            :key="color.hex"
            v-title
          >
            <checkbox>
              <span
                class="color--preview"
                :style="{ backgroundColor: color.hex }"
              />
              <span>{{ color.title }}</span>
            </checkbox>
          </li>
        </ul>
      </collapse>
    </fieldset>-->

    <fieldset v-if="attributes.length > 0">
      <SectionTitle>{{ $t('items.activeFilters') }}</SectionTitle>
      <ul>
        <li
          v-for="(attr, index) in attributes"
          :key="index"
          class="d-flex space-between"
        >
          <span>
            {{ attributeTranslations[Object.keys(attr)[0]]
              ? attributeTranslations[Object.keys(attr)[0]]
              : Object.keys(attr)[0] }}:
            <strong>
              {{ getFilterDescriptionTranslation(
                Object.keys(attr)[0],
                attr[Object.keys(attr)[0]]
              ) }}
            </strong>
          </span>
          <span>
            <i
              class="uil uil-times color--red"
              @click="clearFilter(index)"
            />
          </span>
        </li>
        <li class="m-t-2">
          <a
            href="#"
            @click="clearFilters"
          >{{ $t('items.clearActiveFilters') }}</a>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <collapse :open="true">
        <template v-slot:header="{open}">
          <div
            class="section-title section-title--collapse"
            :class="{'open': open}"
          >
            {{ $t('items.options') }}
          </div>
        </template>

        <div class="form-group">
          <select v-model="sortType">
            <option value="NONE">
              {{ $t('items.sort.recommended') }}
            </option>
            <option value="AZ">
              {{ $t('items.sort.az') }}
            </option>
            <option value="ZA">
              {{ $t('items.sort.za') }}
            </option>
            <option value="PRICE_LOW_HIGH">
              {{ $t('items.sort.priceLowHigh') }}
            </option>
            <option value="PRICE_HIGH_LOW">
              {{ $t('items.sort.priceHighLow') }}
            </option>
            <option value="AVAILABILITY">
              {{ $t('items.sort.availability') }}
            </option>
          </select>
        </div>

        <div class="form-group form-group--checkbox">
          <toggle v-model="showRetailPrices">
            {{ $t('account.account.showRetailPrices.title') }}
          </toggle>
        </div>

        <div class="form-group form-group--checkbox">
          <toggle v-model="showOnlyActionsAndPromos">
            {{ $t('items.optionsOnlyActions') }}
          </toggle>
        </div>
      </collapse>
    </fieldset>

    <filter-attribute
      v-for="(attribute) in filterAttributes"
      :key="attribute.code"
      v-model="attributes"
      :attribute="attribute"
      :collapsed-by-default="true"
    />

    <loader
      v-if="requestPending"
      class="m-t-2"
      size="small"
      message="FilterAttributes are Loading..."
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import loader from '@/elements/loader.vue';
import toggle from '@/elements/toggle.vue';
// import checkbox from '@/elements/checkbox.vue';
import SectionTitle from '@/elements/SectionTitle.vue';

import collapse from '@/components/collapse.vue';
import filterAttribute from '@/components/FilterAttribute.vue';

export default {
  name: 'FilterAttributes',
  components: {
    loader,
    collapse,
    filterAttribute,
    toggle,
    // checkbox,
    SectionTitle,
  },
  props: {
    defaultFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collapse: {},
    };
  },
  computed: {
    ...mapGetters('auth', {
      isLoggedIn: 'isLoggedIn',
    }),
    ...mapState({
      selectedCustomerId: (state) => state.customer.selectedCustomerId,
      attributeTranslations: (state) => state.language.attributeTranslations,
      filterAttributes: (state) => state.items.filterOptions,
      requestPending: (state) => state.items.filterOptionsPending,
      requestError: (state) => state.items.filterOptionsError,
      selectedAttributes: (state) => state.items.filters,
      search: (state) => state.items.search,

    }),
    attributes: {
      get() {
        return this.selectedAttributes.filter((o) => !o.category && !o.promo);
      },
      set(newVal) {
        this.$store.dispatch('items/setCurrentFilters', {
          filters: [
            ...newVal,
            ...this.defaultFilters,
          ],
          triggerFetch: true,
        });
      },
    },
    showRetailPrices: {
      get() {
        return this.$store.state.items.showRetailPrices;
      },
      set(newVal) {
        this.$store.commit('items/showRetailPrices', newVal);
      },
    },
    showOnlyActionsAndPromos: {
      get() {
        return this.$store.state.items.showOnlyActionsAndPromos;
      },
      set(newVal) {
        this.$store.dispatch('items/showOnlyActionsAndPromos', newVal);
      },
    },
    sortType: {
      get() {
        return this.$store.state.items.sortType;
      },
      set(newVal) {
        this.$store.dispatch('items/setSortType', newVal);
      },
    },
  },
  methods: {
    changeCollapse(code) {
      this.collapse[code] = !this.collapse[code];
    },
    clearFilters() {
      this.attributes = [];
    },
    getFilterDescriptionTranslation(code, searchvalue) {
      if (this.filterAttributes.length > 0) {
        const { values } = this.filterAttributes.find((o) => o.code === code);
        const value = values.find((o) => o.code === searchvalue);
        return value.description;
      }
      return searchvalue;
    },
    clearFilter(index) {
      this.attributes = this.attributes.filter((k, i) => i !== index);
    },
  },
};
</script>
