<template>
  <div class="empty-state">
    <div class="empty-state__icon">
      <i
        v-if="icon"
        :class="'uil uil-'+icon"
      />
      <i
        v-else
        class="uil uil-search-alt"
      />
    </div>

    <div class="empty-state__content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyState',
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
