<template>
  <div>
    <span
      :key="availableTimespan"
      class="product__availability"
    >

      <div
        v-if="size === 'small'"
        v-b-tooltip.hover
        :title="availableTimespan"
      >
        <span class="progress">
          <span
            class="progress__bar"
            :style="{ backgroundColor: specs()[0], width: specs()[1]+'%' }"
          />
        </span>
      </div>
      <div v-else>
        <span class="progress">
          <span
            class="progress__bar"
            :style="{ backgroundColor: specs()[0], width: specs()[1]+'%' }"
          />
        </span>
        {{ availableTimespan }}
      </div>
    </span>
    <Message
      v-if="weeks > 28 && size !== 'small'"
      type="warning"
      :icon="true"
      :show-close="false"
    >
      {{ $t('item.availabilityMightNotBeCorrect') }}
    </Message>
  </div>
</template>

<script>
import { availabilityText, weeksPerQuantity } from '@/utils/cartUtils';
import Message from '@/components/Message.vue';
import { mapState } from 'vuex';

export default {
  name: 'Price',
  components: {
    Message,
  },
  props: {
    availability: {
      type: Array,
      default() {
        return [];
      },
    },
    quantity: {
      type: [String, Number],
      default: '1',
    },
    size: {
      type: String,
      default: null,
    },

  },
  computed: {
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
    }),
    availableTimespan() {
      return availabilityText(this.availability, this.quantity, this.showRetailPrices);
    },
    weeks() {
      return weeksPerQuantity(this.availability, this.quantity);
    },
  },
  methods: {
    specs() {
      const { weeks } = this;
      if (weeks !== false) {
        if (weeks < 1) { return ['#01d324', '100']; }
        if (weeks < 1.5) { return ['#01d324', '100']; }
        if (weeks < 6) { return ['#fed41c', '75']; }
        if (weeks < 25) { return ['#ff7100', '50']; }
        if (weeks < 50) { return ['#d1000d', '25']; }
        return ['#8b0000', '5'];
      }
      return ['background-color: rgba(0,0,0,.15);', '0'];
    },
  },
};
</script>
