<template>
  <bcf-aside @close="closeAside">
    <bcfAsideHeader :close="closeAside">
      {{ $t('account.change.title') }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <select-customer @changed="closeAside" />
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import selectCustomer from '@/components/selectCustomer.vue';

export default {
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    selectCustomer,
  },
  props: {
    close: {
      type: Function,
      default: null,
    },
  },
  methods: {
    test() {
      console.log('close');
    },
    closeAside() {
      console.log(this.close);
      if (!this.close) {
        this.$router.push({ name: 'account' });
      } else {
        this.close();
      }
    },
  },
};
</script>
