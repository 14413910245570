import { apiInstance } from './api';

export const getFacebookFeeds = async () => {
  const feeds = await apiInstance.get('/v1/facebookFeeds');
  return feeds;
};

export const getSocialFeedDetail = async (id) => {
  const feed = await apiInstance.get(`/v1/facebookFeeds/${id}`);
  return feed.data;
};
