<template>
  <div>
    <div class="m-t-4 m-b-2 m-md-t-0 m-md-b-4">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>{{ $t("header.notifications") }}</h1>
              <a
                class="button button--icon d-md-none"
                @click="showNotificationsSettings = true"
              >
                <i class="uil uil-cog" />
                {{ $t("notifications.settings") }}
              </a>

              <span class="main__title__action--right d-none d-md">
                <span @click="showNotificationsSettings = true">
                  <i class="uil uil-cog" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="container">
      <loader v-if="pending" />
      <div v-else>
        <div v-if="notificationsByDate.length > 0">
          <div
            v-for="date in notificationsByDate"
            :key="date.date"
          >
            <section-title class="m-t-4 m-b-0">
              {{ date.date }}
            </section-title>

            <router-link
              v-for="(notification, index) in date.items"
              :key="index"
              :to="notification.url"
              class="notification__item"
              :class="{ 'notification__item--not-read': !notification.read, 'notification__item--not-seen': !notification.seen}"
            >
              <!--<i
                v-if="notification.category == 'ORDER'"
                class="uil uil-box"
              />-->
              <i
                v-if="notification.type === 'ORDER_SHIPPED'"
                class="uil uil-truck"
              />
              <i
                v-else-if="notification.type === 'NEWS_REMINDER'"
                class="uil uil-rss"
              />
              <i
                v-else-if="notification.type === 'PROMO_REMINDER'"
                class="uil uil-megaphone"
              />
              <i
                v-else-if="notification.type === 'WARRANTY_NEW_MESSAGE'"
                class="uil uil-parcel"
              />
              <i
                v-else
                class="uil uil-bell"
              />
              <div class="notification__item__content">
                {{ notification.message }}
                <small>{{ new Date(notification.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</small>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else>
          <EmptyState icon="bell">
            {{ $t('account.settings.notifications.no') }}
          </EmptyState>
        </div>
      </div>
    </div>
    <notificationsSettings
      v-if="showNotificationsSettings"
      @close="showNotificationsSettings = false"
    />
  </div>
</template>

<style scoped>
</style>

<script>
import loader from '@/elements/loader.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import { formatDate } from '@/utils/dateUtils';
import EmptyState from '@/elements/EmptyState.vue';
import { getNotifications } from '@/api/notifications'; /** Context */
import notificationsSettings from '@/views/account/settings/notifications.vue';

export default {
  name: 'Notifications',
  metaInfo() {
    return {
      title: this.$t('header.notifications'),
    };
  },
  components: {
    EmptyState,
    loader,
    SectionTitle,
    notificationsSettings,
  },
  data() {
    return {
      allNotifications: [],
      pending: true,
      showNotificationsSettings: false,
    };
  },
  async created() {
    this.allNotifications = await getNotifications();
    this.notificationsByDate = this.allNotifications.reduce((dates, notification) => {
      const notificationDate = formatDate(notification.date);
      if (!dates.find(o => o.date === notificationDate)) {
        dates.push({
          date: notificationDate,
          items: [notification],
        });
      } else {
        dates.find(o => o.date === notificationDate).items.push(notification);
      }
      return dates;
    }, []);
    this.pending = false;
  },
};
</script>
<style lang="scss" scoped>
.not-seen {
  i {
    color: red;
  }
}
.not-read {
  background: #efefef;
}
</style>
