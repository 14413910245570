<template>
  <div
    class="expandable-text"
    :class="{ 'expandable': expandable }"
    @click="toggleExpanded()"
  >
    <div
      ref="expandableText"
      class=""
      :class="{ 'truncated': !isExpanded }"
      :style="{ '-webkit-line-clamp': visibleLines }"
    >
      {{ text }}
    </div>
    <span
      v-if="
        expandable"
      class="readmoreless"
    >
      {{ $t(`account.account.myBackOrders.info.${isExpanded ? 'less' : 'more'}`) }}<i
        :class="`uil uil-angle-${isExpanded ? 'up' : 'down'}`"
      /></span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    visibleLines: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      expandable: false,
      isExpanded: false,
    };
  },
  watch: {
    text() {
      this.checkExpandable();
    },
  },
  mounted() {
    this.checkExpandable();
  },
  methods: {
    toggleExpanded() {
      if (this.expandable) {
        this.isExpanded = !this.isExpanded;
      }
    },
    checkExpandable() {
      this.$nextTick(() => {
        if (this.$refs.expandableText && (this.$refs.expandableText.offsetHeight < this.$refs.expandableText.scrollHeight
              || this.$refs.expandableText.offsetWidth < this.$refs.expandableText.scrollWidth)) {
          this.expandable = true;
        } else {
          this.expandable = false;
          this.isExpanded = true;
        }
      });
    },
  },
};
</script>
<style scoped>
.expandable {
  cursor: pointer;
}

.truncated {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.readmoreless {
  font-size: 1.5rem;
  font-weight: bold;
  opacity: 0.3;
}
</style>
