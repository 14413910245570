import { render, staticRenderFns } from "./CartOrderList.vue?vue&type=template&id=ded0a24a"
import script from "./CartOrderList.vue?vue&type=script&lang=js"
export * from "./CartOrderList.vue?vue&type=script&lang=js"
import style0 from "./CartOrderList.vue?vue&type=style&index=0&id=ded0a24a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports