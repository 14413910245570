<template>
  <div
    :class="{
      'status': true,
      [`status__${value}`]: true,
      [`status--disabled`]: disabled,
    }"
  >
    <select
      :value="value"
      class="status-select"
      :disabled="disabled"
      @change="onChange"
    >
      <option
        v-for="status of statuses"
        :key="status"
        :value="status"
      >
        {{ $t(`account.account.myStock.statuses.${status}`) }}
      </option>
    </select>
  </div>
</template>
<script>

export const statuses = [
  'available',
  'reserved',
  'sold',
  'hidden',
];

export default {
  name: 'StatusSelect',
  components: {
  },
  props: {
    value: {
      type: String,
      default: 'available',
    },
    statuses: {
      type: Array,
      default: () => statuses,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    onChange(ev) {
      this.$emit('input', ev.target.value, ev);
      this.$nextTick(() => {
        // In case parent component decides to reject the change, this is needed (solves some bugs for myStock after dismissing dialog)
        ev.target.value = this.value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: inline-grid;
  grid-template-areas: "value select";
  grid-template-columns: auto 1em;
  align-items: center;
  position: relative;

  cursor: pointer;

  &__available {
    background-color: #4ab9e1;
  }

  &__reserved {
    background-color: #fcb88b;
  }

  &__sold {
    background-color: #6dd400;
  }

  &--disabled::after{
    visibility: hidden;
  }
  &::after {
    grid-area: select;
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.8em;
    margin-right: .5em;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.467 8.467' height='32' width='32'%3e%3cpath d='M4.233.265L1.587 2.91H6.88zM.265 3.572v1.323h7.937V3.572zm1.322 1.984l2.646 2.646L6.88 5.556H4.233z' opacity='.996'/%3e%3c/svg%3e");
    background-size: contain;
    background-repeat: no-repeat;
    /*
    background-color: black;
    clip-path: path('M4.233.265L1.587 2.91H6.88zM.265 3.572v1.323h7.937V3.572zm1.322 1.984l2.646 2.646L6.88 5.556H4.233z');*/
    z-index: 1;
  }
}

.status-select {
  grid-column: 1 / -1;
  grid-row: 1 / 1;
  appearance: none;
  background-color: transparent;
  font-size: 1.5rem;
  padding: .5em 2em .5em 1em; /* Extra padding on the right */
  height: auto;
  border: none;
  border-radius: 0;
  background-image: none;
  cursor: inherit;
  outline: none;

  // Remove dropdown arrow in IE10 & IE11 see https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }
  &[disabled]{
    // undo default CSS here, we're indicating disabled by now showing the dropdown icon
    opacity: unset;
    color: white;
  }
}
</style>
