<template>
  <div
    class="loader"
    :class="size?{[`loader--${size}`]:true} : {}"
  >
    <img
      src="@/assets/puff.svg"
      alt="Loading Icon"
    >
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String, // small/normal
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  &--small{
    >img{
      width: 2em;
      height: 2em;
    }
  }
  &--tiny{
    >img{
      width: 14px;
      height: 14px;
    }
  }
}
</style>
