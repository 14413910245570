/* Vue style of writing component: template, script and style*/
<template>
  <div>
    <div class="form-group form-group--search">
      <input
        v-model="search"
        type="search"
        :disabled="disabled"
        :placeholder="`${$t('search')}...`"
      >
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
  },
};
</script>
<style scoped>
input {
  font-size: 12px;
  display: inline-block;
}
button {
  padding: 0.5rem;
}
</style>
