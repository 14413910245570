<template>
  <bcf-aside
    @close="close"
  >
    <bcfAsideHeader :close="close">
      What's my size?
    </bcfAsideHeader>

    <bcfAsideContent>
      <script
        type="application/javascript"
        :src="componentJS"
      />
      <div class="max-width: 100%">
        <bcf-sizing-widget
          theme="primary"
          :lang="lang.substr(0,2).toLowerCase()"
          :bike-type="bikeType"
        />
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>

<script>
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';

export default {
  name: 'SizeModal',
  components: {
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
  },
  props: {
    bikeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      componentJS: process.env.VUE_APP_SIZING_JS,
    };
  },
  computed: {
    lang() {
      return this.$store.state.language.current;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
