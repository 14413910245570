<template>
  <div
    class="preorder"
    :class="{ locked: locked }"
  >
    <ItemsNav />
    <header class="preorder__header">
      <!-- Locked icon -->
      <div
        v-if="locked"
        class="preorder__header__lockedstate"
      >
        <i class="uil uil-lock-alt" />
      </div>
      <!-- Title -->
      <div class="preorder__header__title">
        <h1>
          {{ title }}
        </h1>
        <span
          v-if="preorderCustomer"
          class="preorder__header__title__customer"
        >{{ preorderCustomer.name }}</span>
      </div>
      <!-- Data -->
      <div
        v-if="!loading && preorderitems.length > 0 && missingItems.length === 0"
        class="preorder__header__data"
      >
        <div class="preorder__header__status">
          <div class="preorder__header__info">
            <span class="preorder__header__info__label">STATUS</span>
            <span
              class="preorder__header__info__content status_value"
              :class="statusClass"
            >{{ statusLabel }}</span>
          </div>
          <div class="buttons">
            <!-- Export to excel -->
            <button
              v-if="user.type === 1 && exportOptions && exportOptions.excel"
              class="button button--icon button--danger"
              @click="exportPrebook('excel')"
            >
              <i class="uil uil-file-export" />Export to XLS
            </button>
            <!-- Submit for review -->
            <button
              v-if="status === 0 && user.type >= 2 && containsBikes"
              class="button button--icon button--danger"
              @click="confirmDraftReview(1)"
            >
              <i class="uil uil-file-check-alt" />Submit for review
            </button>
            <!-- Draft reject / approve -->
            <button
              v-if="status === 1 && user.type >= 3"
              class="button button--icon button--danger"
              @click="confirmDraftReject(0)"
            >
              <i class="uil uil-times" />Reject
            </button>
            <button
              v-if="status === 1 && user.type >= 3"
              class="button button--icon button--success"
              @click="confirmDraftApprove(2)"
            >
              <i class="uil uil-check" />Approve
            </button>
            <!-- Sent prebook to customer -->
            <button
              v-if="user.type >= 2 && containsBikes && shareAvailable"
              class="button button--icon button--danger"
              @click="confirmSendToCustomer()"
            >
              <i class="uil uil-share" />Share with IBD
            </button>
            <!-- Review for order -->
            <button
              v-if="status === 2 && user.type >= 2 && containsBikes"
              class="button button--icon button--danger"
              @click="confirmOrderReview(3)"
            >
              <i class="uil uil-file-check-alt" />Submit for review
            </button>
            <!-- Order reject / approve -->
            <button
              v-if="status === 3 && user.type >= 3"
              class="button button--icon button--danger"
              @click="confirmOrderReject(2)"
            >
              <i class="uil uil-times" />Reject
            </button>
            <button
              v-if="status === 3 && user.type >= 3"
              class="button button--icon button--success"
              @click="confirmOrderApprove(4)"
            >
              <i class="uil uil-check" />Approve
            </button>
            <!-- unapprove -->
            <button
              v-if="(status === 2 || status === 4) && user.type >= 3"
              class="button button--icon button--danger"
              @click="confirmUnapprove(0)"
            >
              <i class="uil uil-times" />Unapprove
            </button>
            <!-- order bikes -->
            <button
              v-if="(status === 4 && user.type >= 2)"
              class="button button--icon button--success"
              @click="orderBikes()"
            >
              <i class="uil uil-shopping-cart-alt" />Order bikes
            </button>
            <!-- unlock - admin only -->
            <button
              v-if="status === 5 && user.type === 5"
              class="button button--icon button--danger"
              @click="confirmReopen(0)"
            >
              <i class="uil uil-unlock-alt" />Reopen
            </button>
          </div>
        </div>
        <div class="preorder__header__summary">
          <!-- History -->
          <div
            v-if="historyTotals.price !== 0"
            id="info_history"
            class="preorder__header__info"
          >
            <span class="preorder__header__info__label">{{ historyYear }}</span>
            <span class="preorder__header__info__content">
              <price
                :price="historyTotals.price"
                :currency="currency"
              />
            </span>
          </div>
          <b-tooltip
            target="info_history"
            container="info_history"
            triggers="hover"
            placement="bottom"
          >
            <div class="preorder__header__info__tooltip preorder__header__info__tooltip--large">
              <div class="preorder__header__info__tooltip__column">
                <h3>{{ historyYear === 'LTM' ? 'Last 12 months' : historyYear }}</h3>
                <div><span>Bikes</span><span>{{ historyTotals.qty }}</span></div>
                <div>
                  <span>Total</span><price
                    :price="historyTotals.price"
                    :currency="currency"
                  />
                </div>
              </div>
              <div
                v-for="(range) in Object.keys(historyByRange)"
                :key="range"
                class="preorder__header__info__tooltip__column"
              >
                <h3>{{ range }}</h3>
                <div>
                  <span>Total ({{ historyByRange[range].qty }})</span>
                  <price
                    :price="historyByRange[range].price"
                    :currency="currency"
                  />
                </div>
                <div class="spacer" />
                <div
                  v-for="(model) in getHistoryModelsOrdered(range)"
                  :key="model.model"
                  :class="{ phaseout: model.phaseout }"
                >
                  <span>{{ model.model }} ({{ model.qty }})</span>
                  <price
                    :price="model.price"
                    :currency="currency"
                  />
                </div>
              </div>
            </div>
          </b-tooltip>
          <!-- Dealer level -->
          <div
            id="info_dealerlevel"
            class="preorder__header__info"
          >
            <span class="preorder__header__info__label">Level</span>
            <span class="preorder__header__info__content">{{ dealerLevel }}</span>
          </div>
          <b-tooltip
            v-if="floorplanTargets || perfectLaunchTargets"
            target="info_dealerlevel"
            triggers="hover"
            placement="bottom"
          >
            <div class="preorder__header__info__tooltip preorder__header__info__tooltip--large">
              <div
                v-if="perfectLaunchTargets"
                class="preorder__header__info__tooltip__column"
              >
                <h3>Perfect Launch</h3>
                <template v-for="(level) in Object.keys(perfectLaunchTargets)">
                  <div :key="'pl_' + level">
                    <span>{{ level }}</span>
                  </div>
                  <div :key="'pl_' + level + '_value'">
                    <span>{{ perfectLaunchTargets[level] }} </span>
                  </div>
                </template>
              </div>
              <div
                v-if="floorplanTargets"
                class="preorder__header__info__tooltip__column"
              >
                <h3>Floorplan</h3>
                <template v-for="entry in floorplanTargets">
                  <div :key="'fp_' + entry.label">
                    <span>{{ entry.label }}</span>
                  </div>
                  <div
                    :key="'fp_' + entry.label + '_value'"
                    :class="{ 'preorder__header__info__content--success': entry.qty >= entry.target }"
                  >
                    <span>{{ entry.qty || 0 }} / {{ entry.targetLabel || entry.target }} </span>
                  </div>
                </template>
                <div><span style="font-size: 12px;"><br><i>Floorplan bikes must<br>be ordered in January.</i></span></div>
              </div>
            </div>
          </b-tooltip>
          <!-- Targets -->
          <!-- Year -->
          <div
            v-if="yearTarget !== 0"
            id="info_yeartarget"
            class="preorder__header__info"
          >
            <span class="preorder__header__info__label"><span v-if="yearTargetType === 0">Target</span><span v-else>#BIKES</span> {{ getYear() }}</span>
            <span
              class="preorder__header__info__content"
              :class="{ 'preorder__header__info__content--success': totals.price > yearTarget }"
            >
              <price
                v-if="yearTargetType === 0"
                :price="yearTarget"
                :currency="currency"
              />
              <template v-else>
                {{ yearTarget }}
              </template>
            </span>
          </div>
          <!-- SSPD -->
          <div
            v-if="pakTarget !== 0"
            id="info_paktarget"
            class="preorder__header__info"
          >
            <span class="preorder__header__info__label">SSPD TARGET</span>
            <span
              class="preorder__header__info__content"
              :class="{ 'preorder__header__info__content--success': (pakRemaining === 0 && yearTarget > 0) }"
            ><price
              :price="pakTarget"
              :currency="currency"
            /></span>
          </div>
          <b-tooltip
            v-if="pakTarget !== 0"
            target="info_paktarget"
            triggers="hover"
            placement="bottom"
          >
            <div class="preorder__header__info__tooltip">
              <div class="preorder__header__info__tooltip__column">
                <h3>SSPD Target per month</h3>
                <div
                  v-for="index in 5"
                  :key="`ppd-month${index}`"
                >
                  <span>{{ getMonthName(index-1) }} ({{ paktargetPerMonth[index-1].pct }}%)</span>
                  <price
                    :price="paktargetPerMonth[index-1].price"
                    :currency="currency"
                    :class="{ success: totalsPerMonth[index-1].price >= paktargetPerMonth[index-1].price}"
                  />
                </div>
              </div>
            </div>
          </b-tooltip>
          <!-- SSPD Remaining -->
          <div
            v-if="pakTarget !== 0"
            id="info_pakremaining"
            class="preorder__header__info"
          >
            <span class="preorder__header__info__label">SSPD REMAINING</span>
            <span
              class="preorder__header__info__content"
              :class="{ 'preorder__header__info__content--success': (pakRemaining === 0 && yearTarget > 0) }"
            ><price
              :price="pakRemaining"
              :currency="currency"
              :show-empty-as-dash="yearTarget === 0"
            /></span>
          </div>
          <b-tooltip
            v-if="pakTarget !== 0"
            target="info_pakremaining"
            triggers="hover"
            placement="bottom"
          >
            <div class="preorder__header__info__tooltip">
              <div class="preorder__header__info__tooltip__column">
                <h3>Bikes applicable for SSPD</h3>
                <div><span>Bikes</span><span>{{ paktotals.qty }}</span></div>
                <div>
                  <span>Total</span><price
                    :price="paktotals.price"
                    :currency="currency"
                  />
                </div>
              </div>
            </div>
          </b-tooltip>
          <!-- Totals -->
          <div
            id="info_total"
            class="preorder__header__total preorder__header__info"
          >
            <span class="preorder__header__info__label">Total</span>
            <span class="preorder__header__info__content"><Summary
              always-show
              :data="totals"
            /></span>
          </div>
          <b-tooltip
            target="info_total"
            triggers="hover"
            placement="bottom"
          >
            <div class="preorder__header__info__tooltip preorder__header__info__tooltip--large">
              <div class="preorder__header__info__tooltip__column">
                <h3>SSP {{ getYear() }}</h3>
                <div><span>Bikes</span><span>{{ totals.qty }}</span></div>
                <div>
                  <span>% of year target</span>
                  <span
                    v-if="yearTarget > 0"
                    :class="{ success: yearTargetPercentage >= 100 }"
                  >{{ yearTargetPercentage }}%</span>
                  <span v-else>-</span>
                </div>
                <div>
                  <span>Year target remaining</span>
                  <price
                    v-if="yearTargetType === 0"
                    :price="Math.max(0, yearTarget - totals.price)"
                    :currency="currency"
                    :show-empty-as-dash="yearTarget === 0"
                    :class="{ success: yearTarget > 0 && Math.max(0, yearTarget - totals.price) === 0 }"
                  />
                  <span v-else>{{ Math.max(0, yearTarget - totals.qty) }}</span>
                </div>
                <div class="spacer" />
                <div>
                  <span>MSRP Total</span><price
                    :price="totals.msrp"
                    :currency="currency"
                    :show-empty-as-dash="true"
                  />
                </div>
              </div>
              <div class="preorder__header__info__tooltip__column">
                <h3>SSP per month</h3>
                <div
                  v-for="index in 6"
                  :key="`ppd-month${index}`"
                >
                  <span>{{ getMonthName(index-1) }} ({{ totalsPerMonth[index-1].qty }})</span>
                  <price
                    :price="totalsPerMonth[index-1].price"
                    :currency="currency"
                  />
                </div>
              </div>
              <div class="preorder__header__info__tooltip__column">
                <h3>&nbsp;</h3>
                <div
                  v-for="index in 6"
                  :key="`ppd-month${index}`"
                >
                  <span>{{ getMonthName(index+5) }} ({{ totalsPerMonth[index+5].qty }})</span>
                  <price
                    :price="totalsPerMonth[index+5].price"
                    :currency="currency"
                  />
                </div>
              </div>
            </div>
          </b-tooltip>
        </div>
      </div>
    </header>
    <!-- Loading -->
    <template v-if="loading">
      <div class="preorder__loading">
        <Loader />
      </div>
    </template>
    <!-- Messages -->
    <template v-else-if="user.type === 1 && !customerCanView">
      <div class="preorder__message">
        Season start planning not accessible yet.<br>Please contact your account manager.
      </div>
    </template>
    <template v-else-if="preorderitems.length === 0">
      <div class="preorder__message">
        No season start planning items found.
      </div>
    </template>
    <template v-else-if="user.type !== 1 && missingItems.length > 0">
      <div class="preorder__missingitems">
        Following items in your season start planning are no longer available:<br>{{ missingItems.join(', ') }}.
        <button
          class="button button--danger"
          @click="fixMissingItems()"
        >
          Remove items from season start planning and continue
        </button>
      </div>
    </template>
    <template v-else-if="user.type === 1 && missingItems.length > 0">
      <div class="preorder__message">
        Unable to open your season start planning.<br>Please contact your account manager.
      </div>
    </template>
    <!-- Loaded data -->
    <template v-else>
      <!-- Normal view -->
      <template>
        <nav class="preorder__navigation preorder__navigation--normal">
          <div class="preorder__navigation__tablist preorder__navigation__segments">
            <div
              v-for="(range, rangeindex) in ranges"
              :key="range"
              :class="{ active: activeRange === rangeindex}"
              class="preorder__navigation__tab"
              @click="selectRange(rangeindex)"
            >
              {{ range }}
              <Summary
                class="small"
                :data="totals[brands[activeBrand]][range]"
              />
            </div>
          </div>
        </nav>
        <div class="preorder__content preorder__content--normal">
          <div class="preorder__content__actions">
            <span
              class="preorder__content__actions__action"
              @click="expandAll"
            >expand all</span>
            <span
              class="preorder__content__actions__action"
              @click="collapseGroupsets"
            >collapse groupsets</span>
            <span
              class="preorder__content__actions__action"
              @click="collapseAll"
            >collapse all</span>
          </div>
          <div
            v-for="model in models"
            :key="model.model"
            class="preorder__model"
            :class="{ collapsed: collapsedState[model.model].collapsed }"
          >
            <div
              class="preorder__model__header"
              @click="collapsedState[model.model].collapsed = !collapsedState[model.model].collapsed"
            >
              <span class="label">{{ model.model }}<span
                v-if="brand === 'ridley'"
                class="level "
              >{{ model.niveau }}</span></span>

              <div
                v-if="historyTotals.price !== 0"
                class="preorder__history"
              >
                <span class="year">{{ historyYear }}</span>
                <span class="amount">{{ getHistoryForModel(model.model).qty }} | <price
                  :price="getHistoryForModel(model.model).price"
                  :currency="currency"
                /></span>
              </div>
              <Summary
                :data="totals[brands[activeBrand]][ranges[activeRange]][model.model]"
              />
              <div class="preorder__chevron" />
            </div>
            <div class="preorder__model__content">
              <div
                v-for="groupset in model.groupsets"
                :key="`${model.model}_${groupset.groupset}`"
                class="preorder__groupset"
                :class="{ collapsed: collapsedState[model.model][groupset.groupset].collapsed }"
              >
                <div
                  class="preorder__groupset__header"
                  @click="collapsedState[model.model][groupset.groupset].collapsed = !collapsedState[model.model][groupset.groupset].collapsed"
                >
                  <div class="label">
                    <span class="label-groupset">{{ groupset.groupset }}</span>
                    <span
                      v-if="isElectric(model)"
                      class="label-wheels"
                    >{{ formatInfo(groupset.drive_unit) }}</span>
                    <span
                      v-else
                      class="label-wheels"
                    >{{ formatInfo(groupset.wheelset) }}</span>
                    <span
                      v-if="isElectric(model)"
                      class="label-kit"
                    >{{ formatInfo(groupset.battery) }}</span>
                    <span
                      v-else
                      class="label-kit"
                    >{{ formatInfo(groupset.handlebar) }}</span>
                  </div>
                  <Summary
                    :data="totals[brands[activeBrand]][ranges[activeRange]][model.model][groupset.groupset]"
                  />
                  <div
                    class="preorder__chevron"
                  />
                </div>
                <div class="preorder__groupset__content">
                  <div
                    v-for="(design, designindex) in groupset.designs"
                    :key="design.design"
                    class="preorder__design"
                  >
                    <div class="preorder__design__left">
                      <div class="preorder__design__left__header">
                        <span class="design_code">{{ design.design }}</span>
                      </div>
                      <div
                        v-if="design.image"
                        class="preorder__design__left__image"
                        @click="openDesignLightbox(model, groupset, designindex)"
                      >
                        <div
                          class="preorder__design__left__collectioncolortype"
                          :class="`preorder__design__left__collectioncolortype--${formatCollectionColorType(design.collectioncolor_type)}`"
                        >
                          {{ formatCollectionColorType(design.collectioncolor_type) }}
                        </div>
                        <img
                          :src="design.image.thumb_avif"
                        >
                        <div class="preorder__design__left__image__overlay">
                          <i class="uil uil-search-plus" />
                        </div>
                        <div
                          v-if="isConfidential(design.confidential_until)"
                          class="preorder__design__left__notice"
                        >
                          {{ $t('confidential_until') }} {{ formatDate(design.confidential_until) }}
                        </div>
                      </div>
                      <div
                        v-else
                        class="preorder__design__left__image preorder__design__left__image--placeholder"
                      >
                        <img
                          :src="`https://placehold.jp/eeeeee/cccccc/355x250.png?text=NO%20IMAGE`"
                        >
                      </div>
                    </div>
                    <div class="preorder__design__right">
                      <div class="preorder__design__right__prices">
                        <div class="netto_price">
                          <price
                            :price="design.$$price.price"
                            :currency="design.$$price.currency"
                          />
                        </div>
                        <div class="msrp_price">
                          MSRP <price
                            :price="design.$$price.msrp"
                            :currency="design.$$price.currency"
                          />
                        </div>
                        <Summary
                          :data="totals[brands[activeBrand]][ranges[activeRange]][model.model][groupset.groupset][design.design]"
                        />
                      </div>
                      <div class="preorder__design__right__table">
                        <div class="table__cell table__header table__header--column" />
                        <div class="table__cell table__header table__header--column" />
                        <div
                          v-for="(month, monthIndex) in 12"
                          :key="`${design.design}_${monthIndex}`"
                          class="table__cell table__header table__header--column"
                        >
                          {{ getMonthName(monthIndex) }}
                        </div>

                        <template
                          v-for="item in design.items"
                        >
                          <div
                            :key="item.externalid"
                            class="table__cell table__header table__header--row"
                          >
                            <router-link
                              v-if="userCanViewItem(item)"
                              target="_blank"
                              :to="'/items/bikes/'+item.id"
                              tabindex="-1"
                            >
                              <span>{{ item.externalid }}</span><i class="uil uil-external-link-alt" />
                            </router-link>
                            <span v-else>{{ item.externalid }}</span>
                          </div>
                          <div
                            :key="`${item.externalid}_${item.frame_size}`"
                            class="table__cell"
                          >
                            {{ item.frame_size }}
                          </div>
                          <div
                            v-for="(month, monthIndex) in 12"
                            :key="`${item.externalid}_${monthIndex}`"
                            class="table__cell"
                            :class="{ 'table__cell--filled': preorder[item.externalid]?.[monthIndex] > 0, 'table__cell--disabled': !canOrder(item, monthIndex) }"
                          >
                            <input
                              :disabled="locked || !canOrder(item, monthIndex)"
                              min="0"
                              type="number"
                              class="table__cell__input"
                              :value="preorder[item.externalid]?.[monthIndex] || null"
                              @input="handleInput(item.externalid, monthIndex, $event.target.value)"
                              @wheel="$event.target.blur()"
                              @keyup.enter="$event.target.blur()"
                            >
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- For printing -->
      <template v-if="printMode">
        <div
          ref="print__wrapper"
          class="print__wrapper printmode"
        >
          <div
            ref="print"
            class="print"
          >
            <header class="preorder__header">
              <div class="preorder__header__title">
                <h1>
                  {{ title }}
                </h1>
                <span
                  v-if="preorderCustomer"
                  class="preorder__header__title__customer"
                >{{ preorderCustomer.name }}</span>
              </div>
              <div
                v-if="!loading && missingItems.length === 0"
                class="preorder__header__data"
              >
                <div class="preorder__header__status" />
                <div class="preorder__header__summary">
                  <div
                    v-if="historyTotals.price !== 0"
                    id="info_history"
                    class="preorder__header__info"
                  >
                    <span class="preorder__header__info__label">{{ historyYear }}</span>
                    <span class="preorder__header__info__content">
                      <price
                        :price="historyTotals.price"
                        :currency="currency"
                      />
                    </span>
                  </div>
                  <div
                    id="info_dealerlevel"
                    class="preorder__header__info"
                  >
                    <span class="preorder__header__info__label">Level</span>
                    <span class="preorder__header__info__content">{{ dealerLevel }}</span>
                  </div>
                  <div
                    v-if="yearTarget !== 0"
                    id="info_yeartarget"
                    class="preorder__header__info"
                  >
                    <span class="preorder__header__info__label">{{ getYear() }} Target</span>
                    <span
                      class="preorder__header__info__content"
                      :class="{ 'preorder__header__info__content--success': totals.price > yearTarget }"
                    ><price
                      :price="yearTarget"
                      :currency="currency"
                    /></span>
                  </div>
                  <div
                    v-if="pakTarget !== 0"
                    id="info_paktarget"
                    class="preorder__header__info"
                  >
                    <span class="preorder__header__info__label">SSPD TARGET</span>
                    <span
                      class="preorder__header__info__content"
                      :class="{ 'preorder__header__info__content--success': (pakRemaining === 0 && yearTarget > 0) }"
                    ><price
                      :price="pakTarget"
                      :currency="currency"
                    /></span>
                  </div>
                  <div
                    v-if="pakTarget !== 0"
                    id="info_pakremaining"
                    class="preorder__header__info"
                  >
                    <span class="preorder__header__info__label">SSPD REMAINING</span>
                    <span
                      class="preorder__header__info__content"
                      :class="{ 'preorder__header__info__content--success': (pakRemaining === 0 && yearTarget > 0) }"
                    ><price
                      :price="pakRemaining"
                      :currency="currency"
                      :show-empty-as-dash="yearTarget === 0"
                    /></span>
                  </div>
                  <div
                    id="info_total"
                    class="preorder__header__total preorder__header__info"
                  >
                    <span class="preorder__header__info__label">Total</span>
                    <span class="preorder__header__info__content"><Summary
                      always-show
                      :data="totals"
                    /></span>
                  </div>
                </div>
              </div>
            </header>
            <div
              v-for="(range, rangeindex) in ranges"
              :key="range"
              class="preorder__navigation__tab print__range"
            >
              <div class="print__range__title">
                {{ range }}
                <Summary
                  class="small"
                  :data="totals[brands[activeBrand]][range]"
                />
              </div>
              <div class="preorder__content">
                <div
                  v-for="model in preorderitems[activeBrand]?.ranges[rangeindex]?.models"
                  :key="model.model"
                  class="preorder__model"
                  :class="{ collapsed: false }"
                >
                  <div
                    class="preorder__model__header"
                  >
                    <span class="label">{{ model.model }}<span class="level ">{{ model.niveau }}</span></span>

                    <Summary
                      :data="totals[brands[activeBrand]][ranges[rangeindex]][model.model]"
                    />
                  </div>
                  <div class="preorder__model__content">
                    <div
                      v-for="groupset in model.groupsets"
                      :key="`${model.model}_${groupset.groupset}`"
                      class="preorder__groupset"
                      :class="{ collapsed: false }"
                    >
                      <div
                        class="preorder__groupset__header"
                      >
                        <div class="label">
                          <span class="label-groupset">{{ groupset.groupset }}</span>
                          <span
                            v-if="isElectric(model)"
                            class="label-wheels"
                          >{{ formatInfo(groupset.drive_unit) }}</span>
                          <span
                            v-else
                            class="label-wheels"
                          >{{ formatInfo(groupset.wheelset) }}</span>
                          <span
                            v-if="isElectric(model)"
                            class="label-kit"
                          >{{ formatInfo(groupset.battery) }}</span>
                          <span
                            v-else
                            class="label-wheels"
                          >{{ formatInfo(groupset.handlebar) }}</span>
                        </div>
                        <Summary
                          :data="totals[brands[activeBrand]][ranges[rangeindex]][model.model][groupset.groupset]"
                        />
                      </div>
                      <div class="preorder__groupset__content">
                        <div
                          v-for="(design, designindex) in groupset.designs"
                          :key="design.design"
                          class="preorder__design"
                        >
                          <div class="preorder__design__left">
                            <div class="preorder__design__left__header">
                              <span class="design_code">{{ design.design }}</span>
                            </div>
                            <div
                              v-if="design.image"
                              class="preorder__design__left__image"
                            >
                              <div
                                class="preorder__design__left__collectioncolortype"
                                :class="`preorder__design__left__collectioncolortype--${formatCollectionColorType(design.collectioncolor_type)}`"
                              >
                                {{ formatCollectionColorType(design.collectioncolor_type) }}
                              </div>
                              <img
                                :src="design.image.thumb"
                              >
                              <div
                                v-if="isConfidential(design.confidential_until)"
                                class="preorder__design__left__notice"
                              >
                                {{ $t('confidential_until') }} {{ formatDate(design.confidential_until) }}
                              </div>
                            </div>
                            <div
                              v-else
                              class="preorder__design__left__image preorder__design__left__image--placeholder"
                            >
                              <img
                                :src="`https://placehold.jp/eeeeee/cccccc/355x250.png?text=NO%20IMAGE`"
                              >
                            </div>
                          </div>
                          <div class="preorder__design__right">
                            <div class="preorder__design__right__prices">
                              <div class="netto_price">
                                <price
                                  :price="design.$$price.price"
                                  :currency="design.$$price.currency"
                                />
                              </div>
                              <div class="msrp_price">
                                MSRP <price
                                  :price="design.$$price.msrp"
                                  :currency="design.$$price.currency"
                                />
                              </div>
                              <Summary
                                :data="totals[brands[activeBrand]][ranges[rangeindex]][model.model][groupset.groupset][design.design]"
                              />
                            </div>
                            <div class="preorder__design__right__table">
                              <div class="table__cell table__header table__header--column" />
                              <div class="table__cell table__header table__header--column" />
                              <div
                                v-for="(month, monthIndex) in 12"
                                :key="`${design.design}_${monthIndex}`"
                                class="table__cell table__header table__header--column"
                              >
                                {{ getMonthName(monthIndex) }}
                              </div>

                              <template
                                v-for="item in design.items"
                              >
                                <div
                                  :key="item.externalid"
                                  class="table__cell table__header table__header--row"
                                >
                                  <router-link
                                    target="_blank"
                                    :to="'/items/bikes/'+item.id"
                                    tabindex="-1"
                                  >
                                    <span>{{ item.externalid }}</span><i class="uil uil-external-link-alt" />
                                  </router-link>
                                </div>
                                <div
                                  :key="`${item.externalid}_${item.frame_size}`"
                                  class="table__cell"
                                >
                                  {{ item.frame_size }}
                                </div>
                                <div
                                  v-for="(month, monthIndex) in 12"
                                  :key="`${item.externalid}_${monthIndex}`"
                                  class="table__cell"
                                  :class="{ 'table__cell--filled': preorder[item.externalid]?.[monthIndex] > 0, 'table__cell--disabled': !canOrder(item, monthIndex) }"
                                >
                                  <input
                                    :disabled="true"
                                    min="0"
                                    type="number"
                                    class="table__cell__input"
                                    :value="preorder[item.externalid]?.[monthIndex] || null"
                                  >
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <light-box
      ref="lightbox"
      :images="lightBoxImages"
      :show-caption="true"
      :show-light-box="false"
    />
  </div>
</template>
<script>
import ItemsNav from '@/components/ItemsNav.vue';
import Summary from '@/components/preorder/summary.vue';
import LightBox from '@/components/LightBox/LightBox.vue';
import Price from '@/elements/Price.vue';
import {
  getPrebook, savePrebook, getPrebookItems, getLTM, getHistory, getPrebookConfig, getPrebookExports,
} from '@/api/api'; /** Context */
import { isEmpty } from '@/utils/object';
import Loader from '@/elements/loader.vue';
import { mapState } from 'vuex';
import dialogs from '@/utils/dialogs';
import { formatDate } from '@/utils/dateUtils';
import RemarksDialog from '@/components/preorder/remarksDialog.vue';
// import html2pdf from 'html2pdf.js';

const Reason = Object.freeze({
  Interval: 0,
  PageClose: 1,
  WindowClose: 2,
  StatusChanged: 3,
  SendToCustomer: 4,
});

const Status = Object.freeze({
  draft: 0,
  review_draft: 1,
  presentation: 2,
  review_order: 3,
  approved: 4,
  closed: 5,
});

const TargetType = Object.freeze({
  turnover: 0,
  bikes: 1,
});

export default {
  name: 'Prebook',
  metaInfo() {
    return {
      title: this.$t('cart.preOrder'),
    };
  },
  components: {
    ItemsNav,
    Loader,
    Price,
    Summary,
    LightBox,
  },
  data() {
    return {
      brand: 'ridley',
      config: null,
      loading: true, // loading state
      printMode: false,
      missingItems: [],
      exportOptions: null,
      hasChanges: false, // true if something is changed
      saveInterval: null, // active interval for storing data to the db
      activeBrand: 0, // selected brand index
      activeRange: 0, // selected range index
      status: 0, // preorder status 0=draft, 1=pending review, 2=Approved, 3=Closed
      prev_status: null,
      openToCustomer: false,
      fetchItemsController: null,
      preorderitems: null, // tree structure of preorderable items with all data, top-down is BRAND, RANGE, MODEL, GROUPSET, DESIGN
      collapsedState: null, // tree structure of preorderable items
      preorder: {}, // preorder data
      storedpreorder: null, // preorder data that is stored in db, for comparison
      totals: {},
      totalsPerMonth: Array(12).fill({ qty: 0, price: 0 }),
      totalsPerLevel: {},
      totalsPerLevelFirstMonth: {},
      totalsPerModelFirstMonth: {},
      itemsByExternalId: {},
      paktotals: {},
      preorderCustomer: null,
      lightBoxImages: [],
      remarks: null,
      history: null,
      historyTotals: { qty: 0, price: 0 },
      historyYear: null,
    };
  },
  watch: {
    $route(newRoute, oldRoute) {
      console.log('Route changed');
      // brand switch through url
      if (newRoute.params?.brand && (newRoute.params?.brand !== oldRoute.params?.brand)) {
        this.saveToDB(Reason.PageClose);
        this.fetchItemsController.abort();
        this.initialize();
        // window.location.reload();
      }
    },
  },
  computed: {
    isEmpty() {
      return isEmpty(this.preorder);
    },
    containsBikes() {
      return this.totals.qty > 0;
    },
    shareAvailable() {
      const afterStartOfSeptember = new Date() > new Date(this.getYear() - 1, 7, 31, 23, 59, 59); // 31/08 - 23h59m59s
      return afterStartOfSeptember && this.status >= 2;
    },
    maxOrderTimeframe() {
      switch (this.brand) {
        case 'res': return 12;
        default: return 2;
      }
    },
    brandLabel() {
      switch (this.brand) {
        case 'merckx': return 'Eddy Merckx';
        // case 'res': return 'RES Ridley';
        case 'orion': return 'Ridley RES';
        default: return this.brand;
      }
    },
    title() {
      switch (this.brand) {
        case 'merckx': return `Eddy Merckx ${this.getYear()}`;
        case 'orion': return `Ridley RES ${this.getYear()}`;
        // case 'res': return 'ridley';
        default: return `${this.brand} ${this.getYear()}`;
      }
    },
    brands() {
      if (!this.preorderitems) return [];
      return this.preorderitems.map((entry) => entry.brand) || [];
    },
    ranges() {
      if (!this.preorderitems) return [];
      return this.preorderitems[this.activeBrand]?.ranges.map((entry) => entry.range) || [];
    },
    models() {
      if (!this.preorderitems) return [];
      return this.preorderitems[this.activeBrand]?.ranges[this.activeRange]?.models || [];
    },
    allModels() {
      if (!this.preorderitems) return [];
      return this.preorderitems[this.activeBrand]?.ranges.reduce((allmodels, range) => {
        const rangeModels = range.models.reduce((result, model) => {
          result.push(model.model);
          return result;
        }, []);
        allmodels.push(...rangeModels);
        return allmodels;
      }, []);
    },
    statusClass() {
      return Object.keys(Status).find((key) => Status[key] === this.status);
    },
    statusLabel() {
      switch (this.status) {
        case Status.draft: return 'Preparation';
        case Status.review_draft: return 'Preparation review';
        case Status.presentation: return 'Presentation';
        case Status.review_order: return 'Order review';
        case Status.approved: return 'Approved';
        case Status.closed: return 'Closed';
        default: return 'Unknown';
      }
    },
    ...mapState({
      user: (state) => state.auth.user,
      showRetailPrices: (state) => state.items.showRetailPrices,
      currency: (state) => state.customer.selectedCustomer.attributes.currency,
    }),
    locked() {
      return this.user.type === 1 || this.status === Status.review_draft || this.status === Status.review_order || this.status === Status.approved || this.status === Status.closed;
    },
    contract() {
      const contractBrandName = this.brand === 'orion' ? 'res' : this.brand;
      const result = this.preorderCustomer.attributes.contracts?.find((contract) => contract.brand.toLowerCase() === contractBrandName);
      return result;
    },
    yearTargetType() {
      const targetTypeByBrand = {
        ridley: TargetType.turnover,
        merckx: TargetType.turnover,
        orion: TargetType.bikes,
      };
      return targetTypeByBrand[this.brand];
    },
    yearTarget() {
      if (this.yearTargetType === TargetType.turnover) {
        return this.contract?.nextyeartarget || 0;
      }
      const bikesTargetByBrand = {
        orion: {
          1: 50,
          2: 25,
          3: 10,
        },
      };
      return bikesTargetByBrand[this.brand]?.[this.dealerLevel] || 0;
    },
    yearTargetPercentage() {
      if (this.yearTargetType === TargetType.turnover) {
        const percentage = (Number(this.totals.price) / Number(this.yearTarget)) * 100;
        return Math.round((percentage + Number.EPSILON) * 100) / 100;
      } if (this.yearTargetType === TargetType.bikes) {
        const percentage = (Number(this.totals.qty) / Number(this.yearTarget)) * 100;
        return Math.round((percentage + Number.EPSILON) * 100) / 100;
      }
      return 0;
    },
    pakTarget() {
      if (this.yearTargetType === TargetType.turnover) {
        return this.contract?.nextyearpreordertarget || 0;
      }
      return 0;
    },
    pakRemaining() {
      return Math.max(0, this.pakTarget - this.pakTotal);
    },
    pakTotal() {
      return this.paktotals.price;
    },
    dealerLevel() {
      const rawDealerLevel = this.contract?.nextyeardealerlevel;
      return rawDealerLevel?.substr(rawDealerLevel.length - 1) || '-';
    },
    paktargetPerMonth() {
      return [
        // jan
        {
          pct: 16,
          price: (this.yearTarget / 100) * 16,
        },
        // feb
        {
          pct: 11,
          price: (this.yearTarget / 100) * 11,
        },
        // mar
        {
          pct: 7.5,
          price: (this.yearTarget / 100) * 7.5,
        },
        // apr
        {
          pct: 7.5,
          price: (this.yearTarget / 100) * 7.5,
        },
        // may
        {
          pct: 8,
          price: (this.yearTarget / 100) * 8,
        },
      ];
    },
    perfectLaunchTargets() {
      const perfectLaunchTargets = {
        ridley: {
          0: { Elite: 0, Essential: 0 },
          1: { Elite: 1, Essential: 2 },
          2: { Elite: 1, Essential: 2 },
          3: { Elite: 1, Essential: 2 },
          4: { Elite: 1, Essential: 2 },
          5: { Elite: 0, Essential: 2 },
          6: { Elite: 0, Essential: 2 },
        },
      };
      return perfectLaunchTargets[this.brand]?.[this.dealerLevel];
    },
    frameLevels() {
      if (this.brand === 'ridley') {
        return ['Elite', 'Essential', 'Hydro'];
      }
      return [];
    },
    floorplanTargets() {
      const floorPlanTargets = {
        ridley: {
          1: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 22,
              targetLabel: '>21',
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 4,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 11,
              targetLabel: '>10',
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 9,
              targetLabel: '>8',
            },
          ],
          2: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 21,
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 3,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 10,
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 8,
            },
          ],
          3: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 16,
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 2,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 8,
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 6,
            },
          ],
          4: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 12,
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 2,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 6,
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 4,
            },
          ],
          5: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 9,
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 1,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 5,
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 3,
            },
          ],
          6: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 6,
            },
            {
              label: 'Elite',
              qty: this.totalsPerLevelFirstMonth.Elite,
              target: 1,
            },
            {
              label: 'Essential',
              qty: this.totalsPerLevelFirstMonth.Essential,
              target: 3,
            },
            {
              label: 'Hydro',
              qty: this.totalsPerLevelFirstMonth.Hydro,
              target: 2,
            },
          ],
        },
        merckx: {
          1: [
            {
              label: 'Total',
              qty: (this.totalsPerModelFirstMonth['525'] || 0) + (this.totalsPerModelFirstMonth.Mendrisio || 0) + (this.totalsPerModelFirstMonth['Pévèle Carbon'] || 0) + (this.totalsPerModelFirstMonth['Strasbourg Carbon'] || 0) + (this.totalsPerModelFirstMonth['Strasbourg Aluminium'] || 0),
              target: 9,
              targetLabel: '>9',
            },
            {
              label: '525',
              qty: this.totalsPerModelFirstMonth['525'] || 0,
              target: 2,
            },
            {
              label: 'Mendrisio',
              qty: this.totalsPerModelFirstMonth.Mendrisio || 0,
              target: 2,
            },
            {
              label: 'Pévèle Carbon',
              qty: this.totalsPerModelFirstMonth['Pévèle Carbon'] || 0,
              target: 2,
            },
            {
              label: 'Strasbourg Carbon',
              qty: this.totalsPerModelFirstMonth['Strasbourg Carbon'] || 0,
              target: 1,
            },
            {
              label: 'Strasbourg Aluminium',
              qty: this.totalsPerModelFirstMonth['Strasbourg Aluminium'] || 0,
              target: 2,
            },
          ],
          2: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 6,
            },
            {
              label: '525',
              qty: this.totalsPerModelFirstMonth['525'] || 0,
              target: 1,
            },
            {
              label: 'Mendrisio',
              qty: this.totalsPerModelFirstMonth.Mendrisio || 0,
              target: 2,
            },
            {
              label: 'Pévèle Carbon',
              qty: this.totalsPerModelFirstMonth['Pévèle Carbon'] || 0,
              target: 1,
            },
            {
              label: 'Strasbourg Carbon',
              qty: this.totalsPerModelFirstMonth['Strasbourg Carbon'] || 0,
              target: 1,
            },
            {
              label: 'Strasbourg Aluminium',
              qty: this.totalsPerModelFirstMonth['Strasbourg Aluminium'] || 0,
              target: 1,
            },
          ],
          3: [
            {
              label: 'Total',
              qty: this.totalsPerMonth[0].qty,
              target: 6,
            },
            {
              label: '525',
              qty: this.totalsPerModelFirstMonth['525'] || 0,
              target: 1,
            },
            {
              label: 'Mendrisio',
              qty: this.totalsPerModelFirstMonth.Mendrisio || 0,
              target: 1,
            },
            {
              label: 'Strasbourg Aluminium',
              qty: this.totalsPerModelFirstMonth['Strasbourg Aluminium'] || 0,
              target: 1,
            },
          ],
        },
        orion: {
        },
      };
      return floorPlanTargets[this.brand]?.[this.dealerLevel];
    },
    historyByRange() {
      const result = {};
      this.history.forEach((entry) => {
        const modelData = {};
        modelData[entry.model] = {
          qty: Number(entry.qty),
          price: Number(entry.price),
          phaseout: this.isPhaseOutModel(entry.model),
        };

        if (!result[entry.range]) result[entry.range] = {};
        result[entry.range].qty = (result[entry.range]?.qty || 0) + Number(entry.qty);
        result[entry.range].price = (result[entry.range]?.price || 0) + Number(entry.price);
        result[entry.range].models = { ...result[entry.range].models, ...modelData };
      });
      return result;
    },
    customerCanView() {
      return (this.openToCustomer && this.status >= Status.presentation);
    },
  },
  async mounted() {
    this.initialize();
    this.saveInterval = setInterval(this.saveToDB, 300000, Reason.Interval); // server to db every minute.
    window.addEventListener('beforeunload', this.windowClosed);
    window.addEventListener('beforeprint', this.printScreenOpened);
    window.addEventListener('afterprint', this.printScreenClosed);
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    this.saveToDB(Reason.PageClose);
    window.removeEventListener('beforeunload', this.windowClosed);
    window.removeEventListener('beforeprint', this.printScreenOpened);
    window.removeEventListener('afterprint', this.printScreenClosed);
  },
  methods: {
    async initialize() {
      this.loading = true;
      this.brand = this.$route.params.brand;
      this.$nextTick(() => window.scrollTo({ top: 142, behavior: 'smooth' }));
      this.config = await getPrebookConfig();
      this.preorderCustomer = this.$store.state.customer.selectedCustomer;
      const preorderResponse = await getPrebook(this.preorderCustomer.id, this.getYear(), this.brand);
      this.preorder = preorderResponse.result?.[0]?.preorder || {};
      this.storedpreorder = { ...this.preorder };
      this.status = preorderResponse.result?.[0]?.preorder_status || 0;
      this.prev_status = preorderResponse.result?.[0]?.previous_status;
      this.openToCustomer = preorderResponse.result?.[0]?.open_to_customer || false;

      this.fetchItemsController = new AbortController();
      const itemResponse = await getPrebookItems(this.$store.state.language.currentApi, this.preorderCustomer.id, this.brandLabel, this.fetchItemsController.signal);
      this.preorderitems = itemResponse.result;

      if (this.validatePrebook()) {
        const ltmResponse = await getLTM(this.preorderCustomer.id, this.brand, this.$store.state.language.currentApi);
        const ltm = ltmResponse.result;
        const ltmTotal = this.getHistoryTotals(ltm);
        const historyResponse = await getHistory(this.preorderCustomer.id, this.getHistoryYear(), this.brand, this.$store.state.language.currentApi);
        const yearHistory = historyResponse.result;
        const yearHistoryTotal = this.getHistoryTotals(yearHistory);
        this.history = ltmTotal.price > yearHistoryTotal.price ? ltm : yearHistory;
        this.historyTotals = ltmTotal.price > yearHistoryTotal.price ? ltmTotal : yearHistoryTotal;
        this.historyYear = ltmTotal.price > yearHistoryTotal.price ? 'LTM' : this.getHistoryYear();
        this.initCollapsedState();
        this.calculateTotals();

        this.exportOptions = await getPrebookExports(this.preorderCustomer.id, this.getYear(), this.brand);
      }
      this.loading = false;
    },
    validatePrebook() {
      this.preorderitems.forEach((brand) => {
        brand.ranges.forEach((range) => {
          range.models.forEach((model) => {
            model.groupsets.forEach((groupset) => {
              groupset.designs.forEach((design) => {
                design.items.forEach((item) => {
                  this.itemsByExternalId[item.externalid] = item;
                });
              });
            });
          });
        });
      });
      // filter empty entries
      Object.keys(this.preorder).forEach((key) => {
        if (this.preorder[key].reduce((total, amount) => total + amount, 0) === 0) {
          delete this.preorder[key];
        }
      });
      // find items in the preorder that are no longer available as preorder item
      Object.keys(this.preorder).forEach((key) => {
        if (!this.itemsByExternalId[key]) {
          this.missingItems.push(key);
        }
      });
      return this.missingItems.length === 0;
    },
    exportPrebook(type) {
      if (this.exportOptions[type]) {
        window.open(this.exportOptions[type], '_blank');
      }
    },
    isElectric(model) {
      return this.brand === 'orion';
      // return model.electric === 'Yes';
    },
    userCanViewItem(item) {
      return item.minusertype ? item.minusertype <= this.user.type : this.user.type > 1;
    },
    fixMissingItems() {
      this.missingItems.forEach((item) => {
        delete this.preorder[item];
        this.hasChanges = true;
      });

      window.location.reload();
      // this.saveToDB(Reason.PageClose);
      // this.initialize();
    },
    formatDate,
    isConfidential(confidentialDate) {
      if (!confidentialDate) return false;
      const now = new Date().getTime();
      const until = new Date(confidentialDate).getTime();
      return until > now;
    },
    canOrder(item, month) {
      // no confidential date, preorder until end of May
      if (!item.confidential_until) return month < 5;

      const launchDate = new Date(item.confidential_until);
      // const launchDate = new Date('2025-05-05');
      const preorderStart = new Date(`${this.getYear()}-01-01`);

      // launch date before preorder start, preorder until end of May
      if (launchDate.getTime() < preorderStart.getTime()) return month < 5;

      // launch date far away in the future
      if (launchDate.getYear() > this.getYear()) return false;

      // launch date until end of May or if later, the launch month + 2 additional months.
      return launchDate.getMonth() <= month && month < Math.max(launchDate.getMonth() + this.maxOrderTimeframe, 5);
    },
    initCollapsedState() {
      const modelDefault = false;
      const groupsetDefault = true;
      if (!this.preorderitems) return;
      const collapsedState = {};
      this.preorderitems.forEach((brand) => {
        brand.ranges.forEach((range) => {
          range.models.forEach((model) => {
            collapsedState[model.model] = { collapsed: modelDefault };
            model.groupsets.forEach((groupset) => {
              collapsedState[model.model][groupset.groupset] = { collapsed: groupsetDefault };
            });
          });
        });
      });
      this.collapsedState = collapsedState;
    },
    collapseGroupsets() {
      if (!this.preorderitems) return;
      this.preorderitems.forEach((brand, brandIndex) => {
        if (brandIndex === this.activeBrand) {
          brand.ranges.forEach((range, rangeIndex) => {
            if (rangeIndex === this.activeRange) {
              range.models.forEach((model) => {
                this.collapsedState[model.model].collapsed = false;
                model.groupsets.forEach((groupset) => {
                  this.collapsedState[model.model][groupset.groupset].collapsed = true;
                });
              });
            }
          });
        }
      });
    },
    collapseAll() {
      if (!this.preorderitems) return;
      this.preorderitems.forEach((brand, brandIndex) => {
        if (brandIndex === this.activeBrand) {
          brand.ranges.forEach((range, rangeIndex) => {
            if (rangeIndex === this.activeRange) {
              range.models.forEach((model) => {
                this.collapsedState[model.model].collapsed = true;
                model.groupsets.forEach((groupset) => {
                  this.collapsedState[model.model][groupset.groupset].collapsed = true;
                });
              });
            }
          });
        }
      });
    },
    expandAll() {
      if (!this.preorderitems) return;
      this.preorderitems.forEach((brand, brandIndex) => {
        if (brandIndex === this.activeBrand) {
          brand.ranges.forEach((range, rangeIndex) => {
            if (rangeIndex === this.activeRange) {
              range.models.forEach((model) => {
                this.collapsedState[model.model].collapsed = false;
                model.groupsets.forEach((groupset) => {
                  this.collapsedState[model.model][groupset.groupset].collapsed = false;
                });
              });
            }
          });
        }
      });
    },
    selectBrand(brandindex) {
      this.activeBrand = brandindex;
      this.activeRange = 0;
      const smoothBehavior = window.scrollY < 142 ? 'smooth' : 'instant';
      window.scroll({ top: 142, behavior: smoothBehavior });
    },
    selectRange(rangeindex) {
      this.activeRange = rangeindex;
      const smoothBehavior = window.scrollY < 142 ? 'smooth' : 'instant';
      window.scroll({ top: 142, behavior: smoothBehavior });
    },
    getYear() {
      return this.config?.year;
    },
    getHistoryYear() {
      return this.config?.history_year;
    },
    getMonthName(index) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(index);
      const result = date.toLocaleDateString(this.$store.state.language.current, { month: 'short' });
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    formatInfo(info) {
      if (!info) return '';
      if (Array.isArray(info)) {
        if (info[0]) return info[0].split('//')[0].trim();
        if (info[1]) return info[1].split('//')[0].trim();
        return '';
      }
      return info.split('//')[0].trim();
    },
    formatCollectionColorType(type) {
      switch (type) {
        case 'Lifecycle Collection Color': return 'LCC';
        case 'Dynamic Collection Color': return 'DCC';
        case 'Unique Collection Color': return 'UCC';
        default: return 'Unknown';
      }
    },
    calculateTotals() {
      this.totals = this.getTotal();
      this.paktotals = this.getTotal(true);
      this.preorderitems.forEach((brand) => {
        this.totals[brand.brand] = { ...this.getTotalsForBrand(brand) };
        brand.ranges.forEach((range) => {
          this.totals[brand.brand][range.range] = { ...this.getTotalsForRange(range) };
          range.models.forEach((model) => {
            this.totals[brand.brand][range.range][model.model] = { ...this.getTotalsForModel(model) };
            model.groupsets.forEach((groupset) => {
              this.totals[brand.brand][range.range][model.model][groupset.groupset] = { ...this.getTotalsForGroupset(groupset) };
              groupset.designs.forEach((design) => {
                this.totals[brand.brand][range.range][model.model][groupset.groupset][design.design] = { ...this.getTotalsForDesign(design) };
              });
            });
          });
        });
      });
      this.totalsPerMonth = this.getTotalsPerMonth();
      this.totalsPerLevel = this.getTotalsPerLevel();
      this.totalsPerLevelFirstMonth = this.getTotalsPerLevelFirstMonth();
      this.totalsPerModelFirstMonth = this.getTotalsPerModelFirstMonth();
    },
    getTotalsPerLevel() {
      const result = {};
      for (const itemid of Object.keys(this.preorder)) {
        const itemLevel = this.itemsByExternalId[itemid].niveau?.split(' ')[0] || 'Unknown';
        const sum = this.preorder[itemid].reduce((total, qty) => total + qty, 0);
        result[itemLevel] = (result[itemLevel] || 0) + sum;
      }
      return result;
    },
    getTotalsPerLevelFirstMonth() {
      const result = {};
      for (const itemid of Object.keys(this.preorder)) {
        const itemLevel = this.itemsByExternalId[itemid].niveau?.split(' ')[0] || 'Unknown';
        result[itemLevel] = (result[itemLevel] || 0) + this.preorder[itemid][0];
      }
      return result;
    },
    getTotalsPerModelFirstMonth() {
      const result = {};
      for (const itemid of Object.keys(this.preorder)) {
        const { model } = this.itemsByExternalId[itemid];
        result[model] = (result[model] || 0) + this.preorder[itemid][0];
      }
      return result;
    },
    getTotalsPerMonth() {
      const monthTotals = new Array(12).fill({ qty: 0, price: 0 });

      for (const itemid of Object.keys(this.preorder)) {
        for (let i = 0; i < 12; i++) {
          const newQty = monthTotals[i].qty + this.preorder[itemid][i];
          monthTotals[i] = {
            qty: newQty,
            price: monthTotals[i].price + this.preorder[itemid][i] * Number(this.itemsByExternalId[itemid].$$price.price),
          };
        }
      }
      return monthTotals;
    },
    getTotal(pak = false) {
      return this.preorderitems.reduce((total, child) => {
        const childTotal = this.getTotalsForBrand(child, pak);
        return {
          qty: total.qty + childTotal.qty,
          msrp: total.msrp + childTotal.msrp,
          price: total.price + childTotal.price,
          currency: this.currency,
        };
      }, {
        qty: 0, price: 0, msrp: 0,
      });
    },
    getTotalsForBrand(brand, pak = false) {
      return brand.ranges.reduce((total, child) => {
        const childTotal = this.getTotalsForRange(child, pak);
        return {
          qty: total.qty + childTotal.qty,
          price: total.price + childTotal.price,
          msrp: total.msrp + childTotal.msrp,
          currency: this.currency,
        };
      }, {
        qty: 0, price: 0, msrp: 0,
      });
    },
    getTotalsForRange(range, pak = false) {
      return range.models.reduce((total, child) => {
        const childTotal = this.getTotalsForModel(child, pak);
        return {
          qty: total.qty + childTotal.qty,
          price: total.price + childTotal.price,
          msrp: total.msrp + childTotal.msrp,
          currency: this.currency,
        };
      }, {
        qty: 0, price: 0, msrp: 0,
      });
    },
    getTotalsForModel(model, pak = false) {
      const totalsForModel = model.groupsets.reduce((total, child) => {
        const childTotal = this.getTotalsForGroupset(child, pak);
        return {
          qty: total.qty + childTotal.qty,
          price: total.price + childTotal.price,
          msrp: total.msrp + childTotal.msrp,
          currency: this.currency,
        };
      }, { qty: 0, price: 0, msrp: 0 });
      return totalsForModel;
    },
    getTotalsForGroupset(groupset, pak = false) {
      return groupset.designs.reduce((total, child) => {
        const childTotal = this.getTotalsForDesign(child, pak);
        return {
          qty: total.qty + childTotal.qty,
          price: total.price + childTotal.price,
          msrp: total.msrp + childTotal.msrp,
          currency: this.currency,
        };
      }, { qty: 0, price: 0, msrp: 0 });
    },
    getTotalsForDesign(design, pak = false) {
      const { items } = design;
      const total = items.reduce((itemsTotal, item) => {
        const preorder = this.preorder[item.externalid];
        if (!preorder || preorder.length === 0) return itemsTotal;
        return itemsTotal + preorder.reduce((itemTotal, monthValue, index) => {
          if (pak && index > 4) return itemTotal;
          return itemTotal + monthValue;
        }, 0);
      }, 0);
      return {
        qty: total, price: total * design.$$price.price, msrp: total * design.$$price.msrp, currency: design.$$price.currency,
      };
    },
    getHistoryTotals(data) {
      return data?.reduce((total, entry) => ({ qty: total.qty + Number(entry.qty), price: total.price + Number(entry.price) }), { qty: 0, price: 0 });
    },
    getHistoryForModel(model) {
      return this.history.find((entry) => (entry.model === model)) || { qty: 0, price: 0 };
    },
    getHistoryModelsOrdered(range) {
      // show phaseout models last.
      const { models } = this.historyByRange[range];
      const result = Object.keys(models).map((model) => ({
        model,
        ...models[model],
      }));
      result.sort((a, b) => {
        if (a.phaseout && !b.phaseout) return 1;
        if (!a.phaseout && b.phaseout) return -1;
        return 0;
      });
      return result;
    },
    isPhaseOutModel(model) {
      return !this.allModels.includes(model);
    },
    handleInput(item, month, qty) {
      const itemPreorder = {};
      itemPreorder[item] = this.preorder[item] || new Array(12).fill(0);
      itemPreorder[item][month] = Number(qty);
      this.preorder = { ...this.preorder, ...itemPreorder };
      this.hasChanges = true;
      this.calculateTotals();
    },
    updateStatus(status, remarks) {
      this.remarks = remarks;
      this.prev_status = this.status;
      this.status = status;
      this.hasChanges = true;
      this.saveToDB(Reason.StatusChanged);
    },
    sendToCustomer() {
      this.remarks = undefined;
      this.prev_status = this.status;
      this.hasChanges = true;
      if (this.status >= Status.presentation) {
        this.openToCustomer = true;
      }
      this.saveToDB(Reason.SendToCustomer);
    },
    openDesignLightbox(model, groupset, index) {
      this.lightBoxImages = groupset.designs.reduce((imgList, design) => {
        if (design.image) {
          imgList.push({
            key: design.design,
            thumb: design.image.thumb,
            src: design.image.highres,
            caption: `${model.model}: ${design.design}`,
          });
        }
        return imgList;
      }, []);
      this.$refs.lightbox.showImage(index);
    },
    windowClosed() {
      this.saveToDB(Reason.WindowClose);
    },
    printScreenOpened() {
      console.log('Show print div');
      this.printMode = true;
    },
    printScreenClosed() {
      console.log('Hide print div');
      this.printMode = false;
    },
    async saveToDB(reason) {
      if (reason === Reason.PageClose || reason === Reason.WindowClose) {
        clearInterval(this.saveInterval);
      }

      const reasons = ['Interval', 'PageClosed', 'WindowClosed', 'StatusChanged', 'SendToCustomer'];
      console.log(`Autosave: Save preorder (${reasons[reason]})`);

      if (!this.hasChanges) {
        console.log(' > No changes.');
        return;
      }
      this.storedpreorder = this.preorder;
      this.hasChanges = false;

      // if status is returned to anything lower than presentation, close it to the customer.
      if (this.openToCustomer && this.status < Status.presentation) {
        this.openToCustomer = false;
      }

      savePrebook(this.preorderCustomer.id, this.config.year, this.status, this.prev_status, this.preorder, reason, this.remarks, this.brand, this.openToCustomer);
    },
    async confirmDraftReview(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Submit preparation for review',
          htmlContent: '<h4>Are you sure you want to send the season start planning preparation to your country manager for review?</h4><br><ul><li>The form will be locked until it is reviewed</li><li>A notification will be sent to the country manager</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmDraftReject(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Reject preparation',
          htmlContent: '<h4>Are you sure you want to reject the season start planning preparation?</h4><br><ul><li>A notification will be sent to the sales representative</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmDraftApprove(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Approve preparation',
          htmlContent: '<h4>Are you sure you want to approve the season start planning preparation?</h4><br><ul><li>A notification will be sent to the sales representative</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmOrderReview(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Submit SSP for final review',
          htmlContent: '<h4>Are you sure you want to send the season start planning to your country manager for review?</h4><br><ul><li>The form will be locked until it is reviewed</li><li>A notification will be sent to the country manager</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmSendToCustomer() {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Share SSP with IBD',
          htmlContent: '<h4>Are you sure you want to share the season start planning with the IBD?</h4><br><ul><li>From this point the IBD will be able to view the SSP in the B2B portal</li></li><li>The IBD will not be able to make changes</li></ul>',
          showRemarks: false,
        },
      });
      if (confirmation) {
        this.sendToCustomer();
      }
    },
    async confirmOrderReject(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Reject SSP',
          htmlContent: '<h4>Are you sure you want to reject the season start planning?</h4><br><ul><li>A notification will be sent to the sales representative</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmOrderApprove(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Approve SSP',
          htmlContent: '<h4>Are you sure you want to approve the season start planning?</h4><br><ul><li>A notification will be sent to the sales representative</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmUnapprove(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Unapprove SSP',
          htmlContent: '<h4>Are you sure you want to unapprove the season start planning?</h4><br><ul><li>This will update the status back to `Draft`</li><li>A notification will be sent to the sales representative</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async confirmAddToCart(status) {
      const confirmed = await dialogs.show({
        title: 'Add SSP to cart',
        htmlContent: '<h4>Are you sure you want to add all season start planning bikes in the shopping cart?</h4><br><h5><b>Note: This will only add them to the cart. They are not ordered yet!</b></h5><br><ul><li>The form will be locked for further editing.</li><li>A notification will be sent to the sales representative</li></ul>',
        type: 'cancelYes',
      });
      if (confirmed) {
        this.updateStatus(status);
      }
    },
    async confirmReopen(status) {
      const confirmation = await dialogs.show({
        component: RemarksDialog,
        props: {
          title: 'Reopen SSP',
          htmlContent: '<h4>Are you sure you want to reopen the season start planning?</h4><br><ul><li>This will update the status back to `Draft`</li></ul>',
        },
      });
      if (confirmation) {
        this.updateStatus(status, confirmation.remarks);
      }
    },
    async orderBikes() {
      // go to checkout
      this.loading = true;
      await this.$store.dispatch('prebook/clear');
      const promises = [];
      Object.keys(this.preorder).forEach((externalId) => {
        const monthQuantities = this.preorder[externalId];
        monthQuantities.forEach((qty, month) => {
          const date = new Date(this.getYear(), month, 1);
          const withoutTimeZone = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
          if (qty > 0) {
            promises.push(this.$store.dispatch('prebook/addProductToPrebook', {
              product: this.itemsByExternalId[externalId],
              qty,
              deliveryDateType: 'SELECTED',
              deliveryDate: withoutTimeZone,
            }));
          }
        });
      });
      await Promise.all(promises);
      await this.$store.dispatch('prebook/setPrebookMetadata', {
        preorderCustomerId: this.preorderCustomer.id,
        brand: this.brand,
        year: this.config.year,
        status: this.status,
        preorder: this.preorder,
      });
      this.loading = false;
      this.$router.push({ name: 'prebook_checkout', params: { brand: this.$route.params.brand } });
    },
  },
};

</script>
