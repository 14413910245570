<!--
 DialogHost serves as the place to actually inject the Dialogs into
 Only one of these should ever exist, and it is currently injected on Frame.vue level
-->
<script>

import Vue from 'vue';
import dialogs from '@/utils/dialogs';
import BcfDialogOverlay from '@/components/dialog/bcfDialogOverlay.vue';

import { shallowIsEqual } from '@/utils/object';

export default Vue.component('bcf-dialog-host', {
  data() {
    return {
      dialogs: [],
      newInstances: [],
      scrollBlock: { pos: null, handler: null, element: null },
    };
  },
  computed: {
    topDialog() {
      return this.dialogs[this.dialogs.length - 1];
    },
  },
  created() {
    dialogs.setHost(this);// A bit dirty to have the component register itself this way (we don't accidentally want two of these)

    document.onkeydown = (evt) => {
      evt = evt || window.event;
      const lastDialog = this.dialogs[this.dialogs.length - 1];
      if (evt.keyCode === 27 && lastDialog && lastDialog.overlay) {
        lastDialog.overlay.close(undefined, 'dismiss'); // Because it is near equal to click-outside which uses dismiss (do we ever need to tell the difference?)
      }
    };
  },
  updated() {
    if (this.newInstances.length) {
      const unresolved = [];
      const resolved = [];
      for (const newDialog of this.newInstances) {
        const instance = newDialog.instance || this.$refs[newDialog.definition.id];
        const overlay = newDialog.overlay || this.$refs[`overlay_${newDialog.definition.id}`];
        if (!instance || !overlay) {
          unresolved.push(newDialog);
        } else {
          newDialog.instance = instance;
          newDialog.overlay = overlay;
          resolved.push(newDialog);
        }
      }

      for (const d of resolved) {
        const {
          overlay,
          lifecycle,
        } = d;
        const {
          resolve,
          reject,
        } = lifecycle;
        overlay.show()
          .then((result) => {
            try {
              resolve(result);
            } finally {
              this.dialogs = this.dialogs.filter((x) => x !== d);
            }
          })
          .catch((err) => {
            try {
              reject(err);
            } finally {
              this.dialogs = this.dialogs.filter((x) => x !== d);
            }
          });
      }

      if (this.newInstances.length > unresolved.length) {
        this.newInstances = unresolved;
      }
    }
  },
  methods: {
    /**
     * Not meant to be used manually! (refer to dialogService)
     */
    showDialogs(newDialogs) {
      const added = newDialogs.map((d) => {
        const instance = {
          definition: d,
          vnode: null,
        };
        // This resolve/reject/promise bit feels hacky (and the double resolve does not really seem necessary?)
        let resolve;
        let reject;
        instance.promise = new Promise((res, rej) => {
          resolve = (result) => {
            try {
              res(result);// Returns to the dialogService
              d.resolve(result); // Returns to the caller
            } finally {
              this.dialogs = this.dialogs.filter((x) => x !== instance);
            }
          };
          reject = (err) => {
            try {
              rej(err);
              d.reject(err);
            } finally {
              this.dialogs = this.dialogs.filter((x) => x !== instance);
            }
          };
        });
        instance.lifecycle = { resolve, reject };
        return instance;
      });
      this.dialogs = [...this.dialogs, ...added];
      return added.map((a) => a.promise);
    },
    onClickOutside(event, sourceDialog) {
      if (sourceDialog.overlay && sourceDialog === this.topDialog) {
        sourceDialog.overlay.close(undefined, 'dismiss');
      }
    },
  },
  render(createElement) {
    // Intentionally not replacing the data-properties themselves (no need to trigger change detection)
    for (const dialog of this.dialogs) {
      const { definition } = dialog;
      const index = this.dialogs.indexOf(dialog);// Hmmh
      const newOverlayProps = {
        isTopLevel: index === (this.dialogs.length - 1),
      };
      if (!dialog.vnode || shallowIsEqual(newOverlayProps, dialog.overlayProps || {})) {
        dialog.overlayProps = newOverlayProps;
        if (!dialog.vnode) {
          this.newInstances.push(dialog);
        }
        dialog.vnode = createElement(
          BcfDialogOverlay,
          {
            key: `overlay_${definition.id}`,
            ref: `overlay_${definition.id}`,
            props: newOverlayProps,
            scopedSlots: {
              default: (overlayControls) => createElement(
                definition.component,
                {
                  props: {
                    ...definition.props,
                    overlay: overlayControls,
                  },
                  key: definition.id,
                  ref: definition.id,
                },
              ),
            },
            on: {
              clickOutside: (event) => this.onClickOutside(event, dialog),
            },
          },
        );
      }
    }

    // Prevent scrolling when a dialog is open
    // Option A, css (hides the scrollbars when an overlay is open)
    if (this.dialogs.length > 0) {
      document.body.classList.add('body__overlay-open');
    } else {
      document.body.classList.remove('body__overlay-open');
    }
    // Option B, JS (forces position back to what it was upon scrolling, jittery)
    // if (this.scrollBlock?.handler) {
    //   document.removeEventListener('scroll', this.scrollBlock.handler);
    //   this.scrollBlock = {};
    // }
    // const scrollEl = document.documentElement;
    // if (this.dialogs.length > 0) {
    //   const newScrollBlock = {
    //     element: scrollEl,
    //     pos: {
    //       top: scrollEl.scrollTop,
    //       left: scrollEl.scrollLeft,
    //     },
    //     handler: (ev) => {
    //       scrollEl.scrollTo(newScrollBlock.pos.left, newScrollBlock.pos.top);// force-set old position
    //     },
    //   };
    //   document.addEventListener('scroll', newScrollBlock.handler);
    //   this.scrollBlock = newScrollBlock;
    // }

    return createElement(
      'div',
      {
        class: [
          'dialogs-host',
          this.dialogs.length > 0 ? 'dialogs-host--active' : null,
        ].filter((x) => x).join(' '),
        on: {
        },
      },
      this.dialogs.map((d) => d.vnode),
    );
  },
});

</script>
