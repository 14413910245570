<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>{{ $t("account.account.myCustomers.firstName") }}</label>
          <b-form-input
            v-model="model.firstName"
            :autocomplete="blockAutocomplete"
            autofocus
            class="form-control"
            type="text"
            :state="validationState('firstName')"
            @input="onInput('firstName')"
            @blur="markTouched('firstName')"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>{{ $t("account.account.myCustomers.lastName") }}</label>
          <b-form-input
            v-model="model.lastName"
            :autocomplete="blockAutocomplete"
            class="form-control"
            type="text"
            :state="validationState('lastName')"
            @input="onInput('lastName')"
            @blur="markTouched('lastName')"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>{{ $t("account.account.myCustomers.email") }}</label>
      <b-form-input
        v-model="model.email"
        :autocomplete="blockAutocomplete"
        class="form-control"
        type="text"
        :state="validationState('email')"
        @input="onInput('email')"
        @blur="markTouched('email')"
      />
    </div>

    <div class="form-group">
      <label>{{ $t("account.account.myCustomers.phone") }}</label>
      <b-form-input
        v-model="model.phone"
        :autocomplete="blockAutocomplete"
        class="form-control"
        type="text"
        :state="validationState('phone')"
        @input="onInput('phone')"
        @blur="markTouched('phone')"
      />
    </div>

    <div class="form-group">
      <label>{{ $t('account.settings.edit.languageChooser') }}</label>
      <select
        v-model="model.language"
        @change="onInput('language')"
        @blur="markTouched('language')"
      >
        <option
          v-for="lang in availableLanguages"
          :key="lang"
          :value="lang"
        >
          {{ $t(`language.${lang}`) }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import translations from '@/translations';
import { required, email } from 'vuelidate/lib/validators';

export default {
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errorMessage: null,
      model: { },
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
    }),
    availableLanguages: () => Object.keys(translations),
    blockAutocomplete: () => '-1', // The correct value would be "off", but that does not work for Chrome
    lang() {
      return translations[this.$store.state.language.current].Datepicker;
    },
  },
  watch: {
    value() {
      this.valueToModel();
    },
  },
  validations() {
    return {
      model: {
        relationId: {},
        email: {
          email,
          required,
        },
        firstName: {},
        lastName: {},
        note: {},
      },
    };
  },
  mounted() {
    this.valueToModel();
  },
  methods: {
    valueToModel(apply = true) {
      const { value } = this;
      const newValue = {
        id: value?.id,
        firstName: value?.firstName,
        lastName: value?.lastName,
        email: value?.email,
        phone: value?.phone,
        language: value?.language ?? this.language,
      };
      if (apply) {
        this.model = newValue;
      }
      return newValue;
    },
    onInput(field) {
      this.$emit('input', this.model);
      this.$emit('valid', this.validationState('email'));
      this.markTouched(field);
    },
    markTouched(field) {
      const modelState = this.$v.model;
      const state = modelState && modelState[field];
      if (state) {
        state.$touch();
      }
    },
    validationState(field) {
      const modelState = this.$v.model;
      const state = modelState[field];
      if (!state || ([null, undefined, ''].includes(state.$model))) {
        return null;
      }
      if (state && (state.$error || state.$invalid)) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
