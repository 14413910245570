<template>
  <div class="main">
    <main class="main__inner">
      <div class="container">
        <div class="row m-b-4">
          <div class="col">
            <div class="section-title">
              Buttons
            </div>

            <a
              href="#"
              class="button button--primary"
            >Primary button</a>
            <span class="button button--primary">Primary button</span>
            <button class="button button--danger">
              Primary button
            </button>
            <button class="button button--secondary">
              Secondary button
            </button>
            <button class="button button--outline">
              Outline button
            </button>

            <a
              disabled
              href="#"
              class="button button--primary"
            >Primary button</a>
            <button
              disabled
              class="button button--secondary"
            >
              Disabled button
            </button>
            <button
              disabled
              class="button button--primary"
            >
              Disabled button
            </button>

            <span class="button button--primary button--icon">
              <i class="uil uil-check" />
            </span>

            <span class="button button--small">
              <i class="uil uil-check" />
            </span>

            <span class="button button--small button--danger">Small button</span>

            <span class="button button--small">
              <i class="uil uil-check" /> Download
            </span>
          </div>
        </div>

        <div class="row m-b-4">
          <div class="col">
            <div class="section-title">
              Forms
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="checkbox" />
                Checkbox unchecked
              </label>
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="checkbox checked" />
                Checkbox checked
              </label>
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="radiobutton" />
                Radiobutton unchecked
              </label>
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="radiobutton checked" />
                Radiobutton checked
              </label>
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="toggle" />
                Toggle off
              </label>
            </div>

            <div class="form-group form-group--checkbox">
              <label>
                <span class="toggle active" />
                Toggle on
              </label>
            </div>

            <div class="form-group">
              <label>Username</label>
              <input
                type="text"
                placeholder="Username"
              >
              <small class="form-text--error">This was going wrong...</small>
              <small class="form-text--warning">This was going kind of ok...</small>
              <small class="form-text--success">This was going great...</small>
            </div>

            <div class="form-group form-group--compact">
              <label>Username</label>
              <input
                type="text"
                placeholder="Username"
              >
              <small class="form-text--error">This was going wrong...</small>
              <small class="form-text--warning">This was going kind of ok...</small>
              <small class="form-text--success">This was going great...</small>
            </div>
          </div>
        </div>

        <div class="row m-b-4">
          <div class="col">
            <div class="section-title">
              Messages
            </div>

            <Message
              type="warning"
              :icon="true"
              content="A small description comes here"
              :show-close="true"
            >
              <strong>Pas op!</strong> We waarschuwen je.
            </Message>

            <Message
              type="warning"
              :icon="true"
              :show-close="true"
            >
              <strong>Pas op!</strong> We waarschuwen je.
            </Message>

            <Message
              type="warning"
              :icon="false"
              :show-close="true"
            >
              <strong>Pas op!</strong> We waarschuwen je.
            </Message>

            <Message
              type="success"
              :icon="false"
              :show-close="false"
            >
              <strong>Pas op!</strong> Dit is een success message.
            </Message>

            <Message
              type="error"
              :icon="false"
              :show-close="false"
            >
              <strong>Pas op!</strong> Dit is een danger message.
            </Message>

            <Message
              type="promo"
              :icon="false"
              :show-close="false"
            >
              <strong>Pas op!</strong> Dit is een promo message.
            </Message>

            <Message
              type="update"
              :icon="false"
              :show-close="false"
            >
              <strong>Pas op!</strong> Dit is een update message.
            </Message>
          </div>
        </div>

        <div class="row m-b-4">
          <div class="col">
            <div class="section-title">
              Emptystates
            </div>

            <div class="row">
              <div class="col-6">
                <EmptyState>Geen resultaten</EmptyState>
              </div>
              <div class="col-6">
                <EmptyState icon="shop">
                  Custom icon gevonden
                </EmptyState>
              </div>
            </div>
          </div>
        </div>

        <div class="row m-b-4">
          <div class="col">
            <div class="section-title">
              List items
            </div>

            <ListItem>Hello world</ListItem>

            <ListItem icon-before>
              <i class="uil uil-store" />
              Hello world
            </ListItem>

            <ListItem
              icon-before
              arrow
            >
              <i class="uil uil-store" />
              Hello world
            </ListItem>

            <ListItem icon-after>
              Hello world
              <i class="uil uil-store" />
            </ListItem>

            <ListItemLocation>
              <strong>Location name</strong>
              <small>Location address</small>
            </ListItemLocation>

            <ListItemLocation selected>
              <strong>Location name</strong>
              <small>Location address</small>
            </ListItemLocation>

            <ListItemAccount>
              <strong>User name</strong>
              <small>User address</small>
            </ListItemAccount>

            <ListItemAccount selected>
              <strong>User name</strong>
              <small>User address</small>
            </ListItemAccount>
          </div>
        </div>
      </div>

      <!-- Progress component example -->
      <Progress
        :value="24"
        type="huge"
      />
      <Progress
        :value="50"
        type="big"
      />
      <Progress :value="76" />
      <Progress />
      <!-- / Progress component example -->
    </main>
  </div>
</template>

<style scoped>
</style>

<script>
import Progress from '@/elements/Progress.vue';
import Message from '@/components/Message.vue';
import EmptyState from '@/elements/EmptyState.vue';
import ListItem from '@/elements/ListItem.vue';
import ListItemLocation from '@/elements/ListItemLocation.vue';
import ListItemAccount from '@/elements/ListItemAccount.vue';

export default {
  name: 'Main',
  components: {
    Progress,
    Message,
    EmptyState,
    ListItem,
    ListItemAccount,
    ListItemLocation,
  },
};
</script>
