<template>
  <div :class="'message--'+type">
    <span
      v-if="icon"
      class="message__icon"
    >
      <img :src="require('../assets/img/icons/icon-'+type+'.svg')">
    </span>
    <span class="message__content">
      <span>
        <slot />
      </span>
      <small
        v-if="htmlContent"
        v-html="htmlContent"
      />
      <small v-else-if="content">{{ content }}</small>
    </span>
    <i
      v-if="showClose"
      class="uil uil-times message__close"
      @click="$emit('closeMessage')"
    />
  </div>
</template>

<!--

Types:
- success
- warning
- update
- error
- promo
-->

<script>
export default {
  name: 'Message',
  props: {
    title: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    htmlContent: {
      type: String,
      default: null,
    },
    showClose: Boolean,
    icon: Boolean,
  },
  computed: {},
  methods: {
  },
};
</script>

<style scoped>
</style>
