<template>
  <div class="cart-view cart-overview">
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>{{ $t("header.cart") }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <loading-overlay :loading="loading">
      <div
        v-if="error"
        class="container"
      >
        <Message
          class="m-t-2"
          type="error"
          :icon="false"
          :show-close="false"
        >
          {{ error }}
        </Message>
      </div>
      <div v-else>
        <div
          v-if="cartProducts.length > 0"
          class="container"
        >
          <div class="row">
            <div class="col">
              <div class="steps-progress">
                <div class="steps-progress__item steps-progress__item--active">
                  1
                </div>
                <div class="steps-progress__item">
                  2
                </div>
                <div class="steps-progress__item">
                  <i class="uil uil-check" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="cartProducts.length === 0"
          class="container m-t-4"
        >
          <EmptyState icon="shopping-cart">
            {{ $t('cart.empty.title') }}
            <span class="button button--danger">
              <router-link :to="{name: 'items'}">
                {{ $t('cart.empty.button') }}
              </router-link>
            </span>
          </EmptyState>
        </div>
        <div
          v-else
          class="container m-t-4"
        >
          <router-view />

          <div class="row">
            <div class="col-lg-9 m-b-4">
              <cart-order-list />
            </div>

            <div class="col-lg-3 m-b-4">
              <div v-if="isPreviewMode & true===false">
                <SectionTitle>Promocode</SectionTitle>

                <div class="d-flex space-between m-b-1">
                  <input
                    type="text"
                    placeholder="Promocode"
                    class="w-100 m-r-1"
                  >
                  <button class="button button--secondary button--icon">
                    <i class="uil uil-check" />
                  </button>
                </div>

                <table class="table table--striped m-b-4">
                  <tr>
                    <td>
                      Code:
                      <strong>BLACKFRIDAY</strong>
                    </td>
                    <td
                      class="nowrap"
                      width="1"
                    >
                      - €85.00
                    </td>
                    <td width="1">
                      <span class="button button--small button--icon">
                        <i class="uil uil-trash color--red" />
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Code:
                      <strong>CYBERMONDAY</strong>
                    </td>
                    <td
                      class="nowrap"
                      width="1"
                    >
                      - €45.00
                    </td>
                    <td width="1">
                      <span class="button button--small button--icon">
                        <i class="uil uil-trash color--red" />
                      </span>
                    </td>
                  </tr>
                </table>
              </div>

              <SectionTitle>{{ $t('cart.prices') }}</SectionTitle>

              <cart-prices-table :generated="generated" />
              <div
                v-if="!showRetailPrices && (balanceExceedsCreditlimit && checkProhibitedActions === 'All-Finance') && isPreviewMode"
              >
                <myBalanceStatus class="m-t-4" />
              </div>
              <div v-if="!showRetailPrices && (!balanceExceedsCreditlimit && checkProhibitedActions === 'All-Finance') && isPreviewMode">
                <Message
                  type="error"
                  :icon="true"
                  :content="$t('account.account.finance.blockedmessage')"
                  :show-close="false"
                >
                  <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.finance.blockedtitle') }}
                </Message>
              </div>
              <div v-if="!showRetailPrices && (!balanceExceedsCreditlimit && checkProhibitedActions === 'All-OAM') && isPreviewMode">
                <Message
                  type="error"
                  :icon="true"
                  :content="$t('account.account.oam.shippingholdmessage')"
                  :show-close="false"
                >
                  <strong>{{ $t('account.account.myBalance.messagetitle1') }}</strong> {{ $t('account.account.oam.shippingholdtitle') }}
                </Message>
              </div>

              <!-- Use below disabled line to activate RTS -->
              <!-- :disabled="!isValid || balanceExceedsCreditlimit" -->
              <!-- :disabled="!isValid" -->
              <button
                v-if="cartProducts.length > 0"
                class="button button--danger w-100"
                :disabled="!isValid || checkProhibitedActions === 'All-Finance'"
                @click="confirmCart"
              >
                {{ $t('cart.nextStep') }}
              </button>
              <div
                v-if="hasUnassignedBikes"
                class="unassignedbikes__notice"
              >
                <span v-html="$t('cart.unassignedbikes')" />
                <usps
                  :usps="assignUsps"
                  style="width: 100%; margin: 0; font-size: 0.9rem;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </loading-overlay>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';
import EmptyState from '@/elements/EmptyState.vue';
import { availabilityText, searchProhibitedAction } from '@/utils/cartUtils';
import Message from '@/components/Message.vue';
import cartPricesTable from '@/components/cartPricesTable.vue';
import myBalanceStatus from '@/components/myBalanceStatus.vue';
import { formatDate } from '@/utils/dateUtils';
import LoadingOverlay from '@/elements/LoadingOverlay.vue';
import CartOrderList from '@/components/cart/CartOrderList.vue';
import usps from '@/components/usps.vue';

export default {
  components: {
    EmptyState,
    SectionTitle,
    Message,
    cartPricesTable,
    myBalanceStatus,
    LoadingOverlay,
    CartOrderList,
    usps,
  },
  metaInfo() {
    return {
      title: this.$t('header.cart'),
    };
  },
  data() {
    return {
      creditlimit: 0,
    };
  },
  computed: {
    checkProhibitedActions() {
      const objProhibitedActions = searchProhibitedAction(this.selectedCustomer);
      return objProhibitedActions?.value;
    },
    balanceExceedsCreditlimit() {
      return ((this.selectedCustomer.attributes.CreditLimitLCY > 0) && (this.totalBalance > this.creditlimit));
    },
    isCreditlimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    ...mapGetters('cart', {
      cartProducts: 'cartProducts',
      generated: 'cartGenerated',
      loading: 'cartLoading',
      error: 'cartError',
    }),
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
      isPreviewMode: (state) => state.global.isPreviewMode,
      selectedCustomer: (state) => state.customer.selectedCustomer,
    }),
    isValid() {
      return !this.generated.hasErrors;
    },
    totalBalance() {
      const { exchangerate } = this.selectedCustomer.attributes;
      if (this.currency !== 'EUR') {
        return ((this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY) * exchangerate)
          + this.generated.toPay;
      }
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY
          + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay;
    },
    hasUnassignedBikes() {
      if (!this.isPreviewMode) return false;

      let hasUnassignedBikes = false;
      if (!this.generated.groupedOrders) return false;
      this.generated.groupedOrders.forEach((order) => {
        order.lines.forEach((line) => {
          if (!line.orderline?.reference && (line.orderline?.customizer || ['bikes'].includes(line.item.category?.toLowerCase()))) {
            hasUnassignedBikes = true;
          }
        });
      });
      return hasUnassignedBikes;
    },
    assignUsps() {
      return [
        {
          icon: 'award',
          content: this.$t('account.account.myBackOrders.assign.usps.registration'),
        },
        {
          icon: 'clock',
          content: this.$t('account.account.myBackOrders.assign.usps.warranty'),
        },
        {
          icon: 'envelope-info',
          content: this.$t('account.account.myBackOrders.assign.usps.updates'),
        },
      ];
    },
  },
  async  mounted() {
    this.$store.dispatch('cart/generate');
    this.currency = this.selectedCustomer.attributes.currency;
    const { exchangerate } = this.selectedCustomer.attributes;
    if (this.currency !== 'EUR') {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY * exchangerate;
    } else {
      this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
    }
  },
  methods: {
    removeItem(key) {
      // eslint-disable-next-line no-alert
      const r = window.confirm(this.$t('cart.removeConfirmation'));
      if (r === true) {
        this.$store.dispatch('cart/removeItem', key);
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    datePredicted(item) {
      if (!item.item.$$availability) {
        return null;
      } if (
        !item.item.$$availability.find(
          (o) => o.quantity === item.orderline.quantity && o.date,
        )
      ) {
        return null;
      }
      const { date } = item.item.$$availability.find(
        (o) => o.quantity === item.quantity,
      );
      return new Date(date);
    },
    weeksToPredicted(item) {
      return availabilityText(item.item.$$availability, item.orderline.quantity, this.showRetailPrices);
    },
    confirmCart() {
      this.$router.push({ name: 'cartConfirm' });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_colors.scss";
.vdp-datepicker__calendar .cell.selected,
.vdp-datepicker__calendar .cell.selected:hover {
  background: $red;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid $red;
}

.cartLink {
  color: inherit;
  text-decoration: none;
}

.unassignedbikes__notice {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  font-size: 2rem;
}
</style>
