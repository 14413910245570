<template>
  <div>
    <loader />
  </div>
</template>

<script>
import loader from '@/elements/loader.vue';

export default {
  name: 'Reset',
  components: {
    loader,
  },
  created() {
    window.localStorage.removeItem('vuex');
    this.$store.replaceState({});
    window.location.href = '/login';
  },
};
</script>
