<template>
  <header
    class="main__header"
    :style="headerColor ? {'background-color': headerColor} : {}"
  >
    <div class="container">
      <div class="row">
        <div class="col main__header__holder">
          <div class="main__header__logo">
            <router-link :to="{name:'dashboard'}">
              <img
                src="@/assets/img/bcf-logo.svg"
                title="Logo"
                alt="BCF Logo"
              >
            </router-link>
          </div>
          <nav class="main__header__nav">
            <ul>
              <li>
                <router-link :to="{name: 'dashboard'}">
                  <img
                    src="@/assets/img/icons/navbar-dashboard.svg"
                    :title="$t('header.dashboard')"
                    :alt="$t('header.dashboard')"
                  >
                  {{ $t("header.dashboard") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'items'}">
                  <img
                    src="@/assets/img/icons/navbar-products.svg"
                    :title="$t('header.products')"
                    :alt="$t('header.products')"
                  >
                  {{ $t("header.products") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'account'}">
                  <img
                    src="@/assets/img/icons/navbar-account.svg"
                    :title="$t('header.account')"
                    :alt="$t('header.account')"
                  >
                  {{ $t("header.account") }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'cart'}">
                  <img
                    src="@/assets/img/icons/navbar-cart.svg"
                    :title="$t('header.cart')"
                    :alt="$t('header.cart')"
                  >
                  {{ $t("header.cart") }}
                </router-link>
                <Badge v-show="cartTotalItems > 0">
                  {{ cartTotalItems }}
                </Badge>
              </li>

              <li class="notifications">
                <router-link :to="{name: 'notifications'}">
                  <img
                    src="@/assets/img/icons/navbar-notifications.svg"
                    :title="$t('header.notifications')"
                    :alt="$t('header.notifications')"
                  >
                  <span>{{ $t("header.notifications") }}</span>
                </router-link>
                <Badge v-show="notificationCount > 0">
                  {{ notificationCount }}
                </Badge>
              </li>

              <li
                class="account pointer"
                @click="toggleCustomerChange"
              >
                <span>{{ selectedCustomer.name }}
                  <template
                    v-if="!showRetailPrices && balanceExceedsCreditlimit && isCreditlimit && isPreviewMode"
                  >
                    <div @click.stop="goToBalance">
                      <Badge>{{ $t('account.account.myBalance.status') }}</Badge>
                    </div>
                  </template>
                </span>
              </li>
              <li
                class="retailprice"
              >
                <img
                  src="@/assets/img/icons/icon-tag-euro.svg"
                  :title="$t('account.account.showRetailPrices.title')"
                  :alt="$t('account.account.showRetailPrices.title')"
                >
                <span
                  v-b-tooltip.hover.left
                  :title="$t('account.account.showRetailPrices.title')"
                >
                  <toggle v-model="showRetailPrices" />
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <accountChange
      v-if="changeCustomer"
      :close="toggleCustomerChange"
    />
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Badge from '@/elements/Badge.vue';
import toggle from '@/elements/toggle.vue';
import accountChange from '@/views/account/settings/change.vue';

export default {
  components: { Badge, toggle, accountChange },
  data() {
    return {
      changeCustomer: false,
      headerColor: process.env.VUE_APP_HEADER_COLOR,
    };
  },
  computed: {
    balanceExceedsCreditlimit() {
      return this.totalBalance > this.selectedCustomer.attributes.CreditLimitLCY;
    },
    isCreditlimit() {
      return this.selectedCustomer.attributes.CreditLimitLCY > 0;
    },
    totalBalance() {
      return this.selectedCustomer.attributes.BalanceProductionOrdersLCY + this.selectedCustomer.attributes.BalanceOpenInvoicesLCY + this.generated.toPay - 20;
    },
    ...mapGetters('cart', {
      cartTotalItems: 'cartTotalItems',
      generated: 'cartGenerated',
    }),
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
      username: (state) => state.auth.user.username,
      selectedCustomer: (state) => state.customer.selectedCustomer,
      isPreviewMode: (state) => state.global.isPreviewMode,
      notificationCount: (state) => state.notifications.count,
    }),
    showRetailPrices: {
      get() {
        return this.$store.state.items.showRetailPrices;
      },
      set(newVal) {
        this.$store.commit('items/showRetailPrices', newVal);
      },
    },
  },
  mounted() {
    this.creditlimit = this.selectedCustomer.attributes.CreditLimitLCY;
  },
  methods: {
    goToBalance() {
      // eslint-disable-next-line no-alert
      // alert('ok works');
      this.$router.push({ name: 'myBalance' });
    },
    toggleCustomerChange() {
      this.changeCustomer = !this.changeCustomer;
    },
  },
};
</script>
