<template>
  <div>
    <bcf-aside
      v-if="isMobile"
      v-show="mobileFiltersOpen"
    >
      <bcfAsideHeader :close="closeMobileFilters">
        {{ $t('items.filters') }}
      </bcfAsideHeader>
      <bcfAsideContent>
        <div class="products__filter">
          <filterattributes :default-filters="defaultFilters" />
        </div>
      </bcfAsideContent>
      <bcfAsideFooter>
        <span />
        <button
          class="button button--primary"
          @click="closeMobileFilters"
        >
          {{ $t('general.apply') }}
        </button>
      </bcfAsideFooter>
    </bcf-aside>

    <ItemsNav />

    <header class="main__title">
      <div class="container">
        <div class="row">
          <div class="col main__title__inner">
            <router-link
              v-if="isTablet"
              :to="{name: 'items'}"
              class="main__title__action--left"
            >
              <i class="uil uil-arrow-left" />
            </router-link>
            <h1>
              {{ $t(title) }} <span v-if="!inProgress">(<counter />)</span>
            </h1>
            <a
              v-if="excel"
              class="main__title__action--right"
              :href="excel"
              target="_blank"
            >
              <i class="uil uil-cloud-download" />
            </a>

            <span
              v-if="isMobile"
              class="main__title__action--right"
              @click="mobileFiltersOpen = true"
            >
              <i class="uil uil-filter" />
              <Badge v-if="countFilters.length > 0">{{ countFilters.length }}</Badge>
            </span>
          </div>
        </div>
      </div>
    </header>

    <div class="container">
      <!-- Products -->

      <div class="products">
        <div class="row">
          <!-- Product filter -->
          <aside
            v-if="!isMobile"
            class="col-3"
          >
            <div class="products__filter m-md-t-2">
              <filterattributes
                style="flex: 2"
                :default-filters="defaultFilters"
              />
            </div>
          </aside>
          <div class="col-12 col-md-9">
            <search
              v-model="searchVal"
              class="m-md-t-2"
            />

            <div
              v-if="inProgress"
              class="products__overview"
            >
              <loader class="m-b-2 m-t-2" />
              <p v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>

            <div
              v-if="!inProgress"
              class="products__overview"
            >
              <!-- ITEM -->
              <router-link
                v-for="(item, index) in displayedItems"
                :key="item.id"
                :to="$route.path + '/' + item.id"
                class="product__item"
              >
                <itemcard
                  :id="item.id"
                  :summaryitem="item"
                  :gtag-list="'Search Results'"
                  :gtag-position="index + 1"
                />
              </router-link>

              <div
                v-if="displayedItems.length === 0"
                style="text-align: center"
                class="m-b-2"
              >
                <emptystate>{{ $t('items.noItemsFound') }}</emptystate>
                <!-- ITEM -->
              </div>

              <!-- /ITEM -->
            </div>

            <div
              v-if="keysOfObject(resultWithoutFilters).length > 0"
              class="products__overview m-t-2"
            >
              <div class="m-t-2">
                <SectionTitle>Found in other categories</SectionTitle>
              </div>

              <div class="m-b-2">
                <div
                  v-for="(key) in keysOfObject(resultWithoutFilters)"
                  :key="key"
                >
                  <listItem
                    v-if="key === 'bikes'"
                    icon-before
                    arrow
                  >
                    <img src="../assets/img/icons/icon-bike.svg">

                    <router-link
                      :to="`/items/bikes?search=${searchVal}`"
                    >
                      {{ $t('items.cat.allBikes') }} <small style="margin-left: 5px">({{ resultWithoutFilters[key].length }})</small>
                    </router-link>
                  </listItem>
                  <listItem
                    v-if="key === 'accessories'"
                    icon-before
                    arrow
                  >
                    <img src="../assets/img/icons/icon-accessories.svg">

                    <router-link
                      :to="`/items/accessories?search=${searchVal}`"
                    >
                      {{ $t('items.cat.accessories') }} <small style="margin-left: 5px">({{ resultWithoutFilters[key].length }})</small>
                    </router-link>
                  </listItem>
                  <listItem
                    v-if="key === 'components'"
                    icon-before
                    arrow
                  >
                    <img src="../assets/img/icons/icon-parts.svg">

                    <router-link
                      :to="`/items/parts?search=${searchVal}`"
                    >
                      {{ $t('items.cat.components') }} <small style="margin-left: 5px">({{ resultWithoutFilters[key].length }})</small>
                    </router-link>
                  </listItem>
                  <listItem
                    v-if="key === 'outlet'"
                    icon-before
                    arrow
                  >
                    <img src="../assets/img/icons/icon-outlet.svg">

                    <router-link
                      :to="`/items/outlet?search=${searchVal}`"
                    >
                      {{ $t('items.cat.outlet') }} <small style="margin-left: 5px">({{ resultWithoutFilters[key].length }})</small>
                    </router-link>
                  </listItem>
                  <listItem
                    v-if="key === 'marketing'"
                    icon-before
                    arrow
                  >
                    <img src="../assets/img/icons/icon-promo.svg">

                    <router-link
                      :to="`/items/promo?search=${searchVal}`"
                    >
                      {{ $t('items.cat.promoMaterial') }} <small style="margin-left: 5px">({{ resultWithoutFilters[key].length }})</small>
                    </router-link>
                  </listItem>
                </div>
              </div>
            </div>

            <pagination
              class="m-t-4 m-b-4"
              :page="page"
              :pages="pages"
              :items-per-page="itemsPerPage"
              @pageChanged="changePage"
              @itemsPerPageChanged="itemsPerPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import axios from 'axios';
import search from '@/components/Search.vue';
import counter from '@/elements/Counter.vue';
import loader from '@/elements/loader.vue';
import emptystate from '@/elements/EmptyState.vue';

import pagination from '@/components/Pagination.vue';
import ItemsNav from '@/components/ItemsNav.vue';
import filterattributes from '@/components/FilterAttributes.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';

import SectionTitle from '@/elements/SectionTitle.vue';
import Badge from '@/elements/Badge.vue';

import itemcard from '@/views/items/itemCard.vue';
import listItem from '@/elements/ListItem.vue';

import { SearchItems } from '@/api/api'; /** Context */

const { CancelToken } = axios;
let findItemsSource = CancelToken.source();

let route = '';
export default {
  name: 'Items',
  metaInfo() {
    return {
      title: this.$t('header.products'),
    };
  },
  components: {
    search,
    ItemsNav,
    loader,
    Badge,
    emptystate,
    counter,
    filterattributes,
    pagination,
    itemcard,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    SectionTitle,
    listItem,
  },
  props: {
    attributes: {
      type: String,
      default: null,
    },
    defaultFilters: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: 'header.products',
    },
  },
  data() {
    return {
      isMobile: window.innerWidth < 768,
      isTablet: window.innerWidth < 992,
      mobileFiltersOpen: false,
      resultWithoutFilters: null,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      errorMessage: (state) => state.items.errorMessage,
      inProgress: (state) => state.items.inProgress,
      page: (state) => state.items.page,
      itemsPerPage: (state) => state.items.itemsPerPage,
      lastUpdate: (state) => state.items.lastUpdate,
      countFilters: (state) => state.items.filters.filter((o) => !o.category && !o.promo),
      excel: (state) => state.items.excel,
    }),
    ...mapGetters('items', {
      displayedItems: 'displayedItems',
      pages: 'pages',
    }),
    searchVal: {
      get() {
        return this.$store.state.items.search;
      },
      set(newVal) {
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.$router.push({
            name: this.$router.currentRoute.name,
            query: {
              ...this.$route.query,
              search: newVal,
              page: 1,
            },
          });
          this.$gtag.event('search', { search_term: newVal });
        }, 500);
      },
    },
  },
  watch: {
    $route(newValue, oldValue) {
      route = newValue.path;
      /* Reload data once we altered our route.
      (usually on selection of filters / edit of search attribute) */
      if (newValue.query.page !== oldValue.query.page) {
        this.$store.dispatch(
          'items/setPage',
          newValue.query.page ? parseInt(newValue.query.page, 10) : 1,
        );
      }

      if (oldValue && newValue.query.search !== oldValue.query.search) {
        this.$store.dispatch('items/setSearch', { search: newValue.query.search, triggerFetch: true });
      }

      if (
        oldValue
        && (newValue.query.attributes
          && newValue.query.attributes !== oldValue.query.attributes)
      ) {
        this.$store.dispatch('items/setCurrentFilters', {
          filters: [
            ...JSON.parse(newValue.query.attributes),
            ...this.defaultFilters,
          ],
          triggerFetch: true,
        });
      } else if (newValue.name !== oldValue.name) {
        this.$store.dispatch('items/showOnlyActionsAndPromos', false);
        this.$store.dispatch('items/setCurrentFilters', { filters: this.defaultFilters, triggerFetch: true });
      }
    },
    displayedItems(newVal) {
      this.resultWithoutFilters = null;
      if (newVal.length === 0 && !this.inProgress && this.$store.state.items.filters.length <= 1 && this.$store.state.items.search.length > 0) {
        this.getItemsWithoutFilters();
      }
      if (newVal.length > 0) {
        this.$gtag.event('view_item_list', {
          items: newVal.map((o) => ({
            id: o.externalid,
            name: o.description,
            brand: o.subtitle,
            price: o.$$price.price,
            quantity: 1,
            list_name: 'Search Results',
            list_position: o.$$index,
          })),
        });
      }
    },
  },
  mounted() {
    if (route !== this.$route.path || !this.lastUpdate) {
      this.$store.dispatch('items/setCurrentFilters', {
        filters: [
          ...(this.$route.query.attributes ? JSON.parse(this.$route.query.attributes) : []),
          ...this.defaultFilters,
        ],
      });
      this.$store.dispatch('items/setSearch', { search: this.$route.query.search });
      this.$store.dispatch('items/findItems');
      this.$store.dispatch('items/fetchAttributes');

      route = this.$route.path;
    }
  },
  created() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.updateWindowSize);
  },
  methods: {
    keysOfObject(obj) {
      if (obj) {
        return Object.keys(obj);
      }
      return [];
    },
    itemsPerPageChanged(itemsPerPage) {
      this.$store.dispatch('items/setItemsPerPage', itemsPerPage);
      window.scroll(0, 0);
    },
    updateWindowSize() {
      this.isMobile = window.innerWidth < 768;
      this.isTablet = window.innerWidth < 992;
    },
    changePage(page) {
      this.$router.push({
        name: this.$router.currentRoute.name,
        query: {
          ...this.$route.query,
          page,
        },
      });
      window.scroll(0, 0);
    },
    async fetchData() {
      this.$store.dispatch('items/findItems');
    },
    closeMobileFilters() {
      this.mobileFiltersOpen = false;
    },
    async getItemsWithoutFilters() {
      findItemsSource.cancel();
      findItemsSource = CancelToken.source();

      const groupBy = (xs, key) => xs.reduce((rv, x) => {
        // eslint-disable-next-line no-param-reassign
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});

      try {
        const summary = true;
        const result = await SearchItems(
          this.$store.state.customer.selectedCustomerId,
          this.$store.state.items.search,
          summary,
          [],
          this.$store.state.language.currentApi,
          {
            cancelToken: findItemsSource.token,
          },
        );
        this.resultWithoutFilters = groupBy(result.result, 'category');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
  },
};
</script>
