<template>
  <div style="position: relative;">
    <div
      v-if="loading"
      style="
    background: #ffffff8a;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    padding: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;"
    >
      <loader />
    </div>
    <slot />
  </div>
</template>

<script>
import loader from '@/elements/loader.vue';

export default {
  components: {
    loader,
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
};
</script>
