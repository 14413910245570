<template>
  <span>{{ counter }}</span>
</template>
<script>
export default {
  name: 'Counter',
  computed: {
    counter() {
      // 12
      return this.$store.state.items.items.length;
    },
  },
};
/*
arr.forEach(function(o){
    if (o.users.some(function(v){
        return v.tags.some(function(i){ return i.tag.indexOf(search_key) !== -1; });
    })) {
        result.push(o);
    }
});
*/
// find by externalid from store
// this.$store.state.items.items.filter(x => x.externalid === 'SBI68CEMC008');
// find by id from store
// this.$store.state.items.items.filter(x => x.id == 9);
</script>
