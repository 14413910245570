export const calculateSortOnAvailability = (a, b) => {
  const aQuant1 = a.$$availability
    ? a.$$availability.find((o) => o.quantity === 1)
    : null;
  const aDate = aQuant1 && aQuant1.date
    ? new Date(aQuant1.date)
    : new Date('2999-01-01');

  const bQuant1 = b.$$availability
    ? b.$$availability.find((o) => o.quantity === 1)
    : null;
  const bDate = bQuant1 && bQuant1.date
    ? new Date(bQuant1.date)
    : new Date('2999-01-01');

  return aDate - bDate;
};

export const sortOnPosition = (a, b) => {
  if ((a.attributes && a.attributes.position ? a.attributes.position : 99999) < (b.attributes.position ? b.attributes.position : 99999)) {
    return -1;
  }
  if ((a.attributes && a.attributes.position ? a.attributes.position : 99999) > (b.attributes.position ? b.attributes.position : 99999)) {
    return 1;
  }
  return 0;
};

export function getImageFor(item) {
  if (!item) {
    return { thumb: null, src: null };
  }
  return {
    thumb: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_400x.jpg`,
    src: item.image ? item.image : `https://images.cyclingfactory.be/${item.externalid}_image_2048x.jpg`,
  };
}

export default calculateSortOnAvailability;
