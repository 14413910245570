import axios from 'axios';
import { RequestCancelled } from '@/api/apiErrors';
import { calculateSortOnAvailability } from '@/utils/itemUtils';
import { SearchItems, GetFilterAttributes } from '../../api/api'; /** Context */

const { CancelToken } = axios;
let findItemsSource = CancelToken.source();
let getFilterOptionsSource = CancelToken.source();

export default {
  namespaced: true,
  state: {
    errorMessage: null,
    inProgress: false,
    items: [],
    lastUpdate: null,
    filters: [],
    filterOptions: [],
    filterOptionsPending: false,
    filterOptionsError: null,
    search: undefined,
    page: 1,
    itemsPerPage: 20,
    pages: [],
    showRetailPrices: true,
    showOnlyActionsAndPromos: false,
    sortType: 'NONE',
    excel: null,
  },

  // actions
  actions: {
    async fetchAttributes({ commit, rootState, state }) {
      commit('setFilterOptionsPending');
      try {
        getFilterOptionsSource.cancel();
        getFilterOptionsSource = CancelToken.source();

        this.filterAttributes = await GetFilterAttributes(
          rootState.customer.selectedCustomerId,
          state.search,
          state.showOnlyActionsAndPromos ? [...state.filters, { promo: 'yes' }] : state.filters,
          rootState.language.currentApi,
          {
            cancelToken: getFilterOptionsSource.token,
          },
        );
        this.filterAttributes.forEach((o) => {
          o.values.sort((a, b) => {
            if (
              (a.priority ? a.priority : 99999)
              < (b.priority ? b.priority : 99999)
            ) {
              return -1;
            } if (
              (a.priority ? a.priority : 99999)
              > (b.priority ? b.priority : 99999)
            ) {
              return 1;
            }
            if (a.code < b.code) {
              return -1;
            } if (a.code > b.code) {
              return 1;
            }
            return 0;
          });
        });
        const filterAttributes = this.filterAttributes
          .filter((o) => !['category', 'promo'].includes(o.code))
          .sort((a, b) => {
            if (
              (a.priority ? a.priority : 99999)
              < (b.priority ? b.priority : 99999)
            ) {
              return -1;
            } if (
              (a.priority ? a.priority : 99999)
              > (b.priority ? b.priority : 99999)
            ) {
              return 1;
            }
            if (a.code < b.code) {
              return -1;
            } if (a.code > b.code) {
              return 1;
            }
            return 0;
          });
        commit('setFilterOptionsDone', filterAttributes);
      } catch (err) {
        if (!(err instanceof RequestCancelled)) {
          commit('setFilterOptionsError', err.message);
        }
      }
    },
    async findItems({ commit, rootState, state }) {
      commit('setRequestPending');
      findItemsSource.cancel();
      findItemsSource = CancelToken.source();
      try {
        const summary = true;
        const result = await SearchItems(
          rootState.customer.selectedCustomerId,
          state.search,
          summary,
          state.showOnlyActionsAndPromos ? [...state.filters, { promo: 'yes' }] : state.filters,
          rootState.language.currentApi,
          {
            cancelToken: findItemsSource.token,
          },
        );
        commit('setRequestSuccess', { items: result.result });
        commit('setExcel', { excel: result.excel });
      } catch (err) {
        if (!(err instanceof RequestCancelled)) {
          commit('setRequestFailed', { error: err.errorMessage });
        }
      }
    },

    clear({ commit }) {
      commit('setRequestPending');
    },

    setCurrentFilters({ commit, dispatch }, { filters, triggerFetch }) {
      commit('setCurrentFilters', filters);
      if (triggerFetch) {
        dispatch('findItems');
        dispatch('fetchAttributes');
      }
    },

    setSearch({ commit, dispatch }, { search, triggerFetch }) {
      commit('setSearch', search);
      if (triggerFetch) {
        dispatch('findItems');
        dispatch('fetchAttributes');
      }
    },

    setPage({ commit }, page) {
      commit('setPage', page);
    },

    setItemsPerPage({ commit }, page) {
      commit('setItemsPerPage', page);
    },

    setSortType({ commit }, type) {
      commit('setSortType', type);
    },

    showOnlyActionsAndPromos({ commit, dispatch }, val) {
      commit('showOnlyActionsAndPromos', val);
      dispatch('findItems');
      dispatch('fetchAttributes');
    },
  },

  // mutations
  mutations: {
    setExcel(state, { excel }) {
      state.excel = excel;
    },
    //
    setRequestSuccess(state, { items }) {
      state.inProgress = false;
      state.items = Object.seal(items);
      state.lastUpdate = new Date();
    },
    setRequestFailed(state, { error }) {
      state.inProgress = false;
      state.error = error;
    },
    setRequestPending(state) {
      state.inProgress = true;
      state.error = null;
      state.items = [];
      state.lastUpdate = null;
      state.excel = null;
      state.page = 1;
    },
    setFilterOptionsDone(state, items) {
      state.filterOptionsPending = false;
      state.filterOptions = Object.seal(items);
    },
    setFilterOptionsError(state, error) {
      state.filterOptionsPending = false;
      state.filterOptionsError = error;
      state.filterOptions = [];
    },
    setFilterOptionsPending(state) {
      state.filterOptionsPending = true;
      state.filterOptionsError = null;
      // state.filterOptions = [];
    },
    setCurrentFilters(state, filters) {
      state.filters = filters;
      state.page = 1;
    },
    setSearch(state, search) {
      state.search = search;
      state.page = 1;
    },
    setPage(state, page) {
      state.page = parseInt(page, 10);
    },
    setItemsPerPage(state, itemsPerPage) {
      state.page = 1;
      state.itemsPerPage = parseInt(itemsPerPage, 10);
    },
    showRetailPrices(state, val) {
      state.showRetailPrices = val;
    },
    setSortType(state, type) {
      state.page = 1;
      state.sortType = type;
    },
    showOnlyActionsAndPromos(state, val) {
      state.showOnlyActionsAndPromos = val;
    },
  },

  getters: {
    pages(state) {
      const pages = [];
      for (
        let i = 0;
        i < state.items.length / state.itemsPerPage;
        i += 1
      ) {
        pages.push(i + 1);
      }
      return pages;
    },
    displayedItems(state) {
      const sortedItems = [...state.items];
      if (state.sortType === 'NONE') {
        sortedItems
          .sort((a, b) => calculateSortOnAvailability(a, b))
          .sort((a, b) => {
            if ((a.position ? a.position : 99999) < (b.priority ? b.position : 99999)) {
              return -1;
            }
            if ((a.position ? a.position : 99999) > (b.position ? b.position : 99999)) {
              return 1;
            }
            return 0;
          });
      } else {
        sortedItems
          .sort((a, b) => {
            if (state.sortType === 'AZ') {
              if (a.title > b.title) {
                return 1;
              }
              if (a.title < b.title) {
                return -1;
              }
              return 0;
            }

            if (state.sortType === 'ZA') {
              if (a.title > b.title) {
                return -1;
              }
              if (a.title < b.title) {
                return 1;
              }
              return 0;
            }

            if (state.sortType === 'PRICE_LOW_HIGH') {
              return (
                (state.showRetailPrices ? b.$$price.msrp : b.$$price.price)
                - (state.showRetailPrices ? a.$$price.msrp : a.$$price.price)
              );
            }

            if (state.sortType === 'PRICE_HIGH_LOW') {
              return (
                (state.showRetailPrices ? a.$$price.msrp : a.$$price.price)
                - (state.showRetailPrices ? b.$$price.msrp : b.$$price.price)
              );
            }

            if (state.sortType === 'AVAILABILITY') {
              return calculateSortOnAvailability(a, b);
            }

            return 0;
          });
      }
      return sortedItems
        .map((obj, i) => ({ ...obj, $$index: i }))
        .slice(
          state.page * state.itemsPerPage - state.itemsPerPage,
          state.page * state.itemsPerPage,
        );
    },
  },
};
