<template>
  <label>
    <input
      v-model="valueInt"
      class="form-check-input"
      type="radio"
      :name="name"
      :value="checkedValue"
      @change="$emit('change', $event.target.checked)"
    >

    <span class="radiobutton m-r-2" />

    <slot />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    checkedValue: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    valueInt: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
<style scoped>
.form-check-input {
  display: none;
}
.form-check-input:checked ~ .radiobutton::after {
  border-radius: 2rem;
  content: " ";
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.25rem;
  background-color: #d1000d;
  font-family: unicons;
  font-size: 2.25rem;
  color: #d1000d;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 2.25rem;
  text-align: center;
}
</style>
