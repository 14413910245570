/* eslint-disable max-classes-per-file */

const translate = (key, context) => window.i18n.t(key, context);

export class ApiError extends Error {
  constructor(...args) {
    super(...args);
    this.name = this.constructor.name;

    if (args[0].response) {
      this.body = args[0].response.data;
      this.status = args[0].response.status;
      this.requestId = args[0].response.headers['x-request-id'];
      this.message = `Api returned ${this.status} [${this.requestId}]`;
      this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'ApiError'}`);
    }
  }
}

export class UnexpectedApiError extends ApiError {
  constructor(...args) {
    super(...args);
    if (this.body) {
      this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'UnexpectedApiError'}`);
    }
  }
}

export class RequestCancelled extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate('api.cancelled');
  }
}

export class ForbiddenApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'ForbiddenApiError'}`);
  }
}

export class UnautorisedApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'UnautorisedApiError'}`);
  }
}

export class UnprocessableApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'UnprocessableApiError'}`, {
      errors: JSON.stringify(this.body.err.message),
    });
  }
}

export class ValidationApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'ValidationApiError'}`);
  }
}

export class AlreadyExistsError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate(`api.${this.body.err && this.body.err.name ? this.body.err.name : 'AlreadyExistsError'}`);
  }
}

export class NoResponseApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate('api.apiNoResponseContent');
  }
}

export class NotFoundApiError extends ApiError {
  constructor(...args) {
    super(...args);
    this.apiMessage = translate('api.apiNotFound');
  }
}
