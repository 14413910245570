<template>
  <div>
    <ItemsNav />
    <div>
      <div
        v-if="inProgress"
        class="container"
      >
        <loader />
      </div>

      <div
        v-else
        class="container"
      >
        <div class="row">
          <div class="col-md-4">
            <SectionTitle>
              Filter models
            </SectionTitle>
            <select v-model="filterModels">
              <option :value="null">
                All
              </option>
              <option
                v-for="range in Object.keys(models)"
                :key="range"
                :value="range"
              >
                {{ range }}
              </option>
            </select>
          </div>
          <div class="col-md-8">
            <SectionTitle>
              {{ $t('items.cat.code') }}
            </SectionTitle>
            <form @submit.prevent="goToBuildYourOwn">
              <div class="d-flex">
                <div class="load-config__container">
                  <input
                    v-model="code"
                    type="text"
                    placeholder="Configuration code"
                    @input="onCodeInput"
                  >
                  <div
                    v-if="buildYourOwnErr"
                    class="load-config__error"
                  >
                    {{ $t(buildYourOwnErr.message) }}
                  </div>
                </div>
                <button
                  class="button button--danger m-l-1"
                  @click="goToBuildYourOwn()"
                >
                  Go!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="container m-t-4">
        <div class="row">
          <template v-for="model in filteredModels">
            <div
              v-if="filterModels === null || filterModels === model.attributes.range"
              :key="model.id"
              class="col-lg-3 col-md-4 col-sm-6 col-12 m-b-4"
            >
              <router-link
                class="configurator__model"
                :to="{name:'buildYourOwn', params: {model: model.modelcode}}"
              >
                <brand-logo
                  :brand="model.attributes ? model.attributes.item_brand : ''"
                  style="position: absolute;    left: 20px;
    top: 20px;  width: 60px; "
                />

                <img
                  v-if="model.attributes && model.attributes.images && model.attributes.images.image && model.attributes.images.image.thumb"
                  class="detailimage"
                  :src="model.attributes.images.image.thumb"
                >
                <strong>{{ model.attributes ? model.attributes.model : model.modelcode }}</strong>
                <small>{{ $t('from') }}
                  <price
                    :price="model.priceFrom"
                    :currency="model.priceFromCurrency || 'EUR'"
                  />
                </small>

                <button
                  v-if="model.attributes"
                  class="button button--icon m-t-2"
                  @click.prevent="openModelInfo(model)"
                >
                  <i class="uil uil-info-circle" />
                </button>
              </router-link>
            </div>
          </template>
        </div>
      </div>
      <bcfAside
        v-if="modelInfo"
        @close="closeModelInfo"
      >
        <bcfAsideHeader
          :close="closeModelInfo"
        >
          <h1 class="m-l-3">
            {{ modelInfo.attributes ? modelInfo.attributes.model : modelInfo.modelcode }}
          </h1>
        </bcfAsideHeader>
        <bcfAsideContent>
          <div
            v-if="modelInfo.attributes.platform_description"
            id="platform_description"
            class="product__platform-description m-t-4"
          >
            <SectionTitle>
              {{
                attributeTranslations[ 'platform_description' ]
                  ? attributeTranslations[ 'platform_description' ]
                  : key
              }}
            </SectionTitle>
            <div v-html="modelInfo.attributes.platform_description" />
            <specifications
              id="specs"
              :item="modelInfo"
            />
            <geo
              id="geo"
              :item="modelInfo"
            />
          </div>
        </bcfAsideContent>
      </bcfAside>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SectionTitle from '@/elements/SectionTitle.vue';

import ItemsNav from '@/components/ItemsNav.vue';
import { getModels, LoadConfigurationByCode } from '@/api/customizer';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import specifications from '@/components/itemDetails/specifications.vue';
import geo from '@/components/itemDetails/geo.vue';
import Price from '@/elements/Price.vue';
import BrandLogo from '@/elements/brandLogo.vue';

export default {
  components: {
    ItemsNav,
    loader,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    SectionTitle,
    specifications,
    geo,
    Price,
    BrandLogo,
  },
  data() {
    return {
      filterModels: null,
      models: {},
      inProgress: false,
      modelInfo: null,
      code: '',
      buildYourOwnErr: null,
    };
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
      showRetailPrices: (state) => state.items.showRetailPrices,
      userEmail: (state) => state.auth.user.email,
    }),
    filteredModels() {
      let models = [];
      if (this.filterModels) {
        models = this.models[this.filterModels];
      } else {
        Object.keys(this.models)
          .forEach((item) => {
            this.models[item].forEach((model) => {
              models.push(model);
            });
          });
      } models.sort((a, b) => a.attributes?.model?.localeCompare(b.attributes?.model));
      return models;
    },
  },
  async created() {
    this.inProgress = true;
    const models = await getModels(this.$store.state.language.currentApi, this.$store.state.customer.selectedCustomerId);

    this.models = models.reduce((rv, x) => {
      // eslint-disable-next-line no-param-reassign
      (rv[x.attributes && x.attributes.range ? x.attributes.range : 'OTHER'] = rv[x.attributes && x.attributes.range ? x.attributes.range : 'OTHER'] || []).push(x);
      return rv;
    }, {});

    this.inProgress = false;
  },
  methods: {
    async goToBuildYourOwn() {
      this.buildYourOwnErr = null;
      if (!this.code) {
        // eslint-disable-next-line no-alert
        // alert('Please provide your customizer code!');
        this.buildYourOwnErr = {
          message: 'customizer.errors.empty',
        };
      } else {
        try {
          const customConfiguration = await LoadConfigurationByCode(this.code);
          this.$router.push({
            name: 'buildYourOwn',
            params: { model: customConfiguration.model },
            query: { code: this.code },
          });
        } catch (err) {
          const context = { code: this.code, status: err.status };
          if (err.status === 404) {
            this.buildYourOwnErr = {
              message: 'customizer.errors.notFound',
            };
          } else {
            this.buildYourOwnErr = {
              ...context,
              message: 'customizer.errors.generic',
            };
          }
        }
      }
    },
    openModelInfo(modelInfo) {
      this.modelInfo = modelInfo;
    },
    closeModelInfo() {
      this.openModelInfo();
    },
    onCodeInput() {
      // Clear any errors
      this.buildYourOwnErr = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.models {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.model-button {
  width: calc(25% - 2rem);
  -webkit-box-shadow: 0 2px 5px rgba(87, 102, 129, 0.4);
  box-shadow: 0 2px 5px rgba(87, 102, 129, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #010a01;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-bottom: 3rem;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 6rem 0 4rem 0;
    color: #010a01;
    font-weight: bold;

    img {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(md) {
  .model-button {
    width: calc(33% - 2rem);
  }
}

@include media-breakpoint-down(sm) {
  .model-button {
    width: calc(50% - 2rem);
  }
}

@include media-breakpoint-down(xs) {
  .model-button {
    width: calc(100%);
  }
}

.load-config {
  &__container {
    flex: 1 0 auto;
    display: inline-block;
    position: relative;
  }

  &__error {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0.25em 0.75em;
    color: red;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.75rem;
  }
}
</style>
