<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>{{ $t('cart.successTitle') }}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div
      v-if="prebookStatus !== 'SUCCESS'"
      class="container"
    >
      <Message
        type="error"
        :icon="true"
      >
        {{ $t('cart.success.errorMessage') }}.
      </Message>
    </div>
    <div
      v-if="prebookStatus === 'SUCCESS'"
      class="container"
    >
      <div class="row">
        <div class="col">
          <div class="steps-progress">
            <div class="steps-progress__item steps-progress__item--active">
              1
            </div>
            <div class="steps-progress__item steps-progress__item--active">
              2
            </div>
            <div class="steps-progress__item steps-progress__item--active">
              <i class="uil uil-check" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col m-t-2">
          <Message
            type="success"
            :icon="true"
          >
            {{ $t('cart.success.message') }}
          </Message>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 m-t-4">
          <SectionTitle>{{ $t('cart.success.whatNextTitle') }}</SectionTitle>

          <ol class="steps">
            <li>{{ $t('cart.success.next1') }}</li>
            <li>{{ $t('cart.success.next2') }}</li>
            <li>{{ $t('cart.success.next3') }}</li>
          </ol>

          <list-item
            icon-before
            arrow
          >
            <i class="uil uil-box" />
            <router-link :to="{name: 'myOrders'}">
              {{ $t('cart.success.viewOrder') }}
            </router-link>
          </list-item>
        </div>

        <div class="col-md-6 m-t-4">
          <SectionTitle>{{ $t('cart.success.goodForYour') }}</SectionTitle>

          <EmptyState icon="chart-line">
            {{ $t('general.availableSoon') }}
          </EmptyState>

          <!--<list-item iconBefore arrow>
            <i class="uil uil-chart"></i>
            <a href="#">Mijn statistieken</a>
          </list-item>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Message from '@/components/Message.vue';
import EmptyState from '@/elements/EmptyState.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import ListItem from '@/elements/ListItem.vue';

export default {
  metaInfo() {
    return {
      title: this.$t('cart.successTitle'),
    };
  },
  components: {
    Message,
    EmptyState,
    SectionTitle,
    ListItem,
  },
  computed: {
    ...mapGetters('prebook', {
      prebookStatus: 'prebookStatus',
    }),
    ...mapState({
      selectedCustomer: (state) => state.customer.selectedCustomer,
      prebookMeta: (state) => state.prebook.metadata,
    }),
  },
  beforeDestroy() {
    this.$store.commit('prebook/setPrebookStatus', {
      status: 'DRAFT',
      customerId: this.$store.state.customer.selectedCustomerId,
    });
  },
  created() {
    if (this.prebookStatus !== 'SUCCESS' && this.prebookStatus !== 'ERROR') {
      this.$router.replace({ name: 'prebook', params: { brand: this.$route.params.brand } });
    }
  },
  mounted() {
    if (!this.prebookMeta || this.prebookMeta.preorderCustomerId !== this.selectedCustomer.id) {
      this.$router.replace({ name: 'prebook', params: { brand: this.$route.params.brand } });
    }
  },
};
</script>
