<template>
  <div class="card-item">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Card',
};
</script>
