<template>
  <div>
    <div @click="localOpen = !localOpen">
      <slot
        name="header"
        :open="localOpen"
      />
    </div>
    <slot v-if="localOpen" />
  </div>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localOpen: this.open,
    };
  },
};
</script>
<style scoped>
</style>
