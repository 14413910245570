<template>
  <div>
    <div
      v-for="(order, i) in generated.groupedOrders"
      :key="'order'+(i+1)"
    >
      <!-- Start order -->
      <SectionTitle>Order #{{ (i+1) }}</SectionTitle>
      <!-- Order header -->
      <div class="cart__order__header">
        <div
          v-if="order.date"
          class="cart__order__header__date"
        >
          <span class="label">{{ $t('cart.deliveryDate') }}</span>
          <span class="value">{{ formatDate(order.date) }}</span>
        </div>
        <div
          v-if="order.shippingcost"
          class="cart__order__header__shippingcost"
        >
          <span class="label">{{ $t('cart.shippingCost') }}</span>
          <span class="value">+
            <price

              :currency="generated.currency"
              :price="order.shippingcost"
            />
          </span>
        </div>
      </div>
      <!-- CartItem card -->
      <cart-order-line
        v-for="line in getValidOrderLines(order)"
        :key="line.item.id"
        :orderline="line"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import CartOrderLine from '@/components/cart/CartOrderLine.vue';
import { formatDate } from '@/utils/dateUtils';
import SectionTitle from '@/elements/SectionTitle.vue';
import price from '@/elements/Price.vue';

export default {
  components: {
    CartOrderLine,
    SectionTitle,
    price,
  },
  props: {
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
      showRetailPrices: (state) => state.items.showRetailPrices,
    }),
    ...mapGetters('cart', {
      generated: 'cartGenerated',
      loading: 'cartLoading',
      error: 'cartError',
    }),
  },
  methods: {
    formatDate,
    getValidOrderLines(order) {
      return order.lines.filter((line) => line.item.externalid || false);
    },
  },
};
</script>

<style lang="scss">
  .cart__order__header {
    padding: 1rem 2.5rem;
    margin-bottom: 2rem;
    background: white;
    font-size: 1.75rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .cart__order__header__date,
    .cart__order__header__shippingcost {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }

    .label {
      font-weight: bold;
      text-transform: uppercase;
    }

    .value {

    }
  }
</style>
