<!--
 A beter name might by BcfDialogModal or BcfModal? (in which case we'd have to resolve conflict with the other BcfModal
-->
<template>
  <div
    :class="{
      'dialog-overlay': true,
      'dialog-overlay--closed': !isOpen,
      'dialog-overlay--open': isOpen,
      [`dialog-overlay--scroll-${scrollMode}`]: true
    }"
  >
    <div class="dialog-overlay__spacer" />
    <div
      v-click-outside="onClickOutside"
      :class="{
        'dialog-overlay__content': true,
      }"
    >
      <slot
        v-bind="overlayControl"
      />
    </div>
    <div class="dialog-overlay__spacer" />
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside';
import Vue from 'vue';
import { shallowIsEqual } from '@/utils/object';

/**
 * @typedef {'beforeClose'|'beforeShow'|'shown'|'closed'} DialogHookTypes
 */

/**
 * @typedef {Object} DialogConfigParams
 * @property {'content'|'window'} [scroll] - How to deal with scrolling
 * @property {Function} [beforeClose] - Callback-hook, called with (closeResult, closeSurce), returning false will prevent closing
 * @property {Function} [beforeShow] - Callback-hook, returning false will prevent closing
 * @property {Function} shown - Callback-hook
 * @property {Function} closed - Callback-hook
 */
/**
 * @typedef {DialogConfigParams} DialogConfigDirectiveParams
 * @property {BcfDialogOverlay} overlay - Required
 */

/**
 *
 * @type {DialogHookTypes[]}
 */
const dialogHookTypes = [
  'beforeClose',
  'beforeShow',
  'shown',
  'closed',
];

/**
 * Provides the backdrop and show/close functionality for dialogs
 */
export default {
  name: 'BcfDialogOverlay',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    isTopLevel: {
      // Keeps track if this is the Topmost dialog (TODO: a solution for dialogs not spawned via dialogs.show();? )
      type: Boolean,
      default: true,
    },
    scroll: {
      type: String,
      /**
       * Options allowed:
       *  - content:  Will set content region of the modal to overflow: auto
       *  - window:   Will set the overlay itself to overflow:auto (scrolling the dialog as a whole)
       *  - any other value will leave overflow values to their HTML defaults
       */
      default: 'content',
    },
  },
  data() {
    return {
      isOpen: false,
      justOpened: false, // This is to surpress a mouseup event triggering a click-outside event -> close
      overlayControl: this.updateControl(),
      hooks: {},
      scrollMode: 'content',
    };
  },
  computed: {
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.show();
        } else {
          this.close();
        }
      }
    },
    scroll(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.scrollMode = newValue;
      }
    },
  },
  beforeCreate() {

  },
  created() {
    // document.onkeydown = (evt) => {
    //   evt = evt || window.event;
    //   if (evt.keyCode === 27 && this.topLevel) {
    //     this.close(undefined, 'dismiss'); // Because it is near equal to click-outside which uses dismiss (do we ever need to tell the difference?)
    //   }
    // };
  },
  mounted() {
    if (this.open) {
      this.show();
    }
  },
  methods: {
    async close(result = undefined, source) {
      const { hooks, isOpen } = this;
      if (isOpen) {
        if (hooks.beforeClose) {
          const hookResult = await hooks.beforeClose(result, source);
          if (hookResult === false) {
            return; // Cancel requested
          }
        }
        if (isOpen.resolve) {
          // Execute resolve-callback fulfulling the promise that bcfDialogOver.show() returns and thus the result of closure
          this.isOpen.resolve(result);
        }
      }
      this.isOpen = false;
      this.updateControl();
      this.$emit('close', result);
      if (hooks.closed) {
        await hooks.closed(result, source);
      }
    },
    async show() {
      const { hooks, isOpen } = this;
      if (!isOpen) {
        if (hooks.beforeShow) {
          const hookResult = await hooks.beforeShow();
          if (hookResult === false) {
            return undefined; // Cancel requested
          }
        }
        const opened = {};
        opened.promise = new Promise((resolve) => {
          opened.resolve = resolve;
        });
        this.isOpen = opened;
        // Mark a just-opened flag which will get cleared after next redraw
        // need to do this to debounce click-outside properly when the dialog is shown as a result of click
        this.justOpened = true;
        requestAnimationFrame(() => {
          this.$nextTick(() => {
            this.justOpened = false;
          });
        });
      }
      this.updateControl();
      this.$emit('show', undefined);
      if (hooks.opened) {
        const result = await hooks.shown();
        if (result === false) {
          return undefined; // Cancel requested
        }
      }
      return this.isOpen.promise;
    },
    onClickOutside(event) {
      if (this.isOpen && !this.justOpened && this.isTopLevel) {
        this.$emit('clickOutside', event);
      }
    },
    updateControl() {
      const newControl = {
        open: this.open,
        show: this.show,
        close: this.close,
        overlay: this,
        configure: this.configure,
      };
      this.overlayControl = newControl;
      return newControl;
    },
    configure(config) {
      this.hooks = Object.fromEntries(
        dialogHookTypes.map((hookKey) => [hookKey, config[hookKey]]),
      );
      if (config.scroll !== undefined) {
        this.scrollMode = config.scroll;
      }
    },
  },
};

/**
 * Partner directive to configure dialog-overlay (can be used in custom component)
 */
Vue.directive('dialog-config', (el, binding) => {
  /** @type {DialogConfigParams} */
  const { value } = binding;
  /** @type {DialogConfigParams} */
  const oldValue = binding.oldValue || {};

  const overlay = value && value.overlay;
  if (!overlay) {
    throw new Error('Can\'t set dialog config, overlay missing');
  }
  if (!shallowIsEqual(value, oldValue)) {
    overlay.configure(value);// A bit dirty
  }
});
</script>

<style scoped>

</style>
