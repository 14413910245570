import { timeExpired } from '@/utils/dateUtils';
import { GetNavisionInvoices } from '../../api/api';

export default {
  namespaced: true,
  state: {
    // items: [],
    errorMessage: null,
    inProgress: false,
    myInvoices: '',
    isLoaded: false,
    lastUpdate: null,
    customer: null,
  },

  getters: {
    listMyInvoices: state => (state.myInvoices ? state.myInvoices : []),
    myInvoiceHeader: state => id => state.myInvoices.filter(s => s.externalinvoiceid === id),
    loadStatus(state) {
      return state.isLoaded;
    },
  },

  actions: {
    async get({
      commit, rootState, rootGetters, state,
    }) {
      if (rootState.myInvoices.lastUpdate === null || timeExpired(rootState.myInvoices.lastUpdate) || rootState.customer.selectedCustomerId !== state.customer) {
        commit('setRequestPending');
        try {
          if (rootGetters['auth/isLoggedIn']) {
            const result = await GetNavisionInvoices(
              rootState.customer.selectedCustomerId,
            );
            commit('setRequestSuccess', { result: result.result, customerId: rootState.customer.selectedCustomerId });
          } else {
            throw new Error('Not logged in!');
          }
        } catch (err) {
          commit('setRequestFailed', { error: err.message });
        }
      }
    },
    async clear({ commit }) {
      commit('setRequestFailed', { error: '' });
    },
  },

  mutations: {
    //
    setRequestSuccess(state, { result, customerId }) {
      state.inProgress = false;
      state.myInvoices = result || state.myInvoices;
      state.isLoaded = true;
      state.lastUpdate = new Date();
      state.customer = customerId;
    },
    setRequestFailed(state, { error }) {
      state.inProgress = false;
      state.isLoaded = true;
      state.errorMessage = error;
    },
    setRequestPending(state) {
      state.inProgress = true;
      state.isLoaded = false;
      state.errorMessage = null;
      state.myInvoices = [];
      state.lastUpdate = null;
    },
  },
};
