<template>
  <div
    class="contact-button"
    :class="{hasNotification: chatCount}"
    @click="openWidget()"
  >
    <i class="uil uil-angle-up" />
    <strong>{{ $t('ChatWithUs') }}</strong>
    <span
      v-if="chatCount"
      class="tag tag--red"
    >{{ chatCount }}</span>
  </div>
</template>
<script>
import { userUpdate } from '@/api/user';

export default {
  data() {
    return {
      chatCount: null,
    };
  },
  destroyed() {
    window.fcWidget.destroy();
  },

  async mounted() {
    const store = this.$store;
    if (store.getters['auth/isLoggedIn']) {
      const t = this;
      const restoreId = store.state.auth.user.freshChatRestoreId;
      window.fcWidget.init({
        token: '4663cf78-16ed-4cda-84d5-de205de8e5db',
        host: 'https://wchat.freshchat.com',
        externalId: store.state.auth.user.externalid,
        restoreId: restoreId || null,
        locale: store.state.language.current,
        firstName: store.state.auth.user.type === 1 ? `${store.state.customer.selectedCustomer.name} (${store.state.customer.selectedCustomer.externalid})` : store.state.auth.user.externalid,
        email: store.state.auth.user.email,
        config: {
          headerProperty: {
            backgroundColor: '#C0242B',
            hideChatButton: true,
          },
        },
      });
      window.fcWidget.on('widget:loaded', () => {
        window.fcWidget.on('unreadCount:notify', (resp) => {
          t.chatCount = resp.count;
        });
      });
      window.fcWidget.user.get(async (resp) => {
        const status = resp && resp.status;
        const data = resp && resp.data;
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: store.state.auth.user.type === 1 ? `${store.state.customer.selectedCustomer.name} (${store.state.customer.selectedCustomer.externalid})` : store.state.auth.user.externalid,
            email: store.state.auth.user.email,
          });
          window.fcWidget.on('user:created', async (resp2) => {
            const status2 = resp2 && resp2.status;
            const data2 = resp2 && resp2.data;
            if (status2 === 200) {
              if (data2.restoreId) {
                await userUpdate(store.state.auth.user.id, { ...store.state.auth.user, freshChatRestoreId: data2.restoreId });
                this.$store.dispatch('auth/updateUser', { user: { ...store.state.auth.user, freshChatRestoreId: data2.restoreId } });
              }
            }
          });
        } else if (!restoreId) {
          window.fcWidget.user.setProperties({
            firstName: store.state.auth.user.type === 1 ? `${store.state.customer.selectedCustomer.name} (${store.state.customer.selectedCustomer.externalid})` : store.state.auth.user.externalid,
            email: store.state.auth.user.email,
          });
          if (data.restoreId) {
            await userUpdate(store.state.auth.user.id, { ...store.state.auth.user, freshChatRestoreId: data.restoreId });
            this.$store.dispatch('auth/updateUser', { user: { ...store.state.auth.user, freshChatRestoreId: data.restoreId } });
          }
        }
      });
    }
  },
  methods: {
    openWidget() {
      window.fcWidget.open();
    },
  },

};
</script>
<style scoped>
.contact-button{
    position: fixed;
    bottom: 0;
    right: 20px;
    background: white;
    padding: 1rem;
    border-top: 1px #d1000d solid;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 39%);
}

.contact-button.hasNotification{
    border-top: 5px #d1000d solid;
}

.tag--red{
  background: #d1000d;
  color: white;
  margin-left: 5px
}

@media (max-width: 991.98px) {
  .contact-button{
    bottom: 56px
  }
}

</style>
