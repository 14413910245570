<template>
  <fieldset>
    <collapse :open="collapsedByDefault">
      <template v-slot:header="{open}">
        <div
          class="section-title section-title--collapse"
          :class="{'open': open}"
        >
          {{ attributeTranslations[attribute.code]
            ? attributeTranslations[attribute.code]
            : attribute.code }}
        </div>
      </template>
      <ul>
        <li
          v-for="v in shownAttributes"
          :key="v.code"
        >
          <checkbox
            v-model="attributes"
            :checked-value="{[attribute.code]: v.code}"
          >
            <span v-if="attribute.code === 'item_brand'">
              <brand-logo :brand="v.description" />
            </span>
            <span v-else>{{ v.description }}</span>
            <small>({{ v.amountofitems }})</small>
          </checkbox>
        </li>
        <li
          v-if="attribute.values.length > showLessCount"
          @click="showMore = !showMore"
        >
          <span
            v-if="showMore"
            class="link-expand-filters less"
          >{{ $t('items.showLess') }}</span>
          <span
            v-else
            class="link-expand-filters"
          >{{ $t('items.showMore') }}</span>
        </li>
      </ul>
    </collapse>
  </fieldset>
</template>
<script>
import { mapState } from 'vuex';
import collapse from '@/components/collapse.vue';
import brandLogo from '@/elements/brandLogo.vue';
import checkbox from '@/elements/checkbox.vue';

export default {
  name: 'FilterAttributes',
  components: {
    collapse,
    brandLogo,
    checkbox,
  },
  props: {
    attribute: {
      type: Object,
      default() {
        return {};
      },
    },
    collapsedByDefault: Boolean,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showLessCount: 5,
      showMore: false,
    };
  },
  computed: {
    ...mapState({
      selectedCustomerId: (state) => state.customer.selectedCustomerId,
      attributeTranslations: (state) => state.language.attributeTranslations,
    }),
    shownAttributes() {
      const {
        showMore, attribute, showLessCount, value,
      } = this;
      if (!attribute || !attribute.values) return [];
      if (showMore || attribute.values.length <= showLessCount) {
        // Show everything in the original listing
        return attribute.values;
      }
      // Limit the list to 5 and prioritize selected options (without changing too much of the ordering)
      const selected = new Set((value || []).filter((f) => Object.hasOwnProperty.call(f, attribute.code)).map((f) => f[attribute.code]));
      return attribute.values.map((v, index) => ({
        value: v,
        index,
        selected: selected.has(v.code),
      }))
        .map((a) => {
          let priority = 2;
          if (a.selected) priority = 0;
          else if (a.index < showLessCount) priority = 1;
          return {
            ...a,
            priority,
          };
        })
        .sort((a, b) => a.priority - b.priority)
        .slice(0, showLessCount)
        .sort((a, b) => a.index - b.index)
        .map((a) => a.value);
    },
    attributes: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.value.length < newVal.length) {
          this.$gtag.event(this.attribute.code, {
            event_category: 'Search FIlter',
            event_label: newVal[newVal.length - 1][this.attribute.code],
          });
        }
        this.$emit('input', newVal);
      },
    },
  },
};
</script>
