<template>
  <div class="locale-changer">
    <select v-model="language">
      <option
        v-for="lang in availableLanguages"
        :key="lang"
        :value="lang"
      >
        {{ $t(`language.${lang}`) }}
      </option>
    </select>
  </div>
</template>

<script>
import translations from '@/translations';

export default {
  name: 'LocaleChanger',
  computed: {
    availableLanguages: () => Object.keys(translations),
    language: {
      get() {
        return this.$store.state.language.current;
      },
      set(value) {
        this.$store.dispatch('language/setLanguage', { language: value });
      },
    },
  },
};
</script>
