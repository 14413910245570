<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t("cart.changeOrder") }}
    </bcfAsideHeader>

    <bcfAsideContent>
      <itemcard
        class="product__item"
        :summaryitem="item.product"
      />
      <button
        v-if="isCustomBike"
        class="button button--primary m-t-4"
        @click="changeBike()"
      >
        Change bike configuration
      </button>
      <div v-if="datePredicted">
        <div v-if="!preOrder">
          <div class="section-title m-t-4">
            {{ $t("cart.deliveryDate") }}
          </div>

          <div class="form-group form-group--checkbox">
            <radio-button
              v-model="deliveryDateType"
              checked-value="DEFAULT"
              name="delivery"
            >
              {{ $t("cart.whenAvailable") }} ({{ weeksToPredicted }})
            </radio-button>
          </div>

          <div class="form-check">
            <label
              class="form-check-label"
              for="delivery1"
            />
          </div>

          <div class="form-group form-group--checkbox">
            <radio-button
              v-model="deliveryDateType"
              checked-value="SELECTED"
              name="delivery"
              @change="chooseDateSelected"
            >
              {{ $t('cart.chooseDate') }}
            </radio-button>
          </div>

          <div
            v-if="deliveryDateType === 'SELECTED'"
            class="form-group"
          >
            <datepicker
              :placeholder="$t('cart.chooseDate')"
              :disabled-dates="disabledDates"
              maximum-view="month"
              :language="lang"
              :value="datepickerValue"
              :format="customFormatter"
              :open-date="datePredicted"
              @selected="dateSelected"
            />
          </div>
        </div>
      </div>

      <div
        v-else-if="!preOrder"
        class="m-b-2"
      >
        <Message
          type="warning"
          :icon="false"
          content="This quantity is not available."
          :show-close="false"
        >
          <strong>Sorry...</strong>
        </Message>
      </div>

      <div class="section-title m-t-2">
        {{ $t("cart.quantity") }}
      </div>

      <div class="d-flex">
        <button
          class="button button--icon button--secondary"
          :disabled="quantity <= 1"
          @click="decrement()"
        >
          -
        </button>

        <div class="form-group m-l-1 m-r-1">
          <input
            v-model="quantity"
            type="number"
            min="0"
            :max="maxItems"
            disabled
          >
        </div>
        <button
          class="button button--icon button--secondary"
          :disabled="quantity >= maxItems"
          @click="increment()"
        >
          +
        </button>
      </div>
      <Message
        v-if="(isCustomBike && quantity > 1 && !preOrder) || (!isCustomBike && quantity > maxItemsAvailable && maxItemsAvailable === 5 && !preOrder)"
        type="warning"
        :icon="false"
        :show-close="false"
      >
        {{ $tc('cart.dateNotAvailable', maxItemsAvailable, {max: maxItemsAvailable}) }}
      </Message>
      <Message
        v-if="maxItemsAvailable < quantity && maxItemsAvailable < 5 && !preOrder && !isCustomBike"
        type="warning"
        :icon="false"
        :show-close="false"
      >
        {{ $tc('cart.limitReached', maxItemsAvailable, {max: maxItemsAvailable}) }}
      </Message>

      <div class="section-title m-t-2">
        {{ $t("cart.itemremarks") }}
      </div>

      <div class="form-group">
        <p>{{ $t('cart.remarksexplanation') }}</p>
        <textarea v-model="remarks" />
      </div>
    </bcfAsideContent>
    <bcfAsideFooter>
      <button
        class="button button--danger"
        @click="removeItem()"
      >
        {{ $t('cart.remove') }}
      </button>
      <button
        class="button button--primary"
        @click="save()"
      >
        {{ $t('cart.save') }}
      </button>
    </bcfAsideFooter>
  </bcf-aside>
</template>

<style scoped></style>

<script>
import Datepicker from 'vuejs-datepicker';
import bcfAside from '@/components/bcfAside.vue';
import dialogs from '@/utils/dialogs';
import translations from '@/translations';
import Message from '@/components/Message.vue';
import itemcard from '@/views/items/itemCard.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import { maxItemsAvailable, availabilityText, getAvailabilityDate } from '@/utils/cartUtils';
import radioButton from '@/elements/radioButton.vue';
import { formatDate } from '@/utils/dateUtils';
import { mapState } from 'vuex';

export default {
  components: {
    Datepicker,
    Message,
    bcfAside,
    bcfAsideHeader,
    bcfAsideFooter,
    bcfAsideContent,
    itemcard,
    radioButton,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      item: {},
      quantity: null,
      remarks: null,
      preOrder: false,
      deliveryDate: null,
      deliveryDateType: 'DEFAULT',
    };
  },
  computed: {
    datePredicted() {
      if (this.isCustomBike) {
        return new Date(this.item.product.deliverydate);
      }

      if (!this.item.product.$$availability) {
        return null;
      }

      const date = getAvailabilityDate(this.item.product.$$availability, this.quantity);
      return new Date(date);
    },
    ...mapState({
      showRetailPrices: (state) => state.items.showRetailPrices,
    }),
    weeksToPredicted() {
      if (this.isCustomBike) {
        return formatDate(this.datePredicted);
      }

      if (this.quantity > this.maxItemsAvailable) {
        return null;
      }
      return availabilityText(this.item.product.$$availability, this.quantity, this.showRetailPrices);
    },
    disabledDates() {
      return {
        to: this.datePredicted, // Disable all dates up to specific date
        days: [6, 0], // Disable Saturday's and Sunday's,
      };
    },
    lang() {
      return translations[this.$store.state.language.current].Datepicker;
    },
    preOrderMonths() {
      const startDate = new Date(2024, 0, 1);
      const quarters = [];

      for (let i = 0; i < 12; i++) {
        const newdate = new Date(startDate);
        newdate.setMonth(newdate.getMonth() + (i));
        const withoutTimeZone = new Date(newdate.getTime() - (newdate.getTimezoneOffset() * 60000));
        quarters.push(
          {
            date: withoutTimeZone,
            key: withoutTimeZone.getTime(),
          },
        );
      }
      return quarters;
    },
    maxItemsAvailable() {
      return this.isCustomBike ? 1 : maxItemsAvailable(this.item.product.$$availability);
    },
    maxItems() {
      if (this.isCustomBike) {
        return this.$store.state.auth.user.type > 1 ? 1000 : 1;
      }

      if (this.$store.state.auth.user.type > 1 && this.item.product.category === 'bikes') {
        if (this.maxItemsAvailable === 5) {
          return 1000;
        }
        return this.maxItemsAvailable;
      }
      return this.maxItemsAvailable;
    },
    datepickerValue() {
      const deliveryDate = this.deliveryDate
        ? this.deliveryDate
        : this.datePredicted;
      return this.deliveryDateType === 'SELECTED'
        ? deliveryDate
        : null;
    },
    canPreorder() {
      return [2, 3, 4, 5].includes(this.$store.state.auth.user.type);
    },
    isCustomBike() {
      return this.item.customizer || false;
    },
  },
  watch: {
    '$route.params.id': (id) => {
      this.initItem(id);
    },
  },
  created() {
    this.initItem(this.$route.params.id);
  },
  methods: {
    initItem(id) {
      this.item = this.$store.getters['cart/cartItem'](id);
      if (!this.item) {
        this.close();
      } else {
        this.quantity = parseInt(this.item.quantity, 10);
        this.remarks = this.item.remarks;
        this.preOrder = this.item.preOrder;
        this.deliveryDate = this.item.deliveryDate;
        this.deliveryDateType = this.item.deliveryDateType;
      }
    },
    customFormatter(date) {
      return formatDate(date);
    },
    async removeItem() {
      const confirmed = await dialogs.show({
        title: `${this.$t('cart.remove')} ${this.item.product.title}?`,
        htmlContent: this.$t('cart.removeConfirmation'),
        type: 'cancelYes',
      });
      if (confirmed) {
        this.$store.dispatch('cart/removeItem', this.item.key);
        this.$router.push({ name: 'cart' });
      }
    },
    save() {
      this.$store.dispatch('cart/patchCartItem', {
        key: this.$route.params.id,
        patch: {
          quantity: parseInt(this.quantity, 10),
          remarks: this.remarks,
          preOrder: this.preOrder,
          deliveryDate: this.deliveryDate,
          deliveryDateType: this.deliveryDateType,
        },
      });
      this.$router.push({ name: 'cart' });
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },
    increment() {
      if (this.quantity < this.maxItems) {
        this.quantity += 1;
        if (!this.preOrder && this.datePredicted > this.deliveryDate) {
          this.deliveryDate = this.datePredicted;
        }
      }
    },
    close() {
      this.$router.replace({ name: 'cart' });
    },
    dateSelected(date) {
      this.deliveryDateType = 'SELECTED';
      this.deliveryDate = date;
    },
    preOrderSelected() {
      if (this.preOrder) {
        this.deliveryDateType = 'SELECTED';
        this.deliveryDate = this.preOrderMonths[0].date;
      } else {
        this.deliveryDateType = 'DEFAULT';
        this.deliveryDate = null;
      }
    },
    chooseDateSelected() {
      if (this.deliveryDateType === 'SELECTED' && !this.deliveryDate) {
        this.deliveryDate = this.datepickerValue;
      }
    },
    changeBike() {
      this.$router.push(`/items/configurator/${this.item.customizer.model}?load=${this.item.key}`);
    },
  },
};
</script>
