import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth';
import items from './modules/items';
import customer from './modules/customer';
import language from './modules/language';
import cart from './modules/cart';
import prebook from './modules/prebook';
import global from './modules/global';
import notifications from './modules/notifications';
import myInvoices from './modules/myinvoices';
import myWarranties from './modules/mywarranties';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    items,
    customer,
    language,
    myInvoices,
    myWarranties,
    cart,
    prebook,
    global,
    notifications,
  },
  strict: debug,
  plugins: [createPersistedState()],
});
