<template>
  <bcf-aside @close="close">
    <bcfAsideHeader :close="close">
      {{ $t("notifications.settings") }}
    </bcfAsideHeader>
    <bcfAsideContent>
      <loader v-if="pending" />
      <div v-else>
        <div v-if="preferenceGroups.length > 0">
          <sectionTitle>{{ $t('notifications.channels') }}</sectionTitle>
          <list-item>
            <toggle
              v-model="pushEnabled"
              style="margin-right: 2rem"
            />
            <div>
              <div>
                {{ $t('notifications.push') }}
              </div>
              <div v-if="!pushSupported()">
                <small>{{ $t('notifications.pushNotSupported') }}</small>
              </div>
              <div v-else>
                <small v-if="hasSubscription">{{ $t('notifications.pushEnabled') }}</small>
                <small v-else>{{ $t('notifications.pushNotEnabled') }}</small>
              </div>
            </div>
          </list-item>
          <list-item>
            <toggle
              v-model="mailEnabled"
              class="m-r-2"
            />
            <div class="w-100 d-flex align-items-center space-between">
              <div>
                {{ $t('notifications.mail') }}
                <small>{{ $t('notifications.mailExpl') }}</small>
              </div>

              <div v-if="mailEnabled">
                <select v-model="mailnotificationsValue">
                  <option value="0">
                    {{ $t('notifications.maildirect') }}
                  </option>
                  <option value="1">
                    {{ $t('notifications.1hour') }}
                  </option>
                  <option value="8">
                    {{ $t('notifications.8hours') }}
                  </option>
                  <option value="24">
                    {{ $t('notifications.mail1day') }}
                  </option>
                  <option value="48">
                    {{ $t('notifications.mail2days') }}
                  </option>
                  <option value="72">
                    {{ $t('notifications.mail3days') }}
                  </option>
                </select>
              </div>
            </div>
          </list-item>

          <div
            v-for="group in preferenceGroups"
            :key="group.group"
          >
            <section-title class="m-t-4 m-b+-0">
              {{ group.group }}
            </section-title>
            <list-item
              v-for="preference in group.items"
              :key="preference.type"
            >
              <toggle
                style="margin-right: 2rem"
                :value="preference.enabled"
                @input="preference.toggleEnabled"
              />

              <div>{{ preference.title }}</div>
            </list-item>
          </div>
        </div>
        <div v-else>
          <EmptyState icon="bell">
            Voor dit account zijn er geen meldingsinstellingen beschikbaar.
          </EmptyState>
        </div>
      </div>
    </bcfAsideContent>
  </bcf-aside>
</template>

<style scoped>
</style>

<script>
import { mapState } from 'vuex';
import toggle from '@/elements/toggle.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import ListItem from '@/elements/ListItem.vue';
import EmptyState from '@/elements/EmptyState.vue';
import loader from '@/elements/loader.vue';
import { userUpdate } from '@/api/user';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';

import {
  getNotificationsPreferences, enablePreference, disablePreference, checkSubscription, unSubscribe,
} from '@/api/notifications';

import {
  subscribe, urlBase64ToUint8Array, ab2str, pushSupported,
} from '@/utils/notificationUtils';

export default {
  name: 'Notifications',
  metaInfo() {
    return {
      title: this.$t('header.notifications'),
    };
  },
  components: {
    toggle,
    SectionTitle,
    ListItem,
    EmptyState,
    loader,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
  },
  data() {
    return {
      preferenceGroups: [],
      hasSubscription: false,
      checkingPermission: false,
      subscription: null,
      pending: true,
    };
  },
  computed: {
    ...mapState({
      userPushNotificationsEnabled: state => state.auth.user.pushnotifications,
      mailnotifications: state => state.auth.user.mailnotifications,
    }),
    pushpermission() {
      return Notification.permission;
    },
    pushEnabled: {
      get() {
        return this.userPushNotificationsEnabled;
      },
      set(newVal) {
        this.$store.dispatch('auth/togglePushNotifications', newVal);
        if (newVal) {
          this.askPermission();
        } else {
          this.removePermission();
        }
      },
    },
    mailEnabled: {
      get() {
        return !!this.mailnotifications;
      },
      set(newVal) {
        this.$store.dispatch('auth/updateUser', {
          user: {
            ...this.$store.state.auth.user,
            mailnotifications: newVal ? 24 : null,
          },
        });
      },
    },
    mailnotificationsValue: {
      get() {
        return this.mailnotifications;
      },
      async set(newVal) {
        const user = {
          ...this.$store.state.auth.user,
          mailnotifications: newVal,
        };
        await userUpdate(user.id, user);

        this.$store.dispatch('auth/updateUser', {
          user,
        });
      },
    },

  },
  async created() {
    const preferences = await getNotificationsPreferences();
    const t = this;
    this.preferences = preferences.map(o => ({
      ...o,
      toggleEnabled(newVal) {
        t.preferences.find(p => p.type === o.type).enabled = newVal;
        if (newVal) {
          enablePreference(o.type);
        } else {
          disablePreference(o.type);
        }
      },
    }));
    this.preferenceGroups = this.preferences.reduce((groups, preference) => {
      if (!groups.find(o => o.group === preference.category)) {
        groups.push({
          group: preference.category,
          items: [preference],
        });
      } else {
        groups.find(o => o.group === preference.category).items.push(preference);
      }
      return groups;
    }, []);

    // Push Notifications
    if (await pushSupported()) {
      if (Notification.permission === 'granted') {
        this.checkingPermission = true;
        try {
          const reg = await navigator.serviceWorker.ready;
          if (reg) {
            this.reg = reg;
            console.log('teg', reg);
            try {
              const subscription = await reg.pushManager.getSubscription();
              this.subscription = subscription;
              console.log(subscription);
              try {
                await checkSubscription({
                  endpoint: subscription.endpoint,
                });
                this.hasSubscription = true;
              } catch (e) {
                // no subscription or error
              } finally {
                this.checkingPermission = false;
              }
            } catch (e) {
              console.error(e);
            }
          }
        } catch (e) {
          console.error('"', e);
        }
      }
    }
    this.pending = false;
  },
  methods: {
    pushSupported,
    async removePermission() {
      if (await pushSupported()) {
        Notification.requestPermission(() => {
          navigator.serviceWorker.getRegistration().then(async (reg) => {
            if (reg) {
              const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                  'BAiv1IOvLP0MuxGx9u1c-kHiHEs6moqBeo8G84TcOBRkfLjOQW5V8_3UQ6vTlmWrorReK6t8EC14jxoC3VYa1Rw',
                ),
              };

              const subscription = this.subscription ? this.subscription : await reg.pushManager.subscribe(subscribeOptions);
              unSubscribe({
                useragent: navigator.appVersion,
                endpoint: subscription.endpoint,
                p256dh: ab2str(subscription.getKey('p256dh')),
                auth: ab2str(subscription.getKey('auth')),
              });
              this.hasSubscription = false;
            }
          });
        });
      }
    },
    async askPermission() {
      if (await pushSupported()) {
        if (Notification.permission !== 'denied') {
          const store = this.$store;
          Notification.requestPermission(async (status) => {
            const subscription = subscribe(store);
            if (subscription) {
              // eslint-disable-next-line no-new
              new Notification('You will now recieve notifications.', {
                icon: '/apple-icon-152x152.png',
                data: {
                  url: 'https://b2b.cyclingfactory.be',
                },
              });
              this.hasSubscription = true;
            }
            this.pushpermission = status;
          });
        }
      }
    },
    close() {
      this.$emit('close', true);
    },

  },
};
</script>
