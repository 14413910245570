import { apiInstance } from './api';

export const userUpdate = async (id, user) => {
  try {
    await apiInstance.put(`/v1/users/${id}`, user);
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), {
      status: err.status,
    });
  }
};

export const changePassword = async (currentPassword, newPassword) => {
  await apiInstance.post('/v1/users/changePassword', {
    currentPassword,
    newPassword,
  });
};

export const changePasswordWithToken = async (token, newPassword) => {
  await apiInstance.post('/v1/users/changePassword', {
    token,
    newPassword,
  });
};

export const changePasswordWithStartToken = async (token, newPassword) => {
  await apiInstance.post('/v1/users/changePassword', {
    startToken: token,
    newPassword,
  });
};

export const requestPassword = async (email, lang) => {
  await apiInstance.post('/v1/users/requestPassword', {
    email,
    lang,
  });
};
