<template>
  <div class="overlay__header">
    <a
      class="overlay__close"
      @click="close"
    />
    <h1>
      <slot />
    </h1>
    <slot name="subtitle" />
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style>
.overlay__close {
  cursor: pointer;
}
</style>
