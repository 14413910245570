/* eslint-disable no-nested-ternary */
<template>
  <BcfDialog
    v-dialog-config="{
      overlay,
    }"
    :overlay="overlay"
    :title="$t(`account.account.myCustomers.confirmDuplicate`)"
    wide
  >
    <div>
      <span v-html="$t(`account.account.myCustomers.confirmDuplicate${duplicates.length>1?'Multiple':''}Intro`)" />
      <br>

      <div class="crm-relation-list">
        <Relation
          v-for="duplicate of duplicates"
          :key="duplicate.id"
          :value="duplicate"
          :class="{
            'crm-relation--interactive': duplicates.length>1,
            'crm-relation--selected': duplicate === selected,
          }"
          @click.native.prevent.stop="selectRelation(duplicate, $event)"
        />
      </div>
      <br>

      <span v-html="$t(`account.account.myCustomers.confirmDuplicate${duplicates.length>1?'Multiple':''}Outro`)" /><br>
    </div>
    <template v-slot:footer>
      <button
        class="button button--secondary"
        @click.stop="cancelSave"
      >
        {{ $t('general.cancel') /** cousin of Major Abort */ }}
      </button>
      <button
        class="button button--primary"
        type="submit"
        :disabled="duplicates.length>1 && !selected"
        @click.stop.prevent="mergeRelation(selected || duplicates[0])"
      >
        {{ duplicates.length===1? $t(`account.account.myCustomers.merge`) : $t(`account.account.myCustomers.mergeSelected`) }}
      </button>
      <button
        class="button button--primary"
        type="submit"
        @click.stop.prevent="saveAnyway"
      >
        {{ $t(`account.account.myCustomers.createAnyway`) }}
      </button>
    </template>
  </BcfDialog>
</template>
<script>

import BcfDialog from '@/components/dialog/bcfDialog.vue';
import { mapState } from 'vuex';
import Relation from './relation.vue';

// Elements
export default {
  components: {
    Relation,
    BcfDialog,
  },
  props: {
    overlay: {
      // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    duplicates: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language.current,
      user: (state) => state.auth.user,
      customer: (state) => state.customer,
    }),
    customerId() {
      return this.customer.selectedCustomerId;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    close(result, source = 'cancel') {
      return this.overlay.close(result, source);
    },
    selectRelation(relation) {
      this.selected = relation;
    },
    mergeRelation(relation) {
      this.close(relation || this.selected, 'submit');
    },
    saveAnyway() {
      this.close(true, 'submit');
    },
    cancelSave() {
      this.close(false, 'submit');
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
