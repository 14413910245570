<template>
  <div>
    <SectionTitle>
      <img :src="logo">
      <!--Ridley-->
    </SectionTitle>
    <loader v-if="pending" />
    <list-item
      v-for="item in items"
      :key="item.driveId"
      icon-before
      arrow
    >
      <i class="uil uil-folder" />
      <router-link :to="{name: 'sharepointRootItems', params: {driveId: item.driveId}}">
        {{ item.displayName }}
      </router-link>
    </list-item>
  </div>
</template>
<script>
import loader from '@/elements/loader.vue';
import SectionTitle from '@/elements/SectionTitle.vue';
import { getSiteDrives } from '@/api/sharepoint';
import ListItem from '@/elements/ListItem.vue';

export default {
  name: 'SharepointDriveList',
  components: {
    SectionTitle,
    loader,
    ListItem,
  },
  props: {
    site: {
      type: String,
      default: null,
    },
    logo: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      items: [],
      pending: false,
    };
  },
  async mounted() {
    this.pending = true;
    this.items = await getSiteDrives(this.site);
    this.pending = false;
  },

};
</script>
