<!-- eslint-disable max-len -->
<template>
  <img
    v-if="!brand"
    src="@/assets/img/brands/logo_no_brand_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='ridley'"
    src="@/assets/img/brands/logo_ridley_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='eddy merckx'"
    src="@/assets/img/brands/logo_eddy_merckx_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='hjc'"
    src="@/assets/img/brands/logo_hjc_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='sram'"
    src="@/assets/img/brands/logo_sram.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='forza'"
    src="@/assets/img/brands/logo_forza_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='look'"
    src="@/assets/img/brands/logo_look_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='shimano'"
    src="@/assets/img/brands/logo_shimano_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='campagnolo'"
    src="@/assets/img/brands/logo_campagnolo.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='elite'"
    src="@/assets/img/brands/logo_elite_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='fsa'"
    src="@/assets/img/brands/logo_fsa_small.png"
    alt="Logo"
    loading="lazy"
  >
  <img
    v-else-if="brand.toLowerCase()=='lazer'"
    src="@/assets/img/brands/logo_lazer_small.png"
    alt="Logo"
    loading="lazy"
  >
  <span
    v-else
    class="text-brand"
  >{{ brand }}</span>
</template>
<script>
export default {
  name: 'BrandLogo',
  props: {
    brand: {
      type: String,
      default: null,
    },
  },
};
</script>
<style scoped>
.text-brand {
  line-height: 15px;
  padding: 2.5px;
  margin-left: 6px;
}
</style>
