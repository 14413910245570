<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex';
import { GetCustomerByid } from '@/api/api';
import { subscribe, pushSupported } from '@/utils/notificationUtils';
import store from './store';

export default {
  name: 'App',
  metaInfo: {
    title: 'B2B - Belgian Cycling Factory',
    titleTemplate: '%s | B2B',
  },
  components: {},
  computed: {
    ...mapGetters('cart', {
      cartProducts: 'cartProducts',
    }),
  },
  async mounted() {
    if (this.cartProducts.length > 0) {
      this.cartProducts.forEach((element) => {
        console.log(element);
        if (!element.key || (element.customizer && !element.product.attributes)) {
          window.localStorage.removeItem('vuex');
          this.$store.replaceState({});
          window.location.href = '/login';
        }
      });
    }
    const t = this;
    function setDimensions() {
      setTimeout(() => {
        t.$gtag.customMap({
          dimension3: 'showRetailPrices',
          dimension2: 'customer',
          dimension1: 'username',
          dimension4: 'language',
        });
        t.$gtag.set({
          user_id: t.$store.state.auth.user
            ? t.$store.state.auth.user.username
            : null,
          showRetailPrices: t.$store.state.items.showRetailPrices,
          customer: t.$store.state.customer.selectedCustomer
            ? t.$store.state.customer.selectedCustomer.externalid
            : null,
          username: t.$store.state.auth.user
            ? t.$store.state.auth.user.username
            : null,
          language: t.$store.state.language.current
            ? t.$store.state.language.current
            : null,
        });
        window.BCF.track('B2B', {
          user_id: t.$store.state.auth.user
            ? t.$store.state.auth.user.username
            : null,
          showRetailPrices: t.$store.state.items.showRetailPrices,
          customer: t.$store.state.customer.selectedCustomer
            ? t.$store.state.customer.selectedCustomer.externalid
            : null,
          username: t.$store.state.auth.user
            ? t.$store.state.auth.user.username
            : null,
          language: t.$store.state.language.current
            ? t.$store.state.language.current
            : null,
        });
      }, 500);
    }
    setDimensions();

    this.$store.commit('auth/setRequestUserIsLoaded');
    // Listen to the store to change locale in different pluggins.
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'language/setLanguage') {
        t.$i18n.locale = state.language.current;
      }
      if (
        [
          'auth/setRequestUserSuccess',
          'customer/setSelectedCustomer',
          'items/showRetailPrices',
          'language/setLanguage',
        ].includes(mutation.type)
      ) {
        setDimensions();
      }
      if (mutation.type === 'auth/setRequestUserSuccess' && state.auth.user.pushnotifications) {
        if (await pushSupported()) {
          Notification.requestPermission(() => {
            subscribe(t.$store);
          });
        }
      }
    });

    // Allow the language to be set with the ?lang= param in the URL.
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('lang')) {
      this.$store.dispatch('language/setLanguage', {
        language: urlParams.get('lang'),
      });
    } else {
      this.$i18n.locale = this.$store.state.language.current;
      if (this.$store.getters['auth/isLoggedIn']) {
        this.$store.dispatch('language/getAttributeTranslations');
      }
    }

    if (urlParams.has('cus')) {
      this.$store.dispatch('customer/setSelectedExternalCustomerId', { selectedExternalCustomerId: urlParams.get('cus') });
    }

    if (urlParams.has('preview')) {
      this.$store.dispatch('global/setPreviewMode', true);
    } else {
      this.$store.dispatch('global/setPreviewMode', false);
    }
    // Start - reload data stores if cache time is expired
    if (store.getters['customer/isStoreDataExpired']) {
      await store.dispatch('items/clear', {}, { root: true });
      if (store.state.auth.user && store.state.auth.user.customerids.length > 0) {
        if (store.state.auth.user.customerids.length === 1) {
          const customer = await GetCustomerByid(store.state.auth.user.id);
          await store.dispatch(
            'customer/setSelectedCustomer',
            { selectedCustomer: customer },
            { root: true },
          );
        }
        await store.dispatch('language/getAttributeTranslations', null, {
          root: true,
        });
      }
    }
    // End - reload
  },
};
</script>
