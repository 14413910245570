import Vue from 'vue';
import { placeOrder as placeOrderApi } from '@/api/api';
import { generateCart } from '@/api/cart';
import { getAvailabilityDate } from '@/utils/cartUtils';
import { v4 } from 'uuid';

// mutations

export default {
  namespaced: true,
  state: {},

  // getters
  getters: {
    cartItem: (state, getters) => (key) => {
      const item = getters.cartProducts.find((o) => o.key === key);
      if (item) {
        return item;
      }
      return null;
    },
    cartCustomizerItem: (state, getters) => (key) => {
      const item = getters.cartProducts.find((o) => o.key === key);
      if (item) {
        return item;
      }
      return null;
    },

    cart: (state, getters, rootState) => {
      const customer = rootState.customer.selectedCustomerId;
      return state[customer];
    },

    cartProducts: (state, getters) => (getters.cart ? getters.cart.items : []),

    cartLocation: (state, getters) => (getters.cart ? getters.cart.location : null),

    oneBikeOneOrder: (state, getters) => (getters.cart ? getters.cart.oneBikeOneOrder : true),

    cartReference: (state, getters) => (getters.cart ? getters.cart.reference : null),

    cartRemark: (state, getters) => (getters.cart ? getters.cart.remark : null),

    cartStatus: (state, getters) => (getters.cart ? getters.cart.status : null),

    cartGenerated: (state, getters) => (getters.cart ? (getters.cart.generated || {}) : {}),

    cartLoading: (state, getters) => (getters.cart ? getters.cart.loading : null),
    cartError: (state, getters) => (getters.cart ? getters.cart.error : null),

    cartPrice: (state, getters) => getters.cartProducts.reduce(
      (cartPrice, item) => {
        const totalPrice = parseFloat(item.product.$$price.msrp) * item.quantity;
        const dealerPrice = parseFloat(item.product.$$price.price) * item.quantity;
        const dealerDiscount = totalPrice - dealerPrice;
        return {
          currency: item.product.$$price.currency,
          totalPrice: cartPrice.totalPrice + totalPrice,
          dealerDiscount: cartPrice.dealerDiscount + dealerDiscount,
          toPay: cartPrice.toPay + dealerPrice,
        };
      },
      {
        currency: null,
        totalPrice: 0,
        dealerDiscount: 0,
        toPay: 0,
      },
    ),

    cartTotalItems: (state, getters) => getters.cartProducts.reduce((total, product) => total + product.quantity, 0),
  },

  // actions
  actions: {

    removeItem({
      commit, rootState, getters, dispatch,
    }, key) {
      const cartItem = getters.cart.items.find(
        (item) => item.key === key,
      );
      try {
        window.app.$gtag.event('remove_from_cart', {
          items: [
            {
              externalid: cartItem.product.externalid,
              name: cartItem.product.description,
              brand: cartItem.product.attributes.item_brand,
              category: cartItem.product.attributes.category,
              price: cartItem.product.$$price.price,
              quantity: cartItem.quantity,
            },
          ],
        });
      } catch (E) {
        console.error('gtag');
      }
      commit('removeItem', {
        key,
        customerId: rootState.customer.selectedCustomerId,
      });
      dispatch('generate');
    },

    removeItemWithoutGenerate({
      commit, rootState,
    }, key) {
      commit('removeItem', {
        key,
        customerId: rootState.customer.selectedCustomerId,
      });
    },

    clear({ commit }, customerId) {
      commit('clear', customerId);
    },

    incrementItemQuantity({ commit, rootState }, key) {
      commit('incrementItemQuantity', {
        key,
        customerId: rootState.customer.selectedCustomerId,
      });
    },

    decrementItemQuantity({ commit, rootState }, key) {
      commit('decrementItemQuantity', {
        key,
        customerId: rootState.customer.selectedCustomerId,
      });
    },

    setLocation({ commit, rootState, dispatch }, id) {
      commit('setLocation', {
        id,
        customerId: rootState.customer.selectedCustomerId,
      });
      dispatch('generate');
    },

    setOneBikeOneOrder({ commit, rootState }, oneBikeOneOrder) {
      commit('setOneBikeOneOrder', {
        oneBikeOneOrder,
        customerId: rootState.customer.selectedCustomerId,
      });
    },

    setRemark({ commit, rootState }, remark) {
      commit('setRemark', {
        remark,
        customerId: rootState.customer.selectedCustomerId,
      });
    },

    setReference({ commit, rootState }, reference) {
      commit('setReference', {
        reference,
        customerId: rootState.customer.selectedCustomerId,
      });
    },
    updateCustomizerInCart({ commit, rootState, dispatch }, { product, customizer, key }) {
      commit('updateCustomizerConfiguration', {
        key,
        product,
        customizer,
        customerId: rootState.customer.selectedCustomerId,
      });
      dispatch('generate');
    },

    addProductToCart({ commit, rootState, getters }, {
      product, customizer, preOrder, qty, deliveryDate, deliveryDateType,
    }) {
      const cartItem = getters.cart.items.find(
        (item) => item.product && item.product.id === product.id && !item.preOrder,
      );
      try {
        window.app.$gtag.event('add_to_cart', {
          items: [
            {
              id: (customizer) ? product.externalid : product.id,
              name: product.description,
              brand: product.attributes.item_brand,
              category: product.attributes.category,
              price: product.$$price.price,
              quantity: qty || 1,
            },
          ],
        });
      } catch (e) {
        console.error(e);
      }

      if (customizer) {
        commit('pushProductToCart', {
          product,
          preOrder,
          qty,
          deliveryDate,
          deliveryDateType,
          customizer,
          customerId: rootState.customer.selectedCustomerId,
        });
      } else if (!cartItem) {
        commit('pushProductToCart', {
          product,
          /*           product: {
            id: product.id,
          }, */
          preOrder,
          qty,
          deliveryDate,
          deliveryDateType,
          customizer,
          customerId: rootState.customer.selectedCustomerId,
        });
      } else {
        commit('incrementItemQuantity', {
          id: cartItem.product.id,
          customerId: rootState.customer.selectedCustomerId,
        });
      }
    },

    patchCartItem({ commit, rootState, dispatch }, { key, patch }) {
      commit('patchCartItem', {
        key,
        patch,
        customerId: rootState.customer.selectedCustomerId,
      });
      dispatch('generate');
    },

    createCartForCustomerIfNotExists({ commit }, customerId) {
      commit('createCartForCustomerIfNotExists', customerId);
    },

    placeOrder: async function placeOrder({ getters, rootState, commit }) {
      commit('setCartStatus', {
        status: 'PENDING',
        customerId: rootState.customer.selectedCustomerId,
      });
      const order = {
        customerid: rootState.customer.selectedCustomerId,
        deliveryLocation: getters.cartLocation,
        reference: getters.cartReference,
        oneBikeOneOrder: getters.oneBikeOneOrder,
        remarks: getters.cartRemark,
        orderlines: [],
      };
      getters.cartProducts.forEach((product) => {
        let item = null;
        if (product.customizer) {
          item = {
            id: product.product.id,
            externalid: product.product.externalid,
            tags: product.product.tags,
            image: product.product.image,
            frameimage: product.product.frameimage,
            range: product.product.attributes.category,
            title: product.product.title,
            description: product.product.description,
            subtitle: product.product.subtitle,
            $$availability: product.product.$$availability,
            variantattribute: product.product.variantattribute,
            attributes: {
              item_brand: product.product.attributes.item_brand,
              category: product.product.attributes.category,
            },
          };
        }

        let deliveryDate;
        if (product.deliveryDateType === 'SELECTED') {
          deliveryDate = product.deliveryDate;
        } else {
          deliveryDate = product.customizer ? product.product.deliverydate : getAvailabilityDate(product.product.$$availability, product.quantity);
        }

        if (product.quantity) {
          order.orderlines.push({
            itemid: product.product.id,
            quantity: product.quantity,
            item: product.customizer ? item : null,
            customizer: product.customizer,
            optionset: product.OptionSets,
            remarks: product.remarks,
            preOrder: product.preOrder,
            deliverydate: deliveryDate,
            deliveryasap: (product.deliveryDateType === 'DEFAULT'),
            reference: product.reference,
          });
        }
      });

      try {
        const placedOrder = await placeOrderApi(order);
        try {
          placedOrder.groupedOrders.forEach((singelOrder) => {
            window.app.$gtag.event('purchase', {
              transaction_id: singelOrder.id,
              value: singelOrder.price,
              currency: singelOrder.currency,
              items: singelOrder.lines.map((o) => ({
                id: o.item.externalid,
                name: o.item.description,
                brand: o.item.attributes.item_brand,
                category: o.item.attributes.category,
                price: o.item.$$price.price,
                quantity: o.orderline.quantity,
                reference: o.item.reference,
              })),
            });
          });
        } catch (e) {
          console.error(e);
        }
        commit('setCartStatus', {
          status: 'SUCCESS',
          customerId: rootState.customer.selectedCustomerId,
        });
        commit('clear', {
          customerId: rootState.customer.selectedCustomerId,
        });
      } catch (e) {
        console.error(e);
        commit('setCartStatus', {
          status: 'ERROR',
          customerId: rootState.customer.selectedCustomerId,
        });
      }
    },
    generate: async function generate({ getters, rootState, commit }) {
      commit('startGenerate', {
        customerId: rootState.customer.selectedCustomerId,
      });
      const order = {
        customerid: rootState.customer.selectedCustomerId,
        deliveryLocation: getters.cartLocation,
        reference: getters.cartReference,
        oneBikeOneOrder: false, // not generate this
        remarks: getters.cartRemark,
        orderlines: [],
      };
      getters.cartProducts.forEach((product) => {
        let item = null;
        if (product.customizer) {
          item = {
            id: product.product.id,
            externalid: product.product.externalid,
            tags: product.product.tags,
            image: product.product.image,
            frameimage: product.product.frameimage,
            range: product.product.attributes.category,
            title: product.product.title,
            description: product.product.description,
            subtitle: product.product.subtitle,
            $$availability: product.product.$$availability,
            variantattribute: product.product.variantattribute,
            attributes: {
              item_brand: product.product.attributes.item_brand,
              category: product.product.attributes.category,
            },
            reference: product.reference,
          };
        }

        let deliveryDate;
        if (product.deliveryDateType === 'SELECTED') {
          deliveryDate = product.deliveryDate;
        } else {
          deliveryDate = product.customizer ? product.product.deliverydate : getAvailabilityDate(product.product.$$availability, product.quantity);
        }

        order.orderlines.push({
          itemid: product.product.id,
          quantity: product.quantity,
          item: product.customizer ? item : null,
          customizer: product.customizer,
          optionset: product.OptionSets,
          remarks: product.remarks,
          preOrder: product.preOrder,
          deliverydate: deliveryDate,
          deliveryDateType: product.deliveryDateType,
          deliveryasap: (product.deliveryDateType === 'DEFAULT'),
          key: product.key,
          reference: product.reference,
        });
      });
      try {
        const generated = await generateCart(order);
        generated.groupedOrders.forEach((group) => {
          group.lines.forEach((line) => {
            delete line.orderline.configuration;
          });
        });
        commit('setGenerated', {
          generated,
          customerId: rootState.customer.selectedCustomerId,
        });
      } catch (e) {
        commit('errorGenerate', {
          error: e,
          customerId: rootState.customer.selectedCustomerId,
        });
      }
    },

  },
  mutations: {
    createCartForCustomerIfNotExists(state, customerId) {
      if (!state[customerId]) {
        Vue.set(state, customerId, {
          items: [],
          location: null,
          remark: null,
          reference: null,
          oneBikeOneOrder: true,
          status: 'DRAFT',
          generated: {},
        });
      }
    },
    pushProductToCart(state, {
      product, customerId, customizer, preOrder, qty, deliveryDate, deliveryDateType,
    }) {
      state[customerId].items.push({
        key: v4(),
        product,
        customizer,
        preOrder: preOrder || false,
        quantity: qty || 1,
        deliveryDate: deliveryDate || null,
        deliveryDateType: deliveryDateType || 'DEFAULT',
      });
    },
    updateCustomizerConfiguration(state, {
      product, customerId, customizer, key,
    }) {
      const cartCustomizer = state[customerId].items.find(
        (item) => item.key === key,
      );
      cartCustomizer.customizer = customizer;
      cartCustomizer.product = product;
    },
    removeItem(state, { key, customerId }) {
      state[customerId].items = state[customerId].items.filter(
        (o) => o.key !== key,
      );
    },

    setLocation(state, { id, customerId }) {
      state[customerId].location = id;
    },
    setRemark(state, { remark, customerId }) {
      state[customerId].remark = remark;
    },
    setReference(state, { reference, customerId }) {
      state[customerId].reference = reference;
    },
    setOneBikeOneOrder(state, { oneBikeOneOrder, customerId }) {
      state[customerId].oneBikeOneOrder = oneBikeOneOrder;
    },
    incrementItemQuantity(state, { key, customerId }) {
      const cartItem = state[customerId].items.find(
        (item) => item.product && item.key === key,
      );
      if (
        cartItem.quantity
        < (cartItem.product.$$availability
          ? cartItem.product.$$availability.filter((o) => o.date).length
          : 0)
      ) {
        cartItem.quantity += 1;
      }
    },

    decrementItemQuantity(state, { key, customerId }) {
      const cartItem = state[customerId].items.find(
        (item) => item.product && item.key === key,
      );
      if (cartItem.quantity > 0) {
        cartItem.quantity -= 1;
      }
    },

    patchCartItem(state, { key, patch, customerId }) {
      const cartItemIndex = state[customerId].items.findIndex(
        (item) => item.key === key,
      );

      if (cartItemIndex !== -1) {
        state[customerId].items.splice(cartItemIndex, 1, {
          // JavaScript (not specific to Vue) can not detect setting the value of an Array item directly
          ...state[customerId].items[cartItemIndex],
          ...patch,
        });
      }
    },

    setCartStatus(state, { status, customerId }) {
      state[customerId].status = status;
    },

    setGenerated(state, { generated, customerId }) {
      state[customerId].loading = false;
      state[customerId].generated = generated;
      state[customerId].error = null;
    },

    startGenerate(state, { customerId }) {
      state[customerId].loading = true;
      // state[customerId].generated = {};
    },

    errorGenerate(state, { error, customerId }) {
      state[customerId].loading = false;
      state[customerId].error = error;
    },

    clear(state, { customerId }) {
      state[customerId].generated = {};
      state[customerId].error = null;
      state[customerId].items = [];
      state[customerId].location = null;
      state[customerId].remark = null;
      state[customerId].reference = null;
      state[customerId].loading = false;
    },
  },
};
