<template>
  <div>
    <router-link
      :to="`${url}`"
      class="promo__item"
    >
      <div
        class="promo__image"
        :style="`background-image:url(${image});`"
      >
        <Badge
          v-if="type!==1 && !(logo && permalink)"
          type="unread"
        >
          {{ $t('general.promo') }}
        </Badge>

        <img
          v-if="(logo && permalink)"
          :src="logo"
          width="20"
          height="20"
          alt="Logo"
          class="logo"
        >
        <div
          v-if="social"
          @click.prevent
        >
          <social-sharing
            :url="`${shopBaseUrl}/share/v1/media/${id}?language=${language}`"
            :quote="social.quote && social.quote ? social.quote : ''"
            :hashtags="social.hashtags && social.hashtags ? social.hashtags : ''"
            class="button button--small share"
            inline-template
          >
            <div
              v-b-tooltip.hover.top
              :title="$t('account.about.socialFeed.share')"
            >
              <network
                class="uil uil-share-alt"
                network="facebook"
              />
            </div>
          </social-sharing>
        </div>

        <div v-if="permalink">
          <a
            v-b-tooltip.hover.top
            :title="$t('account.about.socialFeed.share')"
            target="_blank"
            class="button button--small share"
            :href="`https://www.facebook.com/sharer/sharer.php?u=${permalink}`"
            @click.prevent="openFacebookShare(`https://www.facebook.com/sharer/sharer.php?u=${permalink}`)"
          >
            <i class="uil uil-share-alt" />
          </a>
        </div>
      <!--div-- class="badge badge--unread">{{$t('news.unread')}}</!--div-->
      <!--div class="badge badge--expired">{{$t('news.expired')}}</div-->
      </div>
      <div class="promo__content">
        <h3>
          {{ title }}
        </h3>
        <p>{{ summary }}</p>
        <time>{{ formatDate(date) }}</time>
      </div>
    </router-link>
  </div>
</template>

<script>
import { formatDate } from '@/utils/dateUtils';
import Badge from '@/elements/Badge.vue';

export default {
  name: 'MediaTeaser',
  components: {
    Badge,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    summary: {
      type: String,
      required: false,
      default: null,
    },
    image: {
      type: String,
      required: true,
    },
    social: {
      type: Object,
      required: false,
      default: null,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    logo: {
      type: String,
      required: false,
      default: null,
    },
    permalink: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      shopBaseUrl: process.env.VUE_APP_SHOP_BASE_URL,
      language: this.$store.state.language.currentApi,
    };
  },
  mounted() {
  },
  methods: {
    formatDate,
    openFacebookShare(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  margin: 10px;
}
.share {
  background-color: #1b76f3;
  color: white;
}
</style>
