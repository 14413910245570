/**
 * Deep-Compare 2 objects. Order of keys in an object may differ, all the rest must be equal.
 * Warn: This will run indefenitely if object A contains a circular reference
 * @param objA
 * @param objB
 * @returns {boolean}
 */
export function isEqual(objA, objB) {
  if (typeof objA !== typeof objB) return false;
  if (objA instanceof Array) {
    // Arrays -> If length equal compare all values to be the same
    if (objA.length !== objB.length) return false;
    for (let i = 0; i < objA.length; ++i) {
      if (!isEqual(objA[i], objB[i])) return false;
    }
    return true;
  }
  if (typeof objA === 'object') {
    // Objects -> Sort entries and compare as arrays
    if ((objA === null) !== (objB === null)) return false;
    if (objA === objB) return true;
    const kvpA = Array.from(Object.entries(objA))
      .sort((a, b) => (a[0] < b[0] ? -1 : 1));
    const kvpB = Array.from(Object.entries(objB))
      .sort((a, b) => (a[0] < b[0] ? -1 : 1));
    return isEqual(kvpA, kvpB);
  }
  // Value Primitive
  return objA === objB;
}

/**
 * Shallow-Compare 2 objects. Order of keys in an object may differ, all the rest must be equal.
 * @param objA
 * @param objB
 * @returns {boolean}
 */
export function shallowIsEqual(objA, objB) {
  if (typeof objA !== typeof objB) return false;
  if (objA instanceof Array) {
    // Arrays -> If length equal compare all values to be the same
    if (objA.length !== objB.length) return false;
    for (let i = 0; i < objA.length; ++i) {
      if (objA[i] !== objB[i]) return false;
    }
    return true;
  }
  if (typeof objA === 'object') {
    // Objects -> Sort entries and compare as arrays
    if ((objA === null) !== (objB === null)) return false;
    if (objA === objB) return true;
    const kvpA = Array.from(Object.entries(objA))
      .sort((a, b) => (a[0] < b[0] ? -1 : 1));
    const kvpB = Array.from(Object.entries(objB))
      .sort((a, b) => (a[0] < b[0] ? -1 : 1));
    return shallowIsEqual(kvpA, kvpB);
  }
  // Value Primitive
  return objA === objB;
}

export function isEmpty(obj) {
  return !Object.entries(obj || {}).length && !obj?.length && !obj?.size;
}

export default isEqual;
