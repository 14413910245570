import { apiInstance } from './api';
// TODO: refactor to use below code with MultipleUploads.vue & SimpleUpload.vue
/* export const uploadSingleFile = async (file) => {
  const result = await apiInstance.post('/v1/warranty/upload', file);
  return result;
};

export const uploadMultipleFile = async (files) => {
  const result = await apiInstance.post('/v1/warranty/upload/multiple', files);
  return result;
}; */

export const fetch = async (cid) => {
  const result = await apiInstance.get(`/v1/warranty?cid=${cid}`);
  return result.data;
};

export const fetchDetail = async (id) => {
  const result = await apiInstance.get(`/v1/warranty/detail/${id}`);
  return result.data.result;
};

export const saveWarranty = async (warranty) => {
  try {
    const result = await apiInstance.post('/v1/warranty/save', warranty);
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const deleteFolder = async (prefix) => {
  try {
    const result = await apiInstance.delete(`/v1/warranty/delete/${prefix}`);
    return result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};

export const GetWarrantyinfoBySerialnumber = async (serial) => {
  try {
    const result = await apiInstance.get(`/v1/warranty/${serial}`);
    return result;
  } catch (err) {
    if (err.name === 'AlreadyExistsError') {
      throw err;
    }
    throw Object.assign(new Error('UnexpectedError'), {
      status: err.status,
    });
  }
};

export const replyMessage = async (message) => {
  try {
    const result = await apiInstance.put('/v1/warranty/reply', message);
    return result.data.result;
  } catch (err) {
    throw Object.assign(new Error('Unexpected error occured'), { status: 500 });
  }
};
