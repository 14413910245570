import { subscribe } from '@/api/notifications'; /** Context */

/** Context */
export default {
  namespaced: true,
  state: {
    subscriptions: null,
    currentSubscription: null,
    count: null,
  },
  getters: {},
  actions: {
    subscribe(context, subscription) {
      subscribe(subscription);
    },
    setCount({ commit }, count) {
      commit('setCount', count);
    },
  },
  mutations: {
    setCount(state, count) {
      state.count = count;
    },

  },
};
