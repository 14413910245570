<template>
  <div>
    <div class="m-t-4 m-md-t-0">
      <header class="main__title">
        <div class="container">
          <div class="row">
            <div class="col main__title__inner">
              <h1>Products</h1>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div class="container">
      <div class="row">
        <div class="col">
          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'bikeItems'}">
              <img
                src="../assets/img/icons/icon-bike.svg"
                class="list__item__icon"
              >
              {{ $t('items.cat.allBikes') }}
            </router-link>
          </listItem>
          <listItem
            v-if="ENABLE_BUILD_YOUR_OWN"
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'buildYourOwnModels'}">
              <img src="../assets/img/icons/icon-builder.svg">
              {{ $t('items.cat.buildYourOwn') }}
            </router-link>
          </listItem>
          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'accessoryItems'}">
              <img src="../assets/img/icons/icon-accessories.svg">
              {{ $t('items.cat.accessories') }}
            </router-link>
          </listItem>

          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'partItems'}">
              <img src="../assets/img/icons/icon-parts.svg">
              {{ $t('items.cat.components') }}
            </router-link>
          </listItem>

          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'outletItems'}">
              <img src="../assets/img/icons/icon-outlet.svg">
              {{ $t('items.cat.outlet') }}
            </router-link>
          </listItem>

          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'promoItems'}">
              <img src="../assets/img/icons/icon-promo.svg">
              {{ $t('items.cat.promoMaterial') }}
            </router-link>
          </listItem>

          <listItem
            v-if="isPreviewMode"
            icon-before
            icon-big
            arrow
          >
            <router-link to="#">
              <img src="../assets/img/icons/icon-question.svg">
              {{ $t('items.cat.decisionAid') }}
            </router-link>
          </listItem>
          <listItem
            v-if="isPreviewMode"
            icon-before
            icon-big
            arrow
          >
            <router-link to="#">
              <img src="../assets/img/icons/icon-action.svg">
              {{ $t('items.cat.actions') }}
            </router-link>
          </listItem>
          <listItem
            icon-before
            icon-big
            arrow
          >
            <router-link :to="{name: 'preorder'}">
              <img src="../assets/img/icons/preorder.svg">
              {{ $t('cart.preOrder') }}
            </router-link>
          </listItem>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ListItem from '@/elements/ListItem.vue';

export default {
  metaInfo() {
    return {
      title: '404',
    };
  },

  components: { ListItem },
  computed: {
    ...mapState({
      isPreviewMode: (state) => state.global.isPreviewMode,
      ENABLE_BUILD_YOUR_OWN: (state) => [4].includes(state.auth.user.type) || process.env.VUE_APP_ENABLE_BUILD_YOUR_OWN === 'true',
    }),
  },
  created() {
    window.addEventListener('resize', this.checkSize);
    this.checkSize();
  },
  destroyed() {
    window.removeEventListener('resize', this.checkSize);
  },
  methods: {
    checkSize() {
      if (window.innerWidth > 991) {
        this.$router.replace({
          name: 'bikeItems',
        });
      }
    },
  },
};
</script>

<style scoped>
.container {
  text-align: center;
}
</style>
