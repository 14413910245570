/* eslint-disable no-unused-vars */
import Vue from 'vue';
import { placeOrder as placeOrderApi, savePrebook } from '@/api/api';
import { getAvailabilityDate } from '@/utils/cartUtils';
import { v4 } from 'uuid';
import { generateCart } from '@/api/cart';

// mutations

export default {
  namespaced: true,
  state: {
    items: [],
    location: null,
    oneBikeOneOrder: true,
    status: null, // order status of prebook
    generated: {},
    loading: false,
    error: null,
  },

  // getters
  getters: {
    prebookProducts: (state) => state.items || [],
    prebookLocation: (state) => state.location || null,
    oneBikeOneOrder: (state) => state.oneBikeOneOrder,
    prebookStatus: (state) => state.status || null,
    prebookGenerated: (state) => state.generated || {},
    prebookLoading: (state) => state.loading || null,
    prebookError: (state) => state.error || null,
  },

  // actions
  actions: {
    clear({ commit }) {
      commit('clear');
    },
    setLocation({ commit, rootState, dispatch }, id) {
      commit('setLocation', {
        id,
        customerId: rootState.customer.selectedCustomerId,
      });
      dispatch('generate');
    },
    setOneBikeOneOrder({ commit, rootState }, oneBikeOneOrder) {
      commit('setOneBikeOneOrder', {
        oneBikeOneOrder,
      });
    },
    addProductToPrebook({ commit, rootState, getters }, {
      product, qty, deliveryDate, deliveryDateType,
    }) {
      commit('pushProductToPrebook', {
        product,
        qty,
        deliveryDate,
        deliveryDateType,
      });
    },
    setPrebookMetadata({ commit, rootState }, metadata) {
      commit('setPrebookMetadata', {
        metadata,
      });
    },
    placeOrder: async function placeOrder({ getters, rootState, commit }) {
      commit('setPrebookStatus', {
        status: 'PENDING',
        customerId: rootState.customer.selectedCustomerId,
      });
      const order = {
        customerid: rootState.customer.selectedCustomerId,
        deliveryLocation: getters.prebookLocation,
        oneBikeOneOrder: getters.oneBikeOneOrder,
        orderlines: [],
      };
      getters.prebookProducts.forEach((product) => {
        const item = null;

        let deliveryDate;
        if (product.deliveryDateType === 'SELECTED') {
          deliveryDate = product.deliveryDate;
        } else {
          deliveryDate = getAvailabilityDate(product.product.$$availability, product.quantity);
        }

        if (product.quantity) {
          order.orderlines.push({
            itemid: product.product.id,
            quantity: product.quantity,
            preOrder: product.preOrder,
            deliverydate: deliveryDate,
            deliveryasap: false,
          });
        }
      });
      try {
        const placedOrder = await placeOrderApi(order);
        try {
          placedOrder.groupedOrders.forEach((singelOrder) => {
            window.app.$gtag.event('purchase', {
              transaction_id: singelOrder.id,
              value: singelOrder.price,
              currency: singelOrder.currency,
              items: singelOrder.lines.map((o) => ({
                id: o.item.externalid,
                name: o.item.description,
                brand: o.item.attributes.item_brand,
                category: o.item.attributes.category,
                price: o.item.$$price.price,
                quantity: o.orderline.quantity,
              })),
            });
          });
        } catch (e) {
          console.error(e);
        }
        commit('setPrebookStatus', {
          status: 'SUCCESS',
          customerId: rootState.customer.selectedCustomerId,
        });
        commit('clear', {
          customerId: rootState.customer.selectedCustomerId,
        });
        // Update status to 5 (closed)
        await savePrebook(rootState.prebook.metadata.preorderCustomerId, rootState.prebook.metadata.year, 5, rootState.prebook.metadata.status, rootState.prebook.metadata.preorder, 3, null, rootState.prebook.metadata.brand);
      } catch (e) {
        console.error(e);
        commit('setPrebookStatus', {
          status: 'ERROR',
          customerId: rootState.customer.selectedCustomerId,
        });
      }
    },
    generate: async function generate({ getters, rootState, commit }) {
      commit('startGenerate', {
        customerId: rootState.customer.selectedCustomerId,
      });
      const order = {
        customerid: rootState.customer.selectedCustomerId,
        deliveryLocation: getters.prebookLocation,
        oneBikeOneOrder: false, // not generate this
        orderlines: [],
      };
      getters.prebookProducts.forEach((product) => {
        let item = null;
        if (product.customizer) {
          item = {
            id: product.product.id,
            externalid: product.product.externalid,
            tags: product.product.tags,
            image: product.product.image,
            frameimage: product.product.frameimage,
            range: product.product.attributes.category,
            title: product.product.title,
            description: product.product.description,
            subtitle: product.product.subtitle,
            $$availability: product.product.$$availability,
            variantattribute: product.product.variantattribute,
            attributes: {
              item_brand: product.product.attributes.item_brand,
              category: product.product.attributes.category,
            },
          };
        }

        let deliveryDate;
        if (product.deliveryDateType === 'SELECTED') {
          deliveryDate = product.deliveryDate;
        } else {
          deliveryDate = product.customizer ? product.product.deliverydate : getAvailabilityDate(product.product.$$availability, product.quantity);
        }

        order.orderlines.push({
          itemid: product.product.id,
          quantity: product.quantity,
          item: product.customizer ? item : null,
          customizer: product.customizer,
          optionset: product.OptionSets,
          remarks: product.remarks,
          preOrder: product.preOrder,
          deliverydate: deliveryDate,
          deliveryDateType: product.deliveryDateType,
          deliveryasap: (product.deliveryDateType === 'DEFAULT'),
          key: product.key,
        });
      });
      try {
        const generated = await generateCart(order);
        generated.groupedOrders.forEach((group) => {
          group.lines.forEach((line) => {
            delete line.orderline.configuration;
          });
        });
        commit('setGenerated', {
          generated,
          customerId: rootState.customer.selectedCustomerId,
        });
      } catch (e) {
        commit('errorGenerate', {
          error: e,
          customerId: rootState.customer.selectedCustomerId,
        });
      }
    },
  },
  mutations: {
    pushProductToPrebook(state, {
      product, qty, deliveryDate, deliveryDateType,
    }) {
      state.items.push({
        key: v4(),
        product,
        preOrder: true,
        quantity: qty || 1,
        deliveryDate: deliveryDate || null,
        deliveryDateType: deliveryDateType || 'DEFAULT',
      });
    },
    setLocation(state, { id }) {
      state.location = id;
    },
    setOneBikeOneOrder(state, { oneBikeOneOrder }) {
      state.oneBikeOneOrder = oneBikeOneOrder;
    },
    setPrebookMetadata(state, { metadata }) {
      state.metadata = metadata;
    },
    setPrebookStatus(state, { status }) {
      state.status = status;
    },
    setGenerated(state, { generated }) {
      state.loading = false;
      state.generated = generated;
      state.error = null;
    },
    startGenerate(state) {
      state.loading = true;
      // state.generated = {};
    },
    errorGenerate(state, { error }) {
      state.loading = false;
      state.error = error;
    },
    clear(state) {
      state.generated = {};
      state.error = null;
      state.items = [];
      state.location = null;
      state.loading = false;
      state.oneBikeOneOrder = true;
    },
  },
};
