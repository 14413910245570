<template>
  <form
    style="display: contents"
    @submit.prevent="save"
  >
    <BcfDialog
      ref="dialog"
      v-dialog-config="{
        overlay,
      }"
      :overlay="overlay"
      wide
      :title="title"
    >
      <span
        v-if="htmlContent"
        v-html="htmlContent"
      />
      <template v-if="showRemarks">
        <h4>{{ $t('prebook.remarks') }}</h4>
        <b-form-textarea
          id="textarea"
          v-model="remarks"
          :placeholder="$t('prebook.remarksPlaceholder')"
          rows="3"
        />
      </template>
      <template v-slot:footer>
        <button
          class="button button--secondary"
          type="button"
          @click.prevent="cancel"
        >
          {{ $t('general.cancel') }}
        </button>
        <button
          class="button button--primary"
          type="submit"
          @click.prevent="ok"
        >
          {{ $t('general.yes') }}
        </button>
      </template>
    </BcfDialog>
  </form>
</template>
<script>
import BcfDialog from '@/components/dialog/bcfDialog.vue';
import { BFormTextarea } from 'bootstrap-vue';

export default {
  components: {
    BcfDialog,
    BFormTextarea,
  },
  props: {
    overlay: { // This provides us control over the modal when spawned via dialogs.show(...)
      type: Object,
      default: () => null,
    },
    title: {
      type: String,
      default: '',
    },
    htmlContent: {
      type: String,
      default: null,
    },
    showRemarks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      remarks: null,
    };
  },
  computed: {},
  methods: {
    close(remark, source = 'cancel') {
      return this.overlay.close(remark, source);
    },
    cancel() {
      this.close(undefined, 'cancel');
    },
    ok() {
      this.close({ remarks: this.remarks }, 'ok');
    },
  },
};
</script>
