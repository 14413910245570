/**
 *  Service to spawn simple dialogs.
 *
 *  i.e.
 *
 *
 *  ```
 *    import dialogs from "utils/dialogs'
 *
 *    ...
 *
 *     onDelete(){
 *       let confirmed = await dialogs.show({
 *         title: 'Confirm deletion',
 *         content: 'Are you sure you want to delete X?',
 *         type: 'cancelDelete',
 *       })
 *       if(confirmed){
 *         await actuallyDelete();
 *       }
 *     }
 *  ```
 *
 *  Note:
 *    This is basically just a singleton class.
 *    Using VueC state for this was tried,it did not work because VueX can't store callbacks.
  */

// Imports
import BcfDialog from '@/components/dialog/bcfDialog.vue';
// import BcfDialogHost from '@/components/dialog/bcfDialogHost.vue';

export default new class {
  active = [];

  async show({
    type,
    title,
    content,
    htmlContent,
    component,
    props,
  }) {
    return new Promise((resolve, reject) => {
      const dialog = {
        id: this.#dialogId++,
        component: component || BcfDialog,
        props: {
          type,
          title,
          content,
          htmlContent,
          ...(props || {}),
        },
        resolve,
        reject,
      };
      this.active = [...this.active, dialog];
      if (this.#host) {
        this.#host.showDialogs([dialog], this.active);// [0]; // notify dialogs array is updated
      } else {
        throw new Error('No BcfDialogHost set!');
      }
    });
  }

  setHost(host) {
    this.#host = host;

    // return {
    //   dialogs: this.active,
    //   handleClose: (id, result) => {
    //     const dialog = this.active.find((x) => x.id === id);
    //     if (!dialog) {
    //       console.error(`Dialog #${id} not found or already closed`);
    //     } else {
    //       dialog.resolve(result);
    //       this.active = this.active.filter((x) => x !== dialog);
    //       if (this.#host) {
    //         this.#host(this.active); // notify dialogs array is updated
    //       }
    //     }
    //   },
    // };
  }

  // Private variables
  #dialogId = 0;

  /** @type {BcfDialogHost} */
  #host = undefined;
}();
